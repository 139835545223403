<div class="section container-sub-header">
    <div class="container-title-location">
    <div class="row title-list-view"
    [ngClass]="{'width-100': screenSize !== 'sm' && screenSize !== 'xs'}">
        <div class="col-9" [ngClass]="{'pl-0': screenSize !== 'sm' && screenSize !== 'xs'}">
            <h1>{{resultsPageData.Title}}</h1>
        </div>
        <div class="col-3 text-right d-md-none pr-0" *ngIf="showListViewLink">
            <button class="toggle-view tertiary-cta" id="map-list-view-button" aria-hidden="true" (click)="toggleListView()">
                {{(listView? resultsPageData.MapViewCta.Text : resultsPageData.ListViewCta.Text)}}
            </button>
        </div>
    </div>
    <div *ngIf="storeCount > 0" class="row location"
    [ngClass]="{'width-100 text-right': screenSize !== 'sm' && screenSize !== 'xs'}">
        <div class="col-9">
            <span id="location-span" class="font-body-lg">
                {{getResultsCount()}}
            </span>
        </div>
        <div class="col-3 text-right d-md-none pr-0">
            <button class="tertiary-cta" id="update-search-button" (click)="openDialog()" attr.aria-valuenow="{{resultsPageData.UpdateCta.AdaText}}">{{resultsPageData.UpdateCta.Text}}</button>
        </div>
    </div>
    </div>
    <div class="row find-form-inline">
        <dt-findStoreFormComponent [fromHomepage]="false" [totalStoreCount]= "getStoreCount()" [secondaryView]="true"></dt-findStoreFormComponent>
    </div>
</div>
