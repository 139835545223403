import { IConfig } from '../app/interfaces/config.interface';
import { Sitecore } from '../app/interfaces/findstore.interface';

const sitecoreMock: Sitecore = {
    FindStoreConfig: {
        Title: 'America’s Largest & Most Recognized Automotive Retailer',
        StoresOf: 'stores of',
        SearchRadio: 150,
        NumSuggestionsLocations: 5,
        TilesPerPage: 5,
        CurrentPage: 0,
        Image_Mobile: {
            Alt: '',
            Border: '',
            Class: '',
            Height: 650,
            HSpace: 0,
            Src: '/assets/home-banner-mobile-image.png',
            VSpace: 0,
            Width: 1480,
            MediaId: '7068a1d3-120f-4c2b-a63d-ff4b3bb77bdb',
            Title: '',
            Language: {
                Name: 'en'
            }
        },
        Image_Tablet: {
            Alt: '',
            Border: '',
            Class: '',
            Height: 650,
            HSpace: 0,
            Src: '/assets/home-banner-tablet-image.png',
            VSpace: 0,
            Width: 1480,
            MediaId: '7068a1d3-120f-4c2b-a63d-ff4b3bb77bdb',
            Title: '',
            Language: {
                Name: 'en'
            }
        },
        Image_Desktop: {
            Alt: '',
            Border: '',
            Class: '',
            Height: 650,
            HSpace: 0,
            Src: '/assets/home-banner-desktop-image.png',
            VSpace: 0,
            Width: 1480,
            MediaId: '7068a1d3-120f-4c2b-a63d-ff4b3bb77bdb',
            Title: '',
            Language: {
                Name: 'en'
            }
        },
        FindStoreForm: {
            LocationInput: {
                Label: 'Enter your location',
                Placeholder: 'City, Zipcode',
                ErrorMessage: 'Please enter a valid value',
                Required: true
            },
            HeaderInputs: {
               FasTitleText: 'Find an AutoNation location near you',
               NoStoreError: 'Your search criteria did not match any stores.'
            },
            MakesInput:  {
                Label: 'Select a store',
                Placeholder: 'Please select one option',
                ErrorMessage: 'Please choose a brand',
                Required: true
            },
            CtaFindStore: { Text: 'Find a store', AdaText: 'Find a store ADA'},
            CtaCurrentLocation: { Text: 'Use current location', AdaText: 'Use current location ADA'},
            CtaUpdateCurrentLocation: { Text: 'Update current location', AdaText: 'Update current location ADA'},
            DepartmentTypes: {
                AllCaption: 'All Brands / Makes',
                AllValue: 'all',
                Departments: [
                  { Value: 'alls', Description: 'All Brands / Makes' }
                ]
            },
            BaseSearchSection: {
              LabelInterest: 'Im interested in',
              LabelSales: 'Sales',
              LabelService: 'Service',
              LabelCollision: 'Collision'
            }
        },
        ResultsPage: {
            Title: 'Find an Autonation store',
            IsListView: false,
            ListViewCta: { Text: 'List view', AdaText: 'List view ADA'},
            MapViewCta: { Text: 'Map View', AdaText: 'Map View ADA'},
            UpdateCta: { Text: 'Update', AdaText: 'Update ADA'},
            RemoveTagCta: { Text: 'Remove tag', AdaText: 'Remove tag ADA'},
            LoadMoreCta: { Text: 'Load More tag', AdaText: 'Load More tag ADA'},
            BackTopCta: { Text: 'Back to top tag', AdaText: 'Back to top tag ADA'},
            ServiceCta: { Text: 'Schedule Service Appointment', AdaText: 'Schedule Service ADA'},
            SalesCta: { Text: 'Schedule Sales Appointment', AdaText: 'Schedule Sales ADA'},
            CollisionCta: { Text: 'Schedule Collsion Appointment', AdaText: 'Schedule Collsion ADA'},
            StoreOpenLabel: { Text: 'Open now', AdaText: 'Open now ADA' },
            StoreCloseLabel: { Text: 'Closed', AdaText: 'Closed ADA' },
            NoMapIcon: {
                Alt: 'no map available',
                Border: '',
                Class: '',
                Height: 650,
                HSpace: 0,
                Src: '/assets/mo-map-icon.png',
                VSpace: 0,
                Width: 1480,
                MediaId: '7068a1d3-120f-4c2b-a63d-ff4b3bb77bdb',
                Title: '',
                Language: {
                    Name: 'en'
                }
            },
            NoMapText: 'Sorry, the map is not available. Please try again later.',
            NoMapTitle: 'Map Not Loading',
            NoResultsIcon: {
                Alt: 'no results found',
                Border: '',
                Class: '',
                Height: 39.05,
                HSpace: 0,
                Src: '/assets/no-results-icon.png',
                VSpace: 0,
                Width: 50,
                MediaId: '7068a1d3-120f-4c2b-a63d-ff4b3bb77bdb',
                Title: '',
                Language: {
                    Name: 'en'
                }
            },
            CloseIcon: {
                Alt: 'close icon',
                Border: '',
                Class: '',
                Height: 35,
                HSpace: 0,
                Src: '/assets/close-icon.png',
                VSpace: 0,
                Width: 35,
                MediaId: '7068a1d3-120f-4c2b-a63d-ff4b3bb77bdb',
                Title: '',
                Language: {
                    Name: 'en'
                }
            },
            NoResultsTitle: 'No result found',
            NoResultsText: 'Sorry, we do not have any stores matching your search criteria.'
        },
        MakeAppointmentForm: {
            CtaSubmitForm: { Text: 'Submit', AdaText: 'Submit ADA'},
            FirstNameInput: {
                Label: 'First name',
                Placeholder: 'First na  me',
                ErrorMessage: 'Please enter a valid value',
                Required: true
            },
            LastNameInput: {
                Label: 'Last name',
                Placeholder: 'Last name',
                ErrorMessage: 'Please enter a valid value',
                Required: true
            },
            EmailInput: {
                Label: 'E-mail',
                Placeholder: 'E-mail address',
                ErrorMessage: 'Please enter a valid value',
                Required: true
            },
            PhoneInput: {
                Label: 'Phone number',
                Placeholder: '(###) ###-####',
                ErrorMessage: 'Please enter a valid value',
                Required: true
            },
            ZipCodeInput: {
                Label: 'Zip Code',
                Placeholder: 'Zip Code',
                ErrorMessage: 'Please enter a valid value',
                Required: true
            },
            YearInput: {
                Label: 'Year',
                Placeholder: 'Select Year',
                ErrorMessage: 'Please enter a valid value',
                Required: true
            },
            MakeInput: {
                Label: 'Enter your location',
                Placeholder: 'City, Zipcode',
                ErrorMessage: 'Please enter a valid value',
                Required: true
            },
            ModelInput: {
                Label: 'Enter your location',
                Placeholder: 'City, Zipcode',
                ErrorMessage: 'Please enter a valid value',
                Required: true
            },
            InsuranceInput: {
                Label: 'Enter your location',
                Placeholder: 'City, Zipcode',
                ErrorMessage: 'Please enter a valid value',
                Required: true
            },
            CarDrivableInput: {
                Label: 'Is this car drivable',
                Placeholder: '',
                ErrorMessage: '',
                ListOptions: [
                    { Value: 'yes', Description: 'yes' },
                    { Value: 'no', Description: 'no' }
                ],
                Required: true
            },
        },
        SeoPageDescription: {
            Title: 'AutoNation Chevrolet West Colonial | Chevrolet Dealership Near Orlando, FL',
            // eslint-disable-next-line
          Text: 'As America’s Largest Automotive Retailer, AutoNation proudly operates over 300 locations, representing all of the most popular manufacturer brands and employs over 26,000 Associates, coast to coast. Select a local AutoNation store in your area or expand your search using the filters below.',
            ReadMoreCta: { Text: 'Read More', AdaText: 'Read More ADA'},
            ReadLessCta: { Text: 'Read Less', AdaText: 'Read Less ADA'}
        },
        ThankYouComponent: {
            Title: 'Thank you',
            Text1: 'One of our associates will be in touch with you shortly.',
            Text2: 'Our goal at AutoNation is to make your customer experience fast, easy worry-free and affordable',
            Image: {
                Alt: '',
                Border: '',
                Class: '',
                Height: 650,
                HSpace: 0,
                Src: '/assets/med-l-xl-xxl-hero.png',
                VSpace: 0,
                Width: 1480,
                MediaId: '7068a1d3-120f-4c2b-a63d-ff4b3bb77bdb',
                Title: '',
                Language: {
                    Name: 'en'
                }
            },
            CloseCta: { Text: 'Close', AdaText: 'Close ADA'}
        },
        Id: '43ec66d6-5c12-4827-8834-877448971790',
        Language: { Name: 'en' },
        Version: 1,
        HasVoiPath: '/',
        NoVoiPath: '/cars-for-sale?status=S',
        LocationKey: 'AhySXOZHGo2ohuPsyOOCHDn4PgN_EeaJ1LQ-Q23nh5Z8HLUxfqzMMvWnXMmkXpHB',
        LocationKeyGoogleMaps: 'AhySXOZHGo2ohuPsyOOCHDn4PgN_EeaJ1LQ-Q23nh5Z8HLUxfqzMMvWnXMmkXpHB',
        GetMakesURL: '/StoreDetails/SearchNearbyStores',
        ValidateZipCodeAPIURL: '/api/sitecore/Header/UpdateZipCode',
        CurrentZipCodeAPIURL: '/api/sitecore/Header/GetZipByCityState',
        LocationsAPIURL: 'https://dev.virtualearth.net/REST/v1/Locations',
        AutosuggestAPIURL: 'https://dev.virtualearth.net/REST/v1/Autosuggest',
        GoogleMapUrl: 'https://maps.google.com/',
        SalesUrl: 'http://www.autonation.com/cars-for-sale/',
        VehiclesOnSaleCta: { Text: 'Vehicles on sale', AdaText: 'Vehicles on sale ADA'},
        StoreDetailsCta: { Text: 'Store details', AdaText: 'Store details ADA'},
        StoreDetailsConfig: {
            GetStoreDetailsApi: 'https://www.autonation.com/api/',
            ReviewsDetailsApi: 'https://www.autonation.com/api/sitecore/reputation/getRatingHtml?store=',
            WidgetReviews: '<IFRAME>',
            MakeThisMyStoreCta: { Text: 'Make this my store', AdaText: 'Make this my store ADA'},
            ThisIsMyStoreCta: { Text: 'This is my store', AdaText: 'This is my store ADA'},
            SendToPhone: { Text: 'Send to phone', AdaText: 'Send to phone ADA'},
            BackToSearchResults: { Text: 'Back to search results', AdaText: 'Back to search results ADA'},
            DrawersLabels: {
                StoreHours: { Text: 'Store hours', AdaText: 'Store hours ADA'},
                NewestArrivals: { Text: 'Newest arrivals', AdaText: 'Newest arrivals ADA'},
                ServiceSpecials: { Text: 'Service specials', AdaText: 'Service specials ADA'},
                Reviews: { Text: 'Reviews', AdaText: 'Reviews ADA'}
            },
            HoursLabel: 'hours',
            StoreClosedLabel: 'Closed'
        },
        // eslint-disable-next-line
        ReputationComScriptWidget: '!function(d,s,c){var js,fjs=d.getElementsByTagName(s)[0];js=d.createElement(s);js.className=c;js.src="https://widgets.reputation.com/src/client/widgets/widgets.js";fjs.parentNode.insertBefore(js,fjs);}(document,"script","reputation-wjs");'
    },
    TealiumSettings: {
        TealiumAccount: 'autonation',
        TealiumProfile: 'sitecorean',
        TealiumENV: 'dev'
    },
    Makes: {
        NewMakes: [
            {Description: null, Value: 'Acura'},
            {Description: null, Value: 'Alfa Romeo'},
            {Description: null, Value: 'Audi'},
            {Description: null, Value: 'Bentley'},
            {Description: null, Value: 'BMW'},
            {Description: null, Value: 'Buick'},
            {Description: null, Value: 'Cadillac'},
            {Description: null, Value: 'Chevrolet'},
            {Description: null, Value: 'Chrysler'},
            {Description: null, Value: 'Dodge'},
            {Description: null, Value: 'FIAT'},
            {Description: null, Value: 'Ford'},
            {Description: null, Value: 'GMC'},
            {Description: null, Value: 'Honda'},
            {Description: null, Value: 'Hyundai'},
            {Description: null, Value: 'INFINITI'},
            {Description: null, Value: 'Jaguar'},
            {Description: null, Value: 'Jeep'},
            {Description: null, Value: 'Land Rover'},
            {Description: null, Value: 'Lexus'},
            {Description: null, Value: 'Lincoln'},
            {Description: null, Value: 'Maserati'},
            {Description: null, Value: 'Mercedes-Benz'},
            {Description: null, Value: 'MINI'},
            {Description: null, Value: 'Nissan'},
            {Description: null, Value: 'Porsche'},
            {Description: null, Value: 'Ram'},
            {Description: null, Value: 'Subaru'},
            {Description: null, Value: 'Toyota'},
            {Description: null, Value: 'Volkswagen'},
            {Description: null, Value: 'Volvo'}
        ],
        UsedMakes: [
            {Description: null, Value: 'Acura'},
            {Description: null, Value: 'Alfa Romeo'},
            {Description: null, Value: 'Aston Martin'},
            {Description: null, Value: 'Audi'},
            {Description: null, Value: 'Bentley'},
            {Description: null, Value: 'BMW'},
            {Description: null, Value: 'Buick'},
            {Description: null, Value: 'Cadillac'},
            {Description: null, Value: 'Chevrolet'},
            {Description: null, Value: 'Chrysler'},
            {Description: null, Value: 'Dodge'},
            {Description: null, Value: 'Ferrari'},
            {Description: null, Value: 'FIAT'},
            {Description: null, Value: 'Ford'},
            {Description: null, Value: 'Genesis'},
            {Description: null, Value: 'GMC'},
            {Description: null, Value: 'HARLEY DAVIDSON'},
            {Description: null, Value: 'Honda'},
            {Description: null, Value: 'HUMMER'},
            {Description: null, Value: 'Hyundai'},
            {Description: null, Value: 'INFINITI'},
            {Description: null, Value: 'INTERNATIONAL'},
            {Description: null, Value: 'Isuzu'},
            {Description: null, Value: 'Jaguar'},
            {Description: null, Value: 'Jeep'},
            {Description: null, Value: 'Kia'},
            {Description: null, Value: 'Lamborghini'},
            {Description: null, Value: 'Land Rover'},
            {Description: null, Value: 'Lexus'},
            {Description: null, Value: 'Lincoln'},
            {Description: null, Value: 'Maserati'},
            {Description: null, Value: 'Mazda'},
            {Description: null, Value: 'McLaren'},
            {Description: null, Value: 'Mercedes-Benz'},
            {Description: null, Value: 'Mercury'},
            {Description: null, Value: 'MINI'},
            {Description: null, Value: 'Mitsubishi'},
            {Description: null, Value: 'Nissan'},
            {Description: null, Value: 'Pontiac'},
            {Description: null, Value: 'Porsche'},
            {Description: null, Value: 'Ram'},
            {Description: null, Value: 'Rolls-Royce'},
            {Description: null, Value: 'Saab'},
            {Description: null, Value: 'Saturn'},
            {Description: null, Value: 'Scion'},
            {Description: null, Value: 'smart'},
            {Description: null, Value: 'Subaru'},
            {Description: null, Value: 'Suzuki'},
            {Description: null, Value: 'Tesla'},
            {Description: null, Value: 'Toyota'},
            {Description: null, Value: 'Volkswagen'},
            {Description: null, Value: 'Volvo'}
        ]
    },
    ZipCodeData: {
        PostalCode : '33304',
        StateCode : 'FL',
        City : 'Fort Lauderdale',
        Latitude : 26.1352,
        Longitude : -80.1177,
        ValidPostalCode : true
    },
    isAzureMapsEnabled : false,
    isHeadless: false
};

export const configMock: IConfig = {
    SitecoreDomain: 'local.autonation.com',
    Sitecore: sitecoreMock,
};
