<div class="section store-details-page">
    <div class="row nav-top">
        <div class="col-12 col-md-6 text-left">
            <a class="tertiary-cta" href="#" title="{{storeDetailsConfig.BackToSearchResults.Text}}"
            [attr.aria-label]="storeDetailsConfig.BackToSearchResults.AdaText">
                <span class="dt-icon dt-icon-back-button-pink"></span>
                {{storeDetailsConfig.BackToSearchResults.Text}}
            </a>
        </div>
        <div class="col-6 text-right d-none d-md-block align-middle">
            <a class="tertiary-cta" href="#" title="{{storeDetailsConfig.SendToPhone.AdaText}}"
                [attr.aria-label]="storeDetailsConfig.SendToPhone.AdaText">
                <span class="dt-icon"></span>
                {{storeDetailsConfig.SendToPhone.Text}}
            </a>
        </div>
    </div>
    <div class="row store-information">
        <div class="col-12 offset-md-1 col-md-5 col-lg-6">
            <div class="img-wrapper">
                <img *ngIf="screenSize === 'xs' || screenSize === 'sm'" src="{{storeDetails.StoreImages.Mobile.Src}}" alt="{{storeDetails.Name}}">
                <img *ngIf="screenSize === 'md'" src="{{storeDetails.StoreImages.Mobile.Src}}" alt="{{storeDetails.Name}}">
                <img *ngIf="screenSize != 'xs' && screenSize != 'sm' && screenSize != 'md'" src="{{storeDetails.StoreImages.Mobile.Src}}" alt="{{storeDetails.Name}}">

            </div>
        </div>
        <div class="col-12 col-md-5 col-lg-4 content">
            <h1>{{storeDetails.Name}}</h1>
            <a class="tertiary-cta d-block margin-b-10 my-store" href="#"
                (click)="formInfoService.reportMakeThisMyStoreClick()"
                title="{{storeDetailsConfig.MakeThisMyStoreCta.AdaText}}"
                [attr.aria-label]="storeDetailsConfig.MakeThisMyStoreCta.Text">{{storeDetailsConfig.MakeThisMyStoreCta.Text}}</a>
            <div class="reviews-start-container r4e-widget" id="store-reviews" *ngIf="storeDetails.AverageRating >= 4" #storeReviews>
            </div>
            <a class="d-block" href="{{ storeDetails | formatGoogleMapUrl: googleMapUrl }}"
                (click)="formInfoService.reportStoreAddressClick(false)"
                target="_blank">
                <h4 class="multi-line-icon">
                    <span class="dt-icon dt-icon-location-pin"></span>
                    {{storeDetails.AddressLine1}} {{storeDetails.AddressLine2}}
                    <br>
                    {{storeDetails.City}}, {{storeDetails.StateCode}}, {{storeDetails.PostalCode}}
                </h4>
            </a>
            <a class="d-block" href="tel:{{storeDetails.Phone}}"
                (click)="formInfoService.reportToCallClick(storeDetails.Phone, false)">
                <h4 class="multi-line-icon"><span class="dt-icon dt-icon-phone-blue"></span>
                    {{storeDetails.Phone}}</h4>
            </a>
            <a class="tertiary-cta" 
                href="{{storeDetails | formatCarSalesUrl: findStoreConfig.SalesUrl }}"
                title="{{findStoreConfig.VehiclesOnSaleCta.Text}}"
                (click)="formInfoService.reportVehiclesOnSaleClick()"
                target="_blank"
                [attr.aria-label]="findStoreConfig.VehiclesOnSaleCta.AdaText">
                {{findStoreConfig.VehiclesOnSaleCta.Text}}
            </a>
            <dt-cta-tab-group [store]="storeDetails" *ngIf="screenSize != 'xs' && screenSize != 'sm' && screenSize != 'md' "></dt-cta-tab-group>

        </div>
    </div>
    <div class="row" *ngIf="screenSize === 'xs' || screenSize === 'sm' || screenSize === 'md' ">
        <div class="col-12 col-md-6 offset-md-3">
            <dt-cta-tab-group [store]="storeDetails" [fromResults]="false" ></dt-cta-tab-group>
        </div>
    </div>
    <div class="row">
        <div class="col-12 marketing-content">
            {{ storeDetails.MarketingCopy1 }}
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12">
            <mat-accordion class="accordion-storedetails">
                <mat-expansion-panel id="store-hours"  class="store-hours" [expanded]="true" (opened)="formInfoService.reportToCallClick(storeDetailsConfig.DrawersLabels.StoreHours.Text, false)">
                    <mat-expansion-panel-header  [collapsedHeight]="'60px'" [expandedHeight]="'60px'">
                        <h3>{{storeDetailsConfig.DrawersLabels.StoreHours.Text}}</h3>
                    </mat-expansion-panel-header>
                    <div class="panel-container">
                        <table *ngFor="let dep of storeDetails.Department">
                            <caption>{{ dep.DepartmentType }} {{ storeDetailsConfig.HoursLabel}}</caption>
                            <tr>
                                <td>{{dep.DetailedHours[6].Day}}</td>
                                <td class="text-right">
                                    <ng-container *ngIf="dep.DetailedHours[6].StartTime !== ''">{{dep.DetailedHours[6].StartTime}} - {{dep.DetailedHours[6].EndTime}}</ng-container>
                                    <ng-container *ngIf="dep.DetailedHours[6].StartTime === ''">{{storeDetailsConfig.StoreClosedLabel}}</ng-container>
                               </td>
                            </tr>
                            <tr>
                                <td>{{dep.DetailedHours[0].Day}} - {{dep.DetailedHours[4].Day}}</td>
                                <td class="text-right">
                                    <ng-container *ngIf="dep.DetailedHours[0].StartTime !== ''">{{dep.DetailedHours[0].StartTime}} - {{dep.DetailedHours[0].EndTime}}</ng-container>
                                    <ng-container *ngIf="dep.DetailedHours[0].StartTime === ''">{{storeDetailsConfig.StoreClosedLabel}}</ng-container>
                                </td>
                            </tr>
                            <tr>
                                <td>{{dep.DetailedHours[5].Day}}</td>
                                <td class="text-right">
                                    <ng-container *ngIf="dep.DetailedHours[5].StartTime !== ''">{{dep.DetailedHours[5].StartTime}} - {{dep.DetailedHours[5].EndTime}}</ng-container>
                                    <ng-container *ngIf="dep.DetailedHours[5].StartTime === ''">{{storeDetailsConfig.StoreClosedLabel}}</ng-container>
                                </td>
                           </tr>
                        </table>
                    </div>
                </mat-expansion-panel>

                <mat-expansion-panel id="new-arribals" (opened)="formInfoService.reportToCallClick(storeDetailsConfig.DrawersLabels.NewestArrivals.Text, false)">
                    <mat-expansion-panel-header  [collapsedHeight]="'60px'" [expandedHeight]="'60px'">
                        <h3>{{storeDetailsConfig.DrawersLabels.NewestArrivals.Text}}</h3>
                    </mat-expansion-panel-header>
                    <div class="panel-container">This the expansion 2222 content</div>
                </mat-expansion-panel>

                <mat-expansion-panel id="service-specials"  (opened)="formInfoService.reportToCallClick(storeDetailsConfig.DrawersLabels.ServiceSpecials.Text, false)">
                    <mat-expansion-panel-header  [collapsedHeight]="'60px'" [expandedHeight]="'60px'">
                        <h3>{{storeDetailsConfig.DrawersLabels.ServiceSpecials.Text}}</h3>
                    </mat-expansion-panel-header>
                    <div class="panel-container">This the expansion 2222 content</div>
                </mat-expansion-panel>

                <mat-expansion-panel id="reviews"  (opened)="formInfoService.reportToCallClick(storeDetailsConfig.DrawersLabels.Reviews.Text, false)">
                    <mat-expansion-panel-header  [collapsedHeight]="'60px'" [expandedHeight]="'60px'">
                        <h3>{{storeDetailsConfig.DrawersLabels.Reviews.Text}}</h3>
                    </mat-expansion-panel-header>
                    <div class="panel-container">
                        <a class="reputation-widget" target="_blank" href="https://widgets.reputation.com/widgets/5e7b6b9562779b7d9ea22dc7/run?tk=5e11589198c" data-tk="5e11589198c" data-widget-id="5e7b6b9562779b7d9ea22dc7" env="" region="us">Reputation Reviews</a>
                    </div>
                </mat-expansion-panel>

            </mat-accordion>
        </div>
    </div>
    <div class="row">
        <div class="col-12 marketing-content">
            {{ storeDetails.MarketingCopy2 }}
        </div>
    </div>
</div>
