import { Component, OnInit, Inject} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IConfig } from '../../interfaces/config.interface';
import { FindStoreConfig } from '../../interfaces/findstore.interface';

@Component({
    selector: 'dt-homepage',
    templateUrl: './homepage.component.html',
    styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {

    FindStoreConfigData: FindStoreConfig;
    seoComponentData: {};
    isIframe = false;

    constructor(
        @Inject('config') public SitecoreConfig: IConfig,
        private readonly route: ActivatedRoute
    ) {
     }

    ngOnInit() {
        /* istanbul ignore next */
        if (this.route.data) {
            this.route.data.subscribe(v =>
                this.isIframe = v.isIframe
            );
        }

        this.FindStoreConfigData = this.SitecoreConfig.Sitecore.FindStoreConfig;
        this.seoComponentData = this.SitecoreConfig.Sitecore.FindStoreConfig.SeoPageDescription;
    }
}
