import { Injectable, Inject } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { IZipCodeData, IZipCodeRequest, IZipCodeCityStateRequestData, IZipCodeResponse } from '../interfaces/IZipCode.interface';
import { HttpClient } from '@angular/common/http';
import { DevModeService } from './dev-mode.service';
import { map, delay, shareReplay, catchError } from 'rxjs/operators';
import { IConfig } from '../interfaces/config.interface';
import { ZipCodeDataMock, ZipCodeResponseMock } from '../../tests/responses.mock';

@Injectable({
  providedIn: 'root'
})
export class GeolocatorService {
  private readonly positionEmittedSource = new Subject<IZipCodeData>();
  private readonly locationChangeEmittedSource = new Subject<string>();

  positionEmitted$ = this.positionEmittedSource.asObservable();
  locationChangeEmitted$ = this.locationChangeEmittedSource.asObservable();

  cache = {};

  constructor(@Inject('config') public SitecoreConfig: IConfig,
              private readonly http: HttpClient,
              public devModeService: DevModeService) { }

  locationKey = this.SitecoreConfig.Sitecore.FindStoreConfig.LocationKey;

  getCurrentPosition(geolocationSuccess, geolocationError) {
    return navigator.geolocation.getCurrentPosition(geolocationSuccess, geolocationError);
  }

  emitLocation(locationData: IZipCodeData) {
    this.positionEmittedSource.next(locationData);
  }

  emitLocationChange(cityState: string) {
    this.locationChangeEmittedSource.next(cityState);
  }

  updateUserZipCode(zipCodeData: IZipCodeData): Observable<IZipCodeData> {
    const url = this.SitecoreConfig.Sitecore.FindStoreConfig.ValidateZipCodeAPIURL;
    let getZipCode: any;
    /* istanbul ignore next */
    if (zipCodeData.PostalCode) {
      getZipCode = zipCodeData.PostalCode;
    } else if (sessionStorage.getItem('currentZipCode')) {
      getZipCode = sessionStorage.getItem('currentZipCode');
    } else {
      getZipCode = this.getCookie('PrevZipCode');
    }
    const zipCodeRequest: IZipCodeRequest = {
      zipCode: getZipCode
    };
    if (this.devModeService.isDevMode()) {
      return of(ZipCodeDataMock).pipe(delay(100));
    } else {
      return this.http.post<IZipCodeData>(url, zipCodeRequest);
    }
  }

  updateCurrentUserZipCode(zipCodeData: IZipCodeData): Observable<IZipCodeResponse> {
    /* istanbul ignore next */
    const url = this.SitecoreConfig.Sitecore.FindStoreConfig.CurrentZipCodeAPIURL;
    const zipCodeRequest: IZipCodeCityStateRequestData = {
      city: zipCodeData.City,
      state: zipCodeData.StateCode
    };
    /* istanbul ignore next */
    if (this.devModeService.isDevMode()) {
      return of(ZipCodeResponseMock).pipe(delay(100));
    } else {
      return this.http.post<IZipCodeResponse>(url, zipCodeRequest);
    }
  }

  getCookie(cname: string) {
    const name = cname + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    /* istanbul ignore next */
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      /* istanbul ignore else */
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  getZipCodeData(cityZipcode: string): Observable<IZipCodeData | null> {

    let searchQuery = cityZipcode;
    const cityState = cityZipcode.split(',');
    if (cityState.length === 1) {
      searchQuery = cityState[0].trim() + ',USA';
    }

    if (cityState.length === 2) {
      searchQuery = cityState[0].trim() + ',' + cityState[1].trim();
    }

    const url = `${this.SitecoreConfig.Sitecore.FindStoreConfig.LocationsAPIURL}` +
    `?jsonp=JSONP_CALLBACK&key=${this.locationKey}&q=${searchQuery}`;
     /* istanbul ignore else */
     /* istanbul ignore next */
    if (this.cache[url]) {
      /* istanbul ignore next */
      return this.cache[url];
    }
    this.cache[url] = this.http.jsonp<any>(url, 'JSONP_CALLBACK')
    .pipe(
      shareReplay(1),
      catchError(err => {
        delete this.cache[url];
        return new Observable();
    }),
      map( (bingData) => {
        const zipCodeData = this.mapBingToZipCodeData(bingData, cityZipcode);
        // when searching by city, state
        // if postal code  missing in zipCodeData
        // assign from calling GetZipByCityState API
         /* istanbul ignore else */
        /* istanbul ignore next */
        // if (zipCodeData && zipCodeData.PostalCode === undefined) {
        //   /* istanbul ignore next */
        //   this.updateCurrentUserZipCode(zipCodeData).subscribe(
        //     (getCurrentZipcode: IZipCodeResponse) => {
        //       zipCodeData.PostalCode = getCurrentZipcode.ZipCodeData.PostalCode;
        //     },
        //     error => {
        //       console.log(error);
        //     }
        //   );
        // }
        // /* istanbul ignore else */
        // /* istanbul ignore next */
        // if (zipCodeData) {
        //   this.updateUserZipCode(zipCodeData).subscribe(zipcode => {
        //     if (zipcode) {
        //       document.cookie = 'disableBrowserGeoLocation=true; path=/';
        //     }
        //   });
        // }
        return zipCodeData;
      }));
    return this.cache[url];
  }

  printCityState(zipCodeData: IZipCodeData): string {
    /* istanbul ignore else */
    if (zipCodeData) {
      return zipCodeData.City + ', ' + zipCodeData.StateCode;
    }
    return '';
  }


  convertPosition(position: GeolocationPosition): Observable<IZipCodeData> {
    const latitude = position.coords.latitude.toString().trim();
    const longitude = position.coords.longitude.toString().trim();
    let returnObv: Observable<any>;
    const url = `${this.SitecoreConfig.Sitecore.FindStoreConfig.LocationsAPIURL}/` +
      `${latitude},${longitude}?jsonp=JSONP_CALLBACK&key=${this.locationKey}`;
    returnObv = this.http.jsonp<any>(url, 'JSONP_CALLBACK');
    return returnObv.pipe(
      map((bingData) => {
        return this.mapBingToZipCodeData(bingData);
      }));
  }

  mapBingToZipCodeData(bingData: any, cityState?: string): IZipCodeData | null {
    const bingResource = bingData.resourceSets[0].resources.filter(resource => {
      return (resource.confidence === 'High' && (cityState ? resource.address.formattedAddress.indexOf(cityState) >= 0 : true));
    });
    if (bingResource.length > 0) {
      return {
        PostalCode: bingResource[0].address.postalCode,
        Latitude: bingResource[0].point.coordinates[0],
        Longitude: bingResource[0].point.coordinates[1],
        City: this.getCity(bingResource, cityState),
        StateCode: bingResource[0].address.adminDistrict,
        ValidPostalCode: bingResource[0].address.postalCode ? true : false
      };
    } else {
      return null;
    }
  }

  private getCity(bingResource: any, cityState?: string) {
    let city = bingResource[0].address.locality;
    /* istanbul ignore next */
    if (!city || (cityState && cityState !== bingResource[0].address.postalCode)) {
      city = bingResource[0].address.formattedAddress.split(',')[0];
    }
    return city;
  }
}
