import { Component, OnInit, Inject, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { IConfig } from '../../interfaces/config.interface';
import { SearchCriteria, ResultsPage, Make, SearchCriteriaType, FindStoreConfig } from '../../interfaces/findstore.interface';
import { SearchState } from '../../services/searchState.service';
import { UpdateURL } from '../../utils/utils.functions';
import { ModalComponent } from '../modal/modal.component';
import { BreakpointService } from '../../services/breakpoint.service';
import { FormInfoService } from '../../services/form-info.service';

@Component({
    selector: 'dt-subheadercomponent',
    templateUrl: './subheader.component.html',
    styleUrls: ['./subheader.component.scss']
})

export class SubheaderComponent implements OnInit {

    loadingState: boolean;
    resultsPageData: ResultsPage;
    listView = false;
    @Output() isListView = new EventEmitter<boolean>();
    @Input() storeCount: number;
    @Input() countPerPage: number;
    @Input() storeLength: number;
    @Input() showListViewLink: boolean;
    searchCriteria: SearchCriteria;
    departmentTypes: Make[];
    listDepartments: Make[];
    allDepartment: Make;
    modalDialog: any;
    screenSize = '';
    findStoreConfig: FindStoreConfig;
    constructor(
        @Inject('config') public SitecoreConfig: IConfig,
        public dialog: MatDialog,
        private readonly SearchStateService: SearchState,
        private readonly router: Router,
        private readonly breakpointService: BreakpointService,
        private readonly formInfoService: FormInfoService
    ) {
        this.findStoreConfig = this.SitecoreConfig.Sitecore.FindStoreConfig;
        this.resultsPageData = this.findStoreConfig.ResultsPage;
        this.allDepartment = {
            Value: this.findStoreConfig.FindStoreForm.DepartmentTypes.AllValue,
            Description: this.findStoreConfig.FindStoreForm.DepartmentTypes.AllCaption
        };
        this.departmentTypes = [
            ...this.findStoreConfig.FindStoreForm.DepartmentTypes.Departments,
            this.allDepartment
        ];
        this.listDepartments = [];
    }

    ngOnInit() {
        this.breakpointService.breakpoint.subscribe(breakpoint => {
            this.screenSize = breakpoint;
            if (this.screenSize !== 'xs' && this.screenSize !== 'sm') {
                this.listView = false;
            }
        });
        this.SearchStateService.searchCriteriaBroadcasted$.subscribe(data => {
            if (data.Criteria.makes.length <= 0 && data.Criteria.Departments.length <= 0) {
                data.Criteria.Departments = [this.allDepartment.Value];
            }
            this.searchCriteria = {
                Location: data.Criteria.Location,
                makes: data.Criteria.makes,
                Departments: data.Criteria.Departments
            };
            this.listDepartments = [];
            this.getListDeparments();
        });
        window.scrollTo(0, 0);
    }

    toggleListView() {
        const label = this.listView ? this.resultsPageData.MapViewCta.Text : this.resultsPageData.ListViewCta.Text;
        this.formInfoService.reportListMapViewClick(label);
        this.listView = !this.listView;
        this.isListView.emit(this.listView);
    }

    getListDeparments() {
        this.searchCriteria.Departments.forEach(dep => {
            this.departmentTypes.some(type => {
                if (type.Value === dep) {
                    this.listDepartments.push(type);
                }
            });
        });
    }

    deleteDeparment(indexDep: number, e) {
        e.preventDefault();
        this.listDepartments.splice(indexDep, 1);
        this.updateSearch(SearchCriteriaType.updateTagsSearch);
    }

    deleteMake(make: string, e) {
        e.preventDefault();
        const getIndexMake = this.searchCriteria.makes.indexOf(make);
        this.searchCriteria.makes.splice(getIndexMake, 1);
        if (this.searchCriteria.makes.length === 0) {
            this.listDepartments = [this.allDepartment];
        }
        this.updateSearch(SearchCriteriaType.updateTagsSearch);
    }

    updateSearch(searchType: SearchCriteriaType) {
        this.searchCriteria.Departments = this.listDepartments.map(dep => dep.Value);
        this.searchCriteria.CurrentPage = 0;
        UpdateURL(
            this.searchCriteria.makes,
            this.searchCriteria.Departments,
            this.searchCriteria.Location,
            this.searchCriteria.City,
            this.searchCriteria.State,
            this.router
        );
        this.SearchStateService.broadcastSearchCriteria({
            Criteria: this.searchCriteria,
            Type: searchType
        });
    }

  openDialog(): void {
        /* istanbul ignore if */
        if (this.storeCount > 0 ) {
        this.SearchStateService.broadcastSearchCriteria({
            Criteria: this.searchCriteria,
            Type: SearchCriteriaType.updateLocationSearch
        });
        this.modalDialog = this.dialog.open(ModalComponent, {
            width: '90%',
            panelClass: 'srp-bootstrap',
            data: { loadComponent: 'fasform' }
        });
        }
  }

    getResultsCount() {
        return `${this.storeLength} ${this.findStoreConfig.StoresOf} ${this.storeCount}`;
    }

    getStoreCount() {
        return this.storeCount;
    }
}
