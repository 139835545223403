import { Component, OnInit, Inject } from '@angular/core';
import { IConfig } from '../../interfaces/config.interface';
import { FindStoreConfig } from '../../interfaces/findstore.interface';

@Component({
    selector: 'dt-collisionForComponent',
    templateUrl: './collisionForm.component.html',
    styleUrls: ['./collisionForm.component.scss']
})
export class CollisionFormComponent implements OnInit {

    FindStoreConfigData: FindStoreConfig;

    constructor(
        @Inject('config') public SitecoreConfig: IConfig,
    ) { }

    ngOnInit() {
        this.FindStoreConfigData = this.SitecoreConfig.Sitecore.FindStoreConfig;
    }
}
