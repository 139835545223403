import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DevModeService } from './dev-mode.service';
import { Observable, of } from 'rxjs';
import { map, shareReplay, catchError } from 'rxjs/operators';
import { IConfig } from '../interfaces/config.interface';

@Injectable({
  providedIn: 'root'
})
export class LocationAutocompleteService {

  cache = {};

  constructor(
    @Inject('config') public SitecoreConfig: IConfig,
    private readonly http: HttpClient,
    public devModeService: DevModeService
  ) {}
  locationKey = this.SitecoreConfig.Sitecore.FindStoreConfig.LocationKey;



  getLocations(filterText: string): Observable<[any]> {
    if (filterText) {
      const appendFilterValue = filterText;
      const url = `${this.SitecoreConfig.Sitecore.FindStoreConfig.AutosuggestAPIURL}?` +
        `query=${appendFilterValue}&countryFilter=US&includeEntityTypes=Place&jsonp=JSONP_CALLBACK&key=${this.locationKey}`;
      if (this.cache[url]) {
        return this.cache[url];
      }
      this.cache[url] = this.http.jsonp<any>(url, 'JSONP_CALLBACK')
        .pipe(
          shareReplay(1),
          catchError(err => {
            delete this.cache[url];
            return new Observable();
          }),
          map(resp => {
            const locations = resp.resourceSets[0].resources[0].value
              .filter((val: any) => {
                return val.address.locality && val.address.adminDistrict && val.address.adminDistrict2 ;
              })
              .map(
                (val: any) => {
                  const city = val.address.locality.split(',');
                  const cityState = [city[city.length - 1].trim().replace(/\./g, ''),
                  ` ${this.getStateCode(val.address.adminDistrict)}`].join(',');
                  return {
                    name: cityState,
                    value: cityState
                  };
                });
            return locations.reduce((acc, cur) =>
              acc.some((x: any) => (x.name === cur.name)) ? acc : acc.concat(cur), []);
          }));
      return this.cache[url];
    } else {
      return of([{}]);
    }
  }

  getStateCode(stateFullName: string): string {
    const stateList = {
      Arizona: 'AZ',
      Alabama: 'AL',
      Alaska: 'AK',
      Arkansas: 'AR',
      California: 'CA',
      Colorado: 'CO',
      Connecticut: 'CT',
      Delaware: 'DE',
      Florida: 'FL',
      Georgia: 'GA',
      Hawaii: 'HI',
      Idaho: 'ID',
      Illinois: 'IL',
      Indiana: 'IN',
      Iowa: 'IA',
      Kansas: 'KS',
      Kentucky: 'KY',
      Louisiana: 'LA',
      Maine: 'ME',
      Maryland: 'MD',
      Massachusetts: 'MA',
      Michigan: 'MI',
      Minnesota: 'MN',
      Mississippi: 'MS',
      Missouri: 'MO',
      Montana: 'MT',
      Nebraska: 'NE',
      Nevada: 'NV',
      'New Hampshire': 'NH',
      'New Jersey': 'NJ',
      'New Mexico': 'NM',
      'New York': 'NY',
      'North Carolina': 'NC',
      'North Dakota': 'ND',
      Ohio: 'OH',
      Oklahoma: 'OK',
      Oregon: 'OR',
      Pennsylvania: 'PA',
      'Rhode Island': 'RI',
      'South Carolina': 'SC',
      'South Dakota': 'SD',
      Tennessee: 'TN',
      Texas: 'TX',
      Utah: 'UT',
      Vermont: 'VT',
      Virginia: 'VA',
      Washington: 'WA',
      'West Virginia': 'WV',
      Wisconsin: 'WI',
      Wyoming: 'WY'
    };

    return stateList[stateFullName] ? stateList[stateFullName] : stateFullName;
  }
}
