import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { SearchCriteriaType, ISearchCriteria } from '../interfaces/findstore.interface';

@Injectable({ providedIn: 'root' })

export class SearchState {

    // eslint-disable-next-line max-len
    private readonly notificationSource = new BehaviorSubject<ISearchCriteria | null>({
        Type: SearchCriteriaType.initilizeSearch,
        Criteria: {
            Location: {
                PostalCode: '',
                StateCode: '',
                City: '',
                Latitude: 0,
                Longitude: 0,
                ValidPostalCode: true
            },
            City: '',
            State: '',
            makes: [],
            Departments: []
        }
    });
    searchCriteriaBroadcasted$: Observable<ISearchCriteria> = this.notificationSource.asObservable();

    constructor() {
        this.searchCriteriaBroadcasted$.subscribe((notification: ISearchCriteria) => {
            switch (notification.Type) {
                case SearchCriteriaType.initilizeSearch:
                    console.log(SearchCriteriaType.initilizeSearch, notification.Criteria);
                    break;
                case SearchCriteriaType.updateTagsSearch:
                    console.log(SearchCriteriaType.initilizeSearch, notification.Criteria);
                    break;
                case SearchCriteriaType.updateLocationSearch:
                    console.log(SearchCriteriaType.initilizeSearch, notification.Criteria);
                    break;
            }
        });
    }

    broadcastSearchCriteria(notification: ISearchCriteria) {
        this.notificationSource.next(notification);
    }
}
