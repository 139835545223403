import { Component, OnInit, Inject } from '@angular/core';
import { IConfig } from '../../interfaces/config.interface';
import { FindStoreConfig, ImageSitecore } from '../../interfaces/findstore.interface';
import { BreakpointService } from '../../services/breakpoint.service';

@Component({
    selector: 'dt-bannerTopComponent',
    templateUrl: './bannerTop.component.html',
    styleUrls: ['./bannerTop.component.scss']
})
export class BannerTopComponent implements OnInit {

    FindStoreConfigData: FindStoreConfig;
    screenSize = '';
    imgBg: ImageSitecore;

    constructor(
        private readonly breakpointService: BreakpointService,
        @Inject('config') public SitecoreConfig: IConfig,
    ) { }

    ngOnInit() {
        this.FindStoreConfigData = this.SitecoreConfig.Sitecore.FindStoreConfig;
        this.breakpointService.breakpoint.subscribe(breakpoint => {
            this.screenSize = breakpoint;
            this.updateBgImg();
        });
        this.updateBgImg();
    }

    updateBgImg() {
        switch (this.screenSize) {
          case 'lg':
                this.imgBg = this.FindStoreConfigData.Image_Tablet;
                break;
            case 'xl':
                this.imgBg = this.FindStoreConfigData.Image_Desktop;
                break;
            case 'md':
            case 'sm':
                this.imgBg = this.FindStoreConfigData.Image_Tablet;
                break;
            default:
                this.imgBg = this.FindStoreConfigData.Image_Mobile;
                break;
        }
    }
}
