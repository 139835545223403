import { IZipCodeData } from './IZipCode.interface';
import { ImageType } from './customer.interface';
import { StoreDetailsConfig } from './storeDetails.interface';
import { IStoreSearchResult } from './search.interface';

export interface Language {
    Name: string;
}
export interface InputField {
    Label: string;
    Placeholder: string;
    ErrorMessage?: string;
    Required?: boolean;
}

export interface HeaderField {
    FasTitleText: string;
    NoStoreError: string;
}

export interface InputRadioField {
    Label: string;
    Placeholder: string;
    ErrorMessage?: string;
    Required?: boolean;
    ListOptions: Make[];
}

export interface Cta {
    Text: string;
    AdaText?: string;
}
export enum SearchCriteriaType {
    initilizeSearch = 'init',
    updateTagsSearch = 'update_tags',
    updateLocationSearch = 'information'
}
export interface ISearchCriteria {
    Type: SearchCriteriaType;
    Criteria?: SearchCriteria;
}

export interface Language {
    Name: string;
}
export interface ImageSitecore {
    Alt: string;
    Border: string;
    Class: string;
    Height: number;
    HSpace: number;
    Src: string;
    VSpace: number;
    Width: number;
    MediaId: string;
    Title: string;
    Language: Language;
}
export interface SearchCriteria {
    Location?: IZipCodeData;
    City?: string;
    State?: string;
    makes: string[];
    Departments: string[];
    CurrentPage?: number;
}

export interface Make {
    Description: string;
    Value: string;
}

export interface MakeGroup {
    disabled?: boolean;
    name: string;
    makes: Make[];
}

export interface LocationUser {
    PreferredZipCode: string;
    GeoZipCode: string;
    City: string;
    StateCode: string;
    Latitude: string;
    Longitude: string;
}

export interface DepartmentTypes {
    AllCaption: string;
    AllValue: string;
    Departments: Make[];
}

export interface BaseSearchTypes {
    LabelInterest: string;
    LabelSales: string;
    LabelService: string;
    LabelCollision: string;
}

export interface FindStoreForm {
    LocationInput: InputField;
    HeaderInputs: HeaderField;
    MakesInput: InputField;
    CtaFindStore: Cta;
    CtaCurrentLocation: Cta;
    CtaUpdateCurrentLocation: Cta;
    DepartmentTypes: DepartmentTypes;
    BaseSearchSection: BaseSearchTypes;
}

export interface IBrandLogo {
    Alt: string;
    Src: string;
    Width: number;
    Height: number;
}

export interface SeoPageDescription {
    Title: string;
    Text: string;
    ReadMoreCta: Cta;
    ReadLessCta: Cta;
}

export interface ResultsPage {
    IsListView: boolean;
    Title: string;
    ListViewCta: Cta;
    MapViewCta: Cta;
    UpdateCta: Cta;
    RemoveTagCta: Cta;
    LoadMoreCta: Cta;
    BackTopCta: Cta;
    ServiceCta: Cta;
    SalesCta: Cta;
    CollisionCta: Cta;
    StoreOpenLabel: Cta;
    StoreCloseLabel: Cta;
    NoMapTitle: string;
    NoMapText: string;
    NoMapIcon: ImageType;
    NoResultsIcon: ImageType;
    CloseIcon: ImageType;
    NoResultsTitle: string;
    NoResultsText: string;
}

export interface MakeAppointmentForm {
    CtaSubmitForm: Cta;
    FirstNameInput: InputField;
    LastNameInput: InputField;
    EmailInput: InputField;
    PhoneInput: InputField;
    ZipCodeInput: InputField;
    YearInput: InputField;
    MakeInput: InputField;
    ModelInput: InputField;
    InsuranceInput: InputField;
    CarDrivableInput: InputRadioField;
}

export interface ThankYouComponent {
    Title: string;
    Text1: string;
    Text2: string;
    Image: ImageType;
    CloseCta: Cta;
}

export interface FindStoreConfig {
    Title: string;
    SearchRadio: number;
    NumSuggestionsLocations: number;
    TilesPerPage: number;
    CurrentPage: number;
    FindStoreForm: FindStoreForm;
    Id: string;
    Language: Language;
    Version: number;
    NoVoiPath: string;
    HasVoiPath: string;
    ResultsPage: ResultsPage;
    Image_Mobile: ImageSitecore;
    Image_Tablet: ImageSitecore;
    Image_Desktop: ImageSitecore;
    LocationKey: string;
    LocationKeyGoogleMaps: string;
    SeoPageDescription: SeoPageDescription;
    MakeAppointmentForm: MakeAppointmentForm;
    ThankYouComponent: ThankYouComponent;
    GetMakesURL: string;
    ValidateZipCodeAPIURL: string;
    CurrentZipCodeAPIURL: string;
    LocationsAPIURL: string;
    AutosuggestAPIURL: string;
    GoogleMapUrl: string;
    SalesUrl: string;
    VehiclesOnSaleCta: Cta;
    StoreDetailsCta: Cta;
    StoreDetailsConfig?: StoreDetailsConfig;
    ReputationComScriptWidget?: string;
    StoresOf: string;
}

export interface Makes {
    NewMakes: Make[];
    UsedMakes: Make[];
}

export interface Sitecore {
    FindStoreConfig: FindStoreConfig;
    TealiumSettings: TealiumSettings;
    Makes: Makes;
    ZipCodeData: IZipCodeData;
    isAzureMapsEnabled?: boolean;
    isHeadless: boolean;
}

export interface UserLocation {
    lattotid: FindStoreConfig;
    long: TealiumSettings;
}

export interface TealiumSettings {
    TealiumAccount: string;
    TealiumProfile: string;
    TealiumENV: string;
}

export interface ITabClicked {
    isTabClicked: boolean;
    tileId: number;
    mapContainer: string;
    storeDetails: IStoreSearchResult;
}
