import { Injectable } from '@angular/core';
import { TransferState, makeStateKey } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class StateService {
  state: any = null;

  constructor(private readonly transferState: TransferState) {
    this.readState();
    // eslint-disable-next-line @typescript-eslint/dot-notation
    if (!this.transferState['store']['Sitecore']) {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      this.transferState['store'] = this.state;
    }
  }

  readState() {
    const element = (document.getElementById(
      'my-app-state'
    ) as HTMLInputElement) || { innerText: '' };

    this.state = JSON.parse(
      element.innerText !== ''
        ? element.innerText
            .replace(new RegExp('&q;', 'g'), '"')
            .replace(new RegExp('&a;', 'g'), '&')
            // eslint-disable-next-line @typescript-eslint/quotes
            .replace(new RegExp('&s;', 'g'), "'")
            .replace(new RegExp('&l;', 'g'), '<')
            .replace(new RegExp('&g;', 'g'), '>')
        : '{}'
    );
  }

  deleteState() {
    const element = document.getElementById('my-app-state') as HTMLInputElement;
    element.innerText = '';
  }

  get(key: string): any {
    const transferKey = makeStateKey(key);
    return this.transferState.get(transferKey, null as any);
  }

  remove(key: string) {
    const transferKey = makeStateKey(key);
    return this.transferState.remove(transferKey);
  }

  found(key: string) {
    const transferKey = makeStateKey(key);
    return this.transferState.hasKey(transferKey);
  }
}
