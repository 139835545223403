import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { IConfig } from '../../interfaces/config.interface';
import { FindStoreConfig } from '../../interfaces/findstore.interface';
import { LoadingLayout } from '../../services/loadingLayout.service';
import { FindStorePubSub } from '../../services/findstorepubsub.service';
import { FormInfoService } from '../../services/form-info.service';

// TEMPORAL OBJECT TEST
import { StoreDetailsMock } from '../../../tests/responses.mock';
import { StoreDetails, StoreDetailsConfig } from '../../interfaces/storeDetails.interface';
import { BreakpointService } from '../../services/breakpoint.service';

@Component({
    selector: 'dt-storedetailspage',
    templateUrl: './storedetailspage.component.html',
    styleUrls: ['./storedetailspage.component.scss']
})
export class StoreDetailsPageComponent implements OnInit, AfterViewInit {

    @ViewChild('storeReviews') storeReviews: ElementRef;
    findStoreConfig: FindStoreConfig;
    storeDetailsConfig: StoreDetailsConfig;
    storeId: number;
    storeDetails: StoreDetails;
    screenSize = '';
    googleMapUrl = '';

    constructor(
        @Inject('config') public SitecoreConfig: IConfig,
        private readonly breakpointService: BreakpointService,
        private readonly LoadingLayoutService: LoadingLayout,
        private readonly route: ActivatedRoute,
        private readonly FindStorePubSubService: FindStorePubSub,
        public formInfoService: FormInfoService,

    ) {
        this.LoadingLayoutService.showLoader();
        this.storeDetails = StoreDetailsMock;
        this.findStoreConfig = this.SitecoreConfig.Sitecore.FindStoreConfig;
        this.storeDetailsConfig =  this.findStoreConfig.StoreDetailsConfig;
        this.storeId = this.route.snapshot.params.storeId;
        this.googleMapUrl = this.findStoreConfig.GoogleMapUrl;
        this.loadReputationScript();
        this.breakpointService.breakpoint.subscribe(breakpoint => {
            this.screenSize = breakpoint;
        });
        /* istanbul ignore next */
        this.formInfoService.reportStoreDetailsPageDisplayed(
            this.storeDetails.Phone,
            this.storeDetails.StoreId,
            this.findStoreConfig && this.findStoreConfig.Language ?
            this.findStoreConfig.Language.Name : '');
    }

    ngOnInit() {
        this.LoadingLayoutService.hideLoader();
    }

    ngAfterViewInit() {
        if (this.storeDetails.AverageRating >= 4) {
            this.FindStorePubSubService.getReviewsStores(this.storeId, this.storeDetailsConfig).subscribe(
                (reviewData: string) => {
                    this.storeReviews.nativeElement.innerHTML = reviewData;
                },
                /* istanbul ignore next */
                error => {
                    console.log(error);
                    this.LoadingLayoutService.hideLoader();
                }
            );
        }
    }

    loadReputationScript() {
        const node = document.createElement('script');
        node.innerHTML = this.findStoreConfig.ReputationComScriptWidget;
        document.getElementsByTagName('body')[0].appendChild(node);
    }

}
