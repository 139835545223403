export const UDO = Object.freeze({
    NotSet: '(not set)',
    Type: '1',
    ActionType: 'EngagementType_Find Store|||click',
    EngagementType: 'EngagementType',

    HomepageCatL1: 'Find Store',
    HomepageCatL2: 'Home',
    HomepageCatL3: '',
    Pagegroup: 'find_store',

    SelectMakeEventLabel: 'Select Brand',
    SelectMakeEvent: 'find_store_select_brand',

    CurrentLocationEventLabel: 'Use Current Location',
    CurrentLocationEvent: 'use_current_location',
    CurrentLocationEventCat: 'UserActivityType',
    CurrentLocationEventAction: 'UserActivityType_Find Store|||Click',

    FasFormName: 'find_store',
    FasFormActualName: 'find_store',
    FasFormEvent: 'find_store',
    FasFormEventCat: 'UserActivityType',
    FasFormEventAction: 'UserActivityType_Find Store|||Click',
    FasFormEventLabel: 'Find Store-Form Load',

    FasFormEventStart: 'find_store_form_start',
    FasFormEventStartLabel: 'Find Store-Form Started',

    FasFormEventSubmitStart: 'find_store',
    FasFormEventSubmitStartLabel: 'Find Store',

    FasFormEventSubmitSuccess: 'find_store_form_success',
    FasFormEventSubmitSuccessLabel: 'Find Store-Submit Success',

    ResultspageCatL1: 'Find Store',
    ResultspageCatL2: 'Results',
    ResultspageCatL3: '',
    NoResult: '1',
    ResultsPage: '1',
    SearchesViews: '1',
    ResultsFASFormActualName: 'find-service|find-store|find-collision|find-store',
    ResultspageClickEventAction: 'EngagementType_Find Store|||Click',
    ResultspageStoreDetailsEvent: 'view_store_details',
    ResultspageStoreDetailsEventLabel: 'View Store Details',
    ResultspageListMapViewEvent: 'list_view_click|map_view_click',
    ResultspageCallCtaEventAction: 'EngagementType_Find Store|||Phone',

    ResultspageDeptToggleEventventAction: 'EngagementType_Find Store|||Click',

    ResultspageDeptToggleEventLabelService: 'Service Toggle',
    ResultspageDeptToggleEventService: 'toggle_service',

    ResultspageDeptToggleEventLabelSales: 'Sales Toggle',
    ResultspageDeptToggleEventSales: 'toggle_sales',

    ResultspageDeptToggleEventCollision: 'toggle_collision',
    ResultspageDeptToggleEventLabelCollision: 'Collision Toggle',

    // STORE DETAILS PAGE UDO
    SdpCatL2: 'Store Details Page',
    EnhAction: 'impression',
    SdpEvent: 'sdp_view',
    SdpEventCat: 'UserActivityType',
    SdpEventAction: 'UserActivityType_SDP|||Page Load',
    SdpEventLabel: 'Page load',
    SdpPagegroup: 'SDP',

    SdpEventActionClick: 'UserActivityType_SDP|||click',

    MyStoreCtaEvent: 'sdp_my_store_click',
    MyStoreCtaEventLabel: 'Make This My Store',

    CallCtaEvent: 'phone_click',
    CallCtaEventCat: 'EngagementType',
    CallCtaEventAction: 'EngagementType_Find Store|||Phone',

    AdressCtaEvent: 'map_click',
    AdressCtaEventCat: 'EngagementType',
    AdressCtaEventLabel: 'Map Click',
    AdressCtaEventAction: 'EngagementType_Find Store|||Click',

    VehiclesOnSaleCtaEvent: 'vehicles_on_sale_click',
    VehiclesOnSaleCtaEventLabel: 'Vehicles on Sale',
    VehiclesOnSaleCtaEventAction: 'EngagementType_Find Store|||Click',

    ScheduleServiceCtaEvent: 'schedule_service_click',
    ScheduleServiceCtaEventLabel: 'Schedule Service',

    ScheduleSalesCtaEvent: 'store_appointment_click',
    ScheduleSalesCtaEventLabel: 'Schedule Store Appointment',

    ScheduleCollisionCtaEvent: 'collision_form_click',
    ScheduleCollisionCtaEventLabel: 'Collision Form ',

    ResultspageScheduleCTAEventAction: 'EngagementType_Find Store|||Click',
    DrawerClickEvent: 'drawer_click'
    // END STORE DETAILS PAGE UDO
});
