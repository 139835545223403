import { Component, OnInit, Inject, Input, ViewChild, ElementRef, AfterViewInit  } from '@angular/core';
import { IConfig } from '../../interfaces/config.interface';
import { SeoPageDescription } from '../../interfaces/findstore.interface';
import { BreakpointService } from '../../services/breakpoint.service';

@Component({
    selector: 'dt-seoTextComponent',
    templateUrl: './seoText.component.html',
    styleUrls: ['./seoText.component.scss']
})
export class SeoTextComponent implements OnInit, AfterViewInit {

    @Input() dataComponent: SeoPageDescription;
    screenSize = '';
    showMoreText = false;
    IsResultPage = false;

    @ViewChild('showCta') showCta: ElementRef;
    @ViewChild('paragraph') paragraph: ElementRef;
    @ViewChild('pContainer') pContainer: ElementRef;

    hiddenClass = 'hidden';
    showMore = 'show-more';
    currentHeight = 0;

    constructor(
        @Inject('config') public SitecoreConfig: IConfig,
        private readonly breakpointService: BreakpointService,
    ) { }

    ngOnInit() {
      /* istanbul ignore if */
      if (window.location.href.indexOf('results') > -1) {
        this.IsResultPage = true;
      }      
    }

    ngAfterViewInit() {
        this.breakpointService.breakpoint.subscribe(breakpoint => {
            this.screenSize = breakpoint;
            this.currentHeight = this.paragraph.nativeElement.offsetHeight;
            this.showCtas();
            this.addH3();
        });
    }

    addH3() {
        let strongTitle = document.getElementById('textContainer');
        if (strongTitle != null) {
            let strongs = strongTitle.getElementsByTagName('strong');
            for (let i = 0; i < strongs.length; i++) {
                let oldNode = strongs[i].parentElement;
                let newNode = document.createElement('h3');
                newNode.innerHTML = oldNode.innerHTML;
                newNode.classList.add('text-subtitle');
                oldNode.parentNode.replaceChild(newNode, oldNode);
            }
        }
    }

    toggleText() {
        this.showMoreText = !this.showMoreText;
    }

    showCtas() {
        if (this.currentHeight >= 170) {
            this.pContainer.nativeElement.classList.add(this.showMore);
            this.showCta.nativeElement.classList.add(this.hiddenClass);
        } else {
            this.pContainer.nativeElement.classList.remove(this.showMore);
            this.showCta.nativeElement.classList.remove(this.hiddenClass);
        }
    }

    isMobile() {
        return this.screenSize === 'xs';
    }
}
