<mat-tab-group mat-stretch-tabs [(selectedIndex)]="store.SelectedDepartmentIndex" class="tab-departments" (click)="onTabChange($event)">
    <mat-tab *ngFor="let department of store.Department" label="{{department.DepartmentType}}">
        <p class="hours" innerHTML="{{setHours(department.DetailedHours)}}"></p>
        <a tabindex="0" class="store-cta primary-cta" [id]="department.id" [class]="department.class"
            (click)="openLink(department.link, department.DepartmentType, $event, store.StoreId)"
            (click)="openCTA(department.DepartmentType)"
            [attr.data-hid]=store.StoreId>
            <span class="sr-only" [attr.aria-labelledby]="'schedule-service'+ tileId + ' title'+tileId"></span>
            {{setCTA(department.DepartmentType)}}
        </a>
    </mat-tab>
</mat-tab-group>
