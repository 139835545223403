// import { GetDialersResponse } from '../app/services/getDealers.interface';
import { IZipCodeResponse, IZipCodeData } from '../app/interfaces/IZipCode.interface';
import { IStoreSearchResponse, IStoreSearchResult } from '../app/interfaces/search.interface';
import { StoreDetails } from '../app/interfaces/storeDetails.interface';

export const StoreDetailsMock: StoreDetails = {
  Name: 'AutoNation Nissan Miami',
  AddressLine1: '3345 SW 8th Street',
  AddressLine2: '',
  City: 'Miami',
  StateCode: 'FL',
  PostalCode: '33135',
  Phone: '(305) 707-0145',
  Latitude: '25.764849',
  Longitude: '-80.250775',
  Distance: 2.7398269831543316,
  AverageRating: 4,
  ReviewCount: 0,
  Department: [
    {
      Brands: null,
      ContactPhone: null,
      DepartmentType: 'Sales',
      DetailedHours: [
        {
          Day: 'Monday',
          EndTime: '9:00 PM',
          Reoccurence: 'Weekly',
          StartTime: '9:00 AM'
        },
        {
          Day: 'Tuesday',
          EndTime: '9:00 PM',
          Reoccurence: 'Weekly',
          StartTime: '9:00 AM'
        },
        {
          Day: 'Wednesday',
          EndTime: '9:00 PM',
          Reoccurence: 'Weekly',
          StartTime: '9:00 AM'
        },
        {
          Day: 'Thursday',
          EndTime: '9:00 PM',
          Reoccurence: 'Weekly',
          StartTime: '9:00 AM'
        },
        {
          Day: 'Friday',
          EndTime: '9:00 PM',
          Reoccurence: 'Weekly',
          StartTime: '9:00 AM'
        },
        {
          Day: 'Saturday',
          EndTime: '9:00 PM',
          Reoccurence: 'Weekly',
          StartTime: '9:00 AM'
        },
        {
          Day: 'Sunday',
          EndTime: '7:00 PM',
          Reoccurence: 'Weekly',
          StartTime: '11:00 AM'
        }
      ],
      Hours: null,
      Name: null
    },
    {
      Brands: null,
      ContactPhone: null,
      DepartmentType: 'Service',
      DetailedHours: [
        {
          Day: 'Monday',
          EndTime: '7:00 PM',
          Reoccurence: 'Weekly',
          StartTime: '7:00 AM'
        },
        {
          Day: 'Tuesday',
          EndTime: '7:00 PM',
          Reoccurence: 'Weekly',
          StartTime: '7:00 AM'
        },
        {
          Day: 'Wednesday',
          EndTime: '7:00 PM',
          Reoccurence: 'Weekly',
          StartTime: '7:00 AM'
        },
        {
          Day: 'Thursday',
          EndTime: '7:00 PM',
          Reoccurence: 'Weekly',
          StartTime: '7:00 AM'
        },
        {
          Day: 'Friday',
          EndTime: '7:00 PM',
          Reoccurence: 'Weekly',
          StartTime: '7:00 AM'
        },
        {
          Day: 'Saturday',
          EndTime: '5:00 PM',
          Reoccurence: 'Weekly',
          StartTime: '7:00 AM'
        },
        {
          Day: 'Sunday',
          EndTime: '',
          Reoccurence: 'Weekly',
          StartTime: ''
        }
      ],
      Hours: null,
      Name: null
    },
    {
      Brands: null,
      ContactPhone: null,
      DepartmentType: 'Collision',
      DetailedHours: [
        {
          Day: 'Monday',
          EndTime: '6:00 PM',
          Reoccurence: 'Weekly',
          StartTime: '7:30 AM'
        },
        {
          Day: 'Tuesday',
          EndTime: '6:00 PM',
          Reoccurence: 'Weekly',
          StartTime: '7:30 AM'
        },
        {
          Day: 'Wednesday',
          EndTime: '6:00 PM',
          Reoccurence: 'Weekly',
          StartTime: '7:30 AM'
        },
        {
          Day: 'Thursday',
          EndTime: '6:00 PM',
          Reoccurence: 'Weekly',
          StartTime: '7:30 AM'
        },
        {
          Day: 'Friday',
          EndTime: '6:00 PM',
          Reoccurence: 'Weekly',
          StartTime: '7:30 AM'
        },
        {
          Day: 'Saturday',
          EndTime: '12:00 PM',
          Reoccurence: 'Weekly',
          StartTime: '8:00 AM'
        },
        {
          Day: 'Sunday',
          EndTime: '',
          Reoccurence: 'Weekly',
          StartTime: ''
        }
      ],
      Hours: null,
      Name: null
    }
  ],
  Makes: 'Nissan',
  StoreId: '2890',
  StoreDetailedHours: [
    {
      Day: 0,
      StartTime: '9:00 AM',
      EndTime: '9:00 PM',
      Reoccurence: 0
    },
    {
      Day: 1,
      StartTime: '9:00 AM',
      EndTime: '9:00 PM',
      Reoccurence: 0
    },
    {
      Day: 2,
      StartTime: '9:00 AM',
      EndTime: '9:00 PM',
      Reoccurence: 0
    },
    {
      Day: 3,
      StartTime: '9:00 AM',
      EndTime: '9:00 PM',
      Reoccurence: 0
    },
    {
      Day: 4,
      StartTime: '9:00 AM',
      EndTime: '9:00 PM',
      Reoccurence: 0
    },
    {
      Day: 5,
      StartTime: '9:00 AM',
      EndTime: '9:00 PM',
      Reoccurence: 0
    },
    {
      Day: 6,
      StartTime: '11:00 AM',
      EndTime: '7:00 PM',
      Reoccurence: 0
    }
  ],
  StoreDetailsUrl: 'nissan-miami-fl',
  StoreCollisionUrl: '/dealers/nissan-miami-fl/collision-center',
  ReviewUrl: '/dealers/nissan-miami-fl',
  PromoUrl: 'nissan-miami-fl',
  ShowViewStoreDetails: true,
  Rating: 0,
  FleetSalesURL: 'javascript:void(0);',
  // Additional Custom Params
  StoreSpecials: [],
  MarketingCopy1: 'string',
  MarketingCopy2: 'string',
  StoreImages: {
    Desktop: {
      Alt: '',
      Border: '',
      Class: '',
      Height: 650,
      HSpace: 0,
      Src: 'https://via.placeholder.com/350/250',
      VSpace: 0,
      Width: 1480,
      MediaId: '7068a1d3-120f-4c2b-a63d-ff4b3bb77bdb',
      Title: '',
      Language: {
        Name: 'en'
      }
    },
    Tablet: {
      Alt: '',
      Border: '',
      Class: '',
      Height: 650,
      HSpace: 0,
      Src: 'https://via.placeholder.com/350/250',
      VSpace: 0,
      Width: 1480,
      MediaId: '7068a1d3-120f-4c2b-a63d-ff4b3bb77bdb',
      Title: '',
      Language: {
        Name: 'en'
      }
    },
    Mobile: {
      Alt: '',
      Border: '',
      Class: '',
      Height: 650,
      HSpace: 0,
      Src: 'https://via.placeholder.com/350/250',
      VSpace: 0,
      Width: 1480,
      MediaId: '7068a1d3-120f-4c2b-a63d-ff4b3bb77bdb',
      Title: '',
      Language: {
        Name: 'en'
      }
    }
  },
  PageTitle: 'string',
  GoogleAnalytics: '<script>',
  HiConversion: '<script>',
  LuckyOrangeAnalytics: '<script>',
  ForeSee: '<script>',

};

export const StoreDataMock: IStoreSearchResult[] =
  [
    {
      Name: 'AutoNation Nissan Miami',
      AddressLine1: '3345 SW 8th Street',
      AddressLine2: '',
      City: 'Miami',
      StateCode: 'FL',
      PostalCode: '33135',
      Phone: '(305) 707-0145',
      Latitude: '25.764849',
      Longitude: '-80.250775',
      Distance: 2.7398269831543316,
      AverageRating: 0,
      ReviewCount: 0,
      Department: [
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Sales',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Friday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '11:00 AM'
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        },
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Service',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Friday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '5:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '',
              Reoccurence: 'Weekly',
              StartTime: ''
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        },
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Collision',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Friday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '12:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '8:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '',
              Reoccurence: 'Weekly',
              StartTime: ''
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        }
      ],
      Makes: 'Nissan',
      StoreId: '2890',
      StoreDetailedHours: [
        {
          Day: 0,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 1,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 2,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 3,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 4,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 5,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 6,
          StartTime: '11:00 AM',
          EndTime: '7:00 PM',
          Reoccurence: 0
        }
      ],
      StoreDetailsUrl: 'nissan-miami-fl',
      StoreCollisionUrl: '/dealers/nissan-miami-fl/collision-center',
      ReviewUrl: '/dealers/nissan-miami-fl',
      PromoUrl: 'nissan-miami-fl',
      ShowViewStoreDetails: true,
      Rating: 0,
      FleetSalesURL: 'javascript:void(0);',
      onlyCollisionDepartment: false,
      HasInventory: true,
      IsSalesAppointmentEnabled: true
    },
    {
      Name: 'AutoNation Chevrolet Coral Gables',
      AddressLine1: '4181 SW 8th Street',
      AddressLine2: '',
      City: 'Miami',
      StateCode: 'FL',
      PostalCode: '33134',
      Phone: '(305) 707-0139',
      Latitude: '25.764930',
      Longitude: '-80.262283',
      Distance: 3.454198743012624,
      AverageRating: 0,
      ReviewCount: 0,
      Department: [
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Sales',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Friday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '11:00 AM'
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        },
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Service',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Friday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '5:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '',
              Reoccurence: 'Weekly',
              StartTime: ''
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        },
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Collision',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Friday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '12:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '8:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '',
              Reoccurence: 'Weekly',
              StartTime: ''
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        }
      ],
      Makes: 'Chevrolet',
      StoreId: '2461',
      StoreDetailedHours: [
        {
          Day: 0,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 1,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 2,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 3,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 4,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 5,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 6,
          StartTime: '11:00 AM',
          EndTime: '7:00 PM',
          Reoccurence: 0
        }
      ],
      StoreDetailsUrl: 'chevrolet-coral-gables',
      StoreCollisionUrl: '/dealers/chevrolet-coral-gables/collision-center',
      ReviewUrl: '/dealers/chevrolet-coral-gables',
      PromoUrl: 'chevrolet-coral-gables',
      ShowViewStoreDetails: true,
      Rating: 0,
      FleetSalesURL: 'javascript:void(0);',
      onlyCollisionDepartment: false,
      HasInventory: false,
      IsSalesAppointmentEnabled: false
    },
    {
      Name: 'AutoNation Collision Center Airport Miami',
      AddressLine1: '3677 NW 24th St',
      AddressLine2: '',
      City: 'Miami',
      StateCode: 'FL',
      PostalCode: '33142',
      Phone: '(786) 924-0582',
      Latitude: '25.798620',
      Longitude: '-80.255661',
      Distance: 3.694952963810734,
      AverageRating: 0,
      ReviewCount: 0,
      Department: [
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Collision',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Friday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '12:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '8:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '',
              Reoccurence: 'Weekly',
              StartTime: ''
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        }
      ],
      Makes: '',
      StoreId: '2580',
      StoreDetailedHours: [
        {
          Day: 0,
          StartTime: '7:30 AM',
          EndTime: '6:00 PM',
          Reoccurence: 0
        },
        {
          Day: 1,
          StartTime: '7:30 AM',
          EndTime: '6:00 PM',
          Reoccurence: 0
        },
        {
          Day: 2,
          StartTime: '7:30 AM',
          EndTime: '6:00 PM',
          Reoccurence: 0
        },
        {
          Day: 3,
          StartTime: '7:30 AM',
          EndTime: '6:00 PM',
          Reoccurence: 0
        },
        {
          Day: 4,
          StartTime: '7:30 AM',
          EndTime: '6:00 PM',
          Reoccurence: 0
        },
        {
          Day: 5,
          StartTime: '8:00 AM',
          EndTime: '12:00 PM',
          Reoccurence: 0
        },
        {
          Day: 6,
          StartTime: '',
          EndTime: '',
          Reoccurence: 0
        }
      ],
      StoreDetailsUrl: 'collision-center-airport-miami',
      StoreCollisionUrl: '/dealers/collision-center-airport-miami/collision-center',
      ReviewUrl: '/dealers/collision-center-airport-miami',
      PromoUrl: 'collision-center-airport-miami',
      ShowViewStoreDetails: true,
      Rating: 0,
      FleetSalesURL: 'javascript:void(0);',
      onlyCollisionDepartment: false,
      HasInventory: false,
      IsSalesAppointmentEnabled: true
    },
    {
      Name: 'AutoNation Chevrolet Doral',
      AddressLine1: '1201 NW 89th Court',
      AddressLine2: '',
      City: 'Miami',
      StateCode: 'FL',
      PostalCode: '33172',
      Phone: '(786) 416-6002',
      Latitude: '25.783739',
      Longitude: '-80.339424',
      Distance: 8.320898988098179,
      AverageRating: 0,
      ReviewCount: 0,
      Department: [
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Sales',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Friday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '11:00 AM'
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        },
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Service',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Friday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '5:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '',
              Reoccurence: 'Weekly',
              StartTime: ''
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        },
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Collision',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Friday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '12:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '8:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '',
              Reoccurence: 'Weekly',
              StartTime: ''
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        }
      ],
      Makes: ['Chevrolet', 'Dodge'],
      StoreId: '2334',
      StoreDetailedHours: [
        {
          Day: 0,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 1,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 2,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 3,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 4,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 5,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 6,
          StartTime: '11:00 AM',
          EndTime: '7:00 PM',
          Reoccurence: 0
        }
      ],
      StoreDetailsUrl: 'chevrolet-doral',
      StoreCollisionUrl: '/dealers/chevrolet-doral/collision-center',
      ReviewUrl: '/dealers/chevrolet-doral',
      PromoUrl: 'chevrolet-doral',
      ShowViewStoreDetails: true,
      Rating: 0,
      FleetSalesURL: 'javascript:void(0);',
      onlyCollisionDepartment: false,
      HasInventory: false,
      IsSalesAppointmentEnabled: true
    },
    {
      Name: 'Mercedes-Benz of Miami',
      AddressLine1: '1200 NW 167th Street',
      AddressLine2: '',
      City: 'Miami',
      StateCode: 'FL',
      PostalCode: '33169',
      Phone: '(305) 707-0147',
      Latitude: '25.926346',
      Longitude: '-80.219666',
      Distance: 10.969815178048158,
      AverageRating: 0,
      ReviewCount: 0,
      Department: [
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Sales',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Friday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '5:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '12:00 PM'
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        },
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Service',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Friday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '5:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '',
              Reoccurence: 'Weekly',
              StartTime: ''
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        },
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Collision',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Friday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '12:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '8:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '',
              Reoccurence: 'Weekly',
              StartTime: ''
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        }
      ],
      Makes: 'Mercedes-Benz',
      StoreId: '2370',
      StoreDetailedHours: [
        {
          Day: 0,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 1,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 2,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 3,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 4,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 5,
          StartTime: '9:00 AM',
          EndTime: '7:00 PM',
          Reoccurence: 0
        },
        {
          Day: 6,
          StartTime: '12:00 PM',
          EndTime: '5:00 PM',
          Reoccurence: 0
        }
      ],
      StoreDetailsUrl: 'mercedes-benz-miami-fl',
      StoreCollisionUrl: '/dealers/mercedes-benz-miami-fl/collision-center',
      ReviewUrl: '/dealers/mercedes-benz-miami-fl',
      PromoUrl: 'mercedes-benz-miami-fl',
      ShowViewStoreDetails: true,
      Rating: 0,
      FleetSalesURL: 'javascript:void(0);',
      onlyCollisionDepartment: false,
      HasInventory: true,
      IsSalesAppointmentEnabled: true
    },
    {
      Name: 'AutoNation Ford Miami',
      AddressLine1: '16800 NW 57th Avenue',
      AddressLine2: '',
      City: 'Miami Lakes',
      StateCode: 'FL',
      PostalCode: '33015',
      Phone: '(305) 707-0141',
      Latitude: '25.926660',
      Longitude: '-80.294530',
      Distance: 12.243560176518507,
      AverageRating: 0,
      ReviewCount: 0,
      Department: [
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Sales',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Friday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '11:00 AM'
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        },
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Service',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Friday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '5:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '',
              Reoccurence: 'Weekly',
              StartTime: ''
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        },
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Collision',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Friday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '12:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '8:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '',
              Reoccurence: 'Weekly',
              StartTime: ''
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        }
      ],
      Makes: 'Ford',
      StoreId: '2335',
      StoreDetailedHours: [
        {
          Day: 0,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 1,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 2,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 3,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 4,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 5,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 6,
          StartTime: '11:00 AM',
          EndTime: '7:00 PM',
          Reoccurence: 0
        }
      ],
      StoreDetailsUrl: 'ford-miami-fl',
      StoreCollisionUrl: '/dealers/ford-miami-fl/collision-center',
      ReviewUrl: '/dealers/ford-miami-fl',
      PromoUrl: 'ford-miami-fl',
      ShowViewStoreDetails: true,
      Rating: 0,
      FleetSalesURL: 'javascript:void(0);',
      onlyCollisionDepartment: false,
      HasInventory: true,
      IsSalesAppointmentEnabled: true
    },
    {
      Name: 'AutoNation Honda Miami Lakes',
      AddressLine1: '5925 NW 167th Street',
      AddressLine2: '',
      City: 'Miami Lakes',
      StateCode: 'FL',
      PostalCode: '33015',
      Phone: '(305) 707-0143',
      Latitude: '25.926439',
      Longitude: '-80.298149',
      Distance: 12.331926802289647,
      AverageRating: 0,
      ReviewCount: 0,
      Department: [
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Sales',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Friday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '11:00 AM'
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        },
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Service',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Friday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '5:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '',
              Reoccurence: 'Weekly',
              StartTime: ''
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        }
      ],
      Makes: 'Honda',
      StoreId: '2330',
      StoreDetailedHours: [
        {
          Day: 0,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 1,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 2,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 3,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 4,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 5,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 6,
          StartTime: '11:00 AM',
          EndTime: '7:00 PM',
          Reoccurence: 0
        }
      ],
      StoreDetailsUrl: 'honda-miami-lakes',
      StoreCollisionUrl: 'javascript:void(0);',
      ReviewUrl: '/dealers/honda-miami-lakes',
      PromoUrl: 'honda-miami-lakes',
      ShowViewStoreDetails: true,
      Rating: 0,
      FleetSalesURL: 'javascript:void(0);',
      onlyCollisionDepartment: false,
      HasInventory: true,
      IsSalesAppointmentEnabled: true
    },
    {
      Name: 'AutoNation Chevrolet Pembroke Pines',
      AddressLine1: '8600 Pines Boulevard',
      AddressLine2: '',
      City: 'Pembroke Pines',
      StateCode: 'FL',
      PostalCode: '33024',
      Phone: '(954) 644-5038',
      Latitude: '26.007732',
      Longitude: '-80.259537',
      Distance: 16.88935533458605,
      AverageRating: 0,
      ReviewCount: 0,
      Department: [
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Sales',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Friday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '11:00 AM'
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        },
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Service',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Friday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '5:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '',
              Reoccurence: 'Weekly',
              StartTime: ''
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        },
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Collision',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Friday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '12:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '8:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '',
              Reoccurence: 'Weekly',
              StartTime: ''
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        }
      ],
      Makes: 'Chevrolet',
      StoreId: '2269',
      StoreDetailedHours: [
        {
          Day: 0,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 1,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 2,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 3,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 4,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 5,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 6,
          StartTime: '11:00 AM',
          EndTime: '7:00 PM',
          Reoccurence: 0
        }
      ],
      StoreDetailsUrl: 'chevrolet-pembroke-pines-fl',
      StoreCollisionUrl: '/dealers/chevrolet-pembroke-pines-fl/collision-center',
      ReviewUrl: '/dealers/chevrolet-pembroke-pines-fl',
      PromoUrl: 'chevrolet-pembroke-pines-fl',
      ShowViewStoreDetails: true,
      Rating: 0,
      FleetSalesURL: 'javascript:void(0);',
      onlyCollisionDepartment: false,
      HasInventory: true,
      IsSalesAppointmentEnabled: true
    },
    {
      Name: 'AutoNation Collision Center Pembroke Pines',
      AddressLine1: '8600 Pines Boulevard',
      AddressLine2: '',
      City: 'Pembroke Pines',
      StateCode: 'FL',
      PostalCode: '33024',
      Phone: '(954) 644-5030',
      Latitude: '26.007732',
      Longitude: '-80.259537',
      Distance: 16.88935533458605,
      AverageRating: 0,
      ReviewCount: 0,
      Department: [
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Collision',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Friday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '12:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '8:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '12:00 AM',
              Reoccurence: 'Weekly',
              StartTime: '12:00 AM'
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        }
      ],
      Makes: '',
      StoreId: '2574',
      StoreDetailedHours: [
        {
          Day: 0,
          StartTime: '7:30 AM',
          EndTime: '6:00 PM',
          Reoccurence: 0
        },
        {
          Day: 1,
          StartTime: '7:30 AM',
          EndTime: '6:00 PM',
          Reoccurence: 0
        },
        {
          Day: 2,
          StartTime: '7:30 AM',
          EndTime: '6:00 PM',
          Reoccurence: 0
        },
        {
          Day: 3,
          StartTime: '7:30 AM',
          EndTime: '6:00 PM',
          Reoccurence: 0
        },
        {
          Day: 4,
          StartTime: '7:30 AM',
          EndTime: '6:00 PM',
          Reoccurence: 0
        },
        {
          Day: 5,
          StartTime: '8:00 AM',
          EndTime: '12:00 PM',
          Reoccurence: 0
        },
        {
          Day: 6,
          StartTime: '12:00 AM',
          EndTime: '12:00 AM',
          Reoccurence: 0
        }
      ],
      StoreDetailsUrl: 'collision-center-pembroke-pines-fl',
      StoreCollisionUrl: '/dealers/collision-center-pembroke-pines-fl/collision-center',
      ReviewUrl: '/dealers/collision-center-pembroke-pines-fl',
      PromoUrl: 'collision-center-pembroke-pines-fl',
      ShowViewStoreDetails: true,
      Rating: 0,
      FleetSalesURL: 'javascript:void(0);',
      onlyCollisionDepartment: false,
      HasInventory: true,
      IsSalesAppointmentEnabled: true
    },
    {
      Name: 'AutoNation Nissan Pembroke Pines',
      AddressLine1: '8890 Pines Boulevard',
      AddressLine2: '',
      City: 'Pembroke Pines',
      StateCode: 'FL',
      PostalCode: '33024',
      Phone: '(954) 949-0421',
      Latitude: '26.007635',
      Longitude: '-80.262802',
      Distance: 16.923324414032926,
      AverageRating: 0,
      ReviewCount: 0,
      Department: [
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Sales',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Friday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '11:00 AM'
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        },
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Service',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Friday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '5:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '',
              Reoccurence: 'Weekly',
              StartTime: ''
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        }
      ],
      Makes: 'Nissan',
      StoreId: '2416',
      StoreDetailedHours: [
        {
          Day: 0,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 1,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 2,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 3,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 4,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 5,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 6,
          StartTime: '11:00 AM',
          EndTime: '7:00 PM',
          Reoccurence: 0
        }
      ],
      StoreDetailsUrl: 'nissan-pembroke-pines-fl',
      StoreCollisionUrl: 'javascript:void(0);',
      ReviewUrl: '/dealers/nissan-pembroke-pines-fl',
      PromoUrl: 'nissan-pembroke-pines-fl',
      ShowViewStoreDetails: true,
      Rating: 0,
      FleetSalesURL: 'javascript:void(0);',
      onlyCollisionDepartment: false,
      HasInventory: true,
      IsSalesAppointmentEnabled: true
    },
    {
      Name: 'AutoNation Honda Hollywood',
      AddressLine1: '2400 North State Road 7',
      AddressLine2: '',
      City: 'Hollywood',
      StateCode: 'FL',
      PostalCode: '33021',
      Phone: '(954) 644-5033',
      Latitude: '26.030668',
      Longitude: '-80.209457',
      Distance: 18.15600728804795,
      AverageRating: 0,
      ReviewCount: 0,
      Department: [
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Sales',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Friday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '11:00 AM'
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        },
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Service',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Friday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '5:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '',
              Reoccurence: 'Weekly',
              StartTime: ''
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        },
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Collision',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Friday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '2:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '8:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '',
              Reoccurence: 'Weekly',
              StartTime: ''
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        }
      ],
      Makes: 'Honda',
      StoreId: '2045',
      StoreDetailedHours: [
        {
          Day: 0,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 1,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 2,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 3,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 4,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 5,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 6,
          StartTime: '11:00 AM',
          EndTime: '7:00 PM',
          Reoccurence: 0
        }
      ],
      StoreDetailsUrl: 'honda-hollywood-fl',
      StoreCollisionUrl: '/dealers/honda-hollywood-fl/collision-center',
      ReviewUrl: '/dealers/honda-hollywood-fl',
      PromoUrl: 'honda-hollywood-fl',
      ShowViewStoreDetails: true,
      Rating: 0,
      FleetSalesURL: 'javascript:void(0);',
      onlyCollisionDepartment: false,
      HasInventory: true,
      IsSalesAppointmentEnabled: true
    },
    {
      Name: 'AutoNation Collision Center Hollywood',
      AddressLine1: '2400 N State Road 7',
      AddressLine2: '',
      City: 'Hollywood',
      StateCode: 'FL',
      PostalCode: '33021',
      Phone: '(954) 949-0707',
      Latitude: '26.030668',
      Longitude: '-80.209457',
      Distance: 18.15600728804795,
      AverageRating: 0,
      ReviewCount: 0,
      Department: [
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Collision',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Friday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '12:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '8:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '',
              Reoccurence: 'Weekly',
              StartTime: ''
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        }
      ],
      Makes: '',
      StoreId: '2629',
      StoreDetailedHours: [
        {
          Day: 0,
          StartTime: '7:30 AM',
          EndTime: '6:00 PM',
          Reoccurence: 0
        },
        {
          Day: 1,
          StartTime: '7:30 AM',
          EndTime: '6:00 PM',
          Reoccurence: 0
        },
        {
          Day: 2,
          StartTime: '7:30 AM',
          EndTime: '6:00 PM',
          Reoccurence: 0
        },
        {
          Day: 3,
          StartTime: '7:30 AM',
          EndTime: '6:00 PM',
          Reoccurence: 0
        },
        {
          Day: 4,
          StartTime: '7:30 AM',
          EndTime: '6:00 PM',
          Reoccurence: 0
        },
        {
          Day: 5,
          StartTime: '8:00 AM',
          EndTime: '12:00 PM',
          Reoccurence: 0
        },
        {
          Day: 6,
          StartTime: '',
          EndTime: '',
          Reoccurence: 0
        }
      ],
      StoreDetailsUrl: 'collision-center-hollywood-fl',
      StoreCollisionUrl: '/dealers/collision-center-hollywood-fl/collision-center',
      ReviewUrl: '/dealers/collision-center-hollywood-fl',
      PromoUrl: 'collision-center-hollywood-fl',
      ShowViewStoreDetails: true,
      Rating: 0,
      FleetSalesURL: 'javascript:void(0);',
      onlyCollisionDepartment: false,
      HasInventory: true,
      IsSalesAppointmentEnabled: true
    },
    {
      Name: 'AutoNation Chrysler Dodge Jeep Ram Pembroke Pines',
      AddressLine1: '13601 Pines Boulevard',
      AddressLine2: '',
      City: 'Pembroke Pines',
      StateCode: 'FL',
      PostalCode: '33027',
      Phone: '(954) 446-8775',
      Latitude: '26.008836',
      Longitude: '-80.329902',
      Distance: 18.319471113635824,
      AverageRating: 0,
      ReviewCount: 0,
      Department: [
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Sales',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Friday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '11:00 AM'
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        },
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Service',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Friday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '5:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '',
              Reoccurence: 'Weekly',
              StartTime: ''
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        }
      ],
      Makes: 'Chrysler, Dodge, Jeep, Ram',
      StoreId: '2940',
      StoreDetailedHours: [
        {
          Day: 0,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 1,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 2,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 3,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 4,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 5,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 6,
          StartTime: '11:00 AM',
          EndTime: '7:00 PM',
          Reoccurence: 0
        }
      ],
      StoreDetailsUrl: 'chrysler-dodge-jeep-ram-pembroke-pines-fl',
      StoreCollisionUrl: 'javascript:void(0);',
      ReviewUrl: '/dealers/chrysler-dodge-jeep-ram-pembroke-pines-fl',
      PromoUrl: 'chrysler-dodge-jeep-ram-pembroke-pines-fl',
      ShowViewStoreDetails: true,
      Rating: 0,
      FleetSalesURL: 'javascript:void(0);',
      onlyCollisionDepartment: false,
      HasInventory: true,
      IsSalesAppointmentEnabled: true
    },
    {
      Name: 'Mercedes-Benz of Pembroke Pines',
      AddressLine1: '14199 Pines Boulevard',
      AddressLine2: '',
      City: 'Pembroke Pines',
      StateCode: 'FL',
      PostalCode: '33027',
      Phone: '(954) 644-5035',
      Latitude: '26.008834',
      Longitude: '-80.332023',
      Distance: 18.37484190630416,
      AverageRating: 0,
      ReviewCount: 0,
      Department: [
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Sales',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Friday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '5:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '12:00 PM'
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        },
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Service',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Friday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '5:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '',
              Reoccurence: 'Weekly',
              StartTime: ''
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        },
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Collision',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Friday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '12:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '8:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '',
              Reoccurence: 'Weekly',
              StartTime: ''
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        }
      ],
      Makes: 'Mercedes-Benz',
      StoreId: '2590',
      StoreDetailedHours: [
        {
          Day: 0,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 1,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 2,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 3,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 4,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 5,
          StartTime: '9:00 AM',
          EndTime: '7:00 PM',
          Reoccurence: 0
        },
        {
          Day: 6,
          StartTime: '12:00 PM',
          EndTime: '5:00 PM',
          Reoccurence: 0
        }
      ],
      StoreDetailsUrl: 'mercedes-benz-pembroke-pines-fl',
      StoreCollisionUrl: '/dealers/mercedes-benz-pembroke-pines-fl/collision-center',
      ReviewUrl: '/dealers/mercedes-benz-pembroke-pines-fl',
      PromoUrl: 'mercedes-benz-pembroke-pines-fl',
      ShowViewStoreDetails: true,
      Rating: 0,
      FleetSalesURL: 'javascript:void(0);',
      onlyCollisionDepartment: false,
      HasInventory: true,
      IsSalesAppointmentEnabled: true
    },
    {
      Name: 'Mercedes-Benz of Fort Lauderdale',
      AddressLine1: '2411 South Federal Highway',
      AddressLine2: '',
      City: 'Ft. Lauderdale',
      StateCode: 'FL',
      PostalCode: '33316',
      Phone: '(954) 949-0623',
      Latitude: '26.091740',
      Longitude: '-80.137650',
      Distance: 22.789660348235458,
      AverageRating: 0,
      ReviewCount: 0,
      Department: [
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Sales',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Friday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '5:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '12:00 PM'
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        },
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Service',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Friday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '5:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '',
              Reoccurence: 'Weekly',
              StartTime: ''
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        },
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Collision',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Friday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '12:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '8:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '',
              Reoccurence: 'Weekly',
              StartTime: ''
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        }
      ],
      Makes: 'Mercedes-Benz',
      StoreId: '2165',
      StoreDetailedHours: [
        {
          Day: 0,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 1,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 2,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 3,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 4,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 5,
          StartTime: '9:00 AM',
          EndTime: '7:00 PM',
          Reoccurence: 0
        },
        {
          Day: 6,
          StartTime: '12:00 PM',
          EndTime: '5:00 PM',
          Reoccurence: 0
        }
      ],
      StoreDetailsUrl: 'mercedes-benz-ft-lauderdale-fl',
      StoreCollisionUrl: '/dealers/mercedes-benz-ft-lauderdale-fl/collision-center',
      ReviewUrl: '/dealers/mercedes-benz-ft-lauderdale-fl',
      PromoUrl: 'mercedes-benz-ft-lauderdale-fl',
      ShowViewStoreDetails: true,
      Rating: 0,
      FleetSalesURL: 'javascript:void(0);',
      onlyCollisionDepartment: false,
      HasInventory: true,
      IsSalesAppointmentEnabled: true
    },
    {
      Name: 'AutoNation Collision Center Fort Lauderdale South',
      AddressLine1: '122 SW 22nd St.',
      AddressLine2: '',
      City: 'Ft. Lauderdale',
      StateCode: 'FL',
      PostalCode: '33315',
      Phone: '(954) 734-7318',
      Latitude: '26.094360',
      Longitude: '-80.141624',
      Distance: 22.922511455080233,
      AverageRating: 0,
      ReviewCount: 0,
      Department: [
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Collision',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Friday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '12:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '8:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '',
              Reoccurence: 'Weekly',
              StartTime: ''
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        }
      ],
      Makes: 'Mercedes, Nissan',
      StoreId: '2941',
      StoreDetailedHours: [
        {
          Day: 0,
          StartTime: '7:30 AM',
          EndTime: '6:00 PM',
          Reoccurence: 0
        },
        {
          Day: 1,
          StartTime: '7:30 AM',
          EndTime: '6:00 PM',
          Reoccurence: 0
        },
        {
          Day: 2,
          StartTime: '7:30 AM',
          EndTime: '6:00 PM',
          Reoccurence: 0
        },
        {
          Day: 3,
          StartTime: '7:30 AM',
          EndTime: '6:00 PM',
          Reoccurence: 0
        },
        {
          Day: 4,
          StartTime: '7:30 AM',
          EndTime: '6:00 PM',
          Reoccurence: 0
        },
        {
          Day: 5,
          StartTime: '8:00 AM',
          EndTime: '12:00 PM',
          Reoccurence: 0
        },
        {
          Day: 6,
          StartTime: '',
          EndTime: '',
          Reoccurence: 0
        }
      ],
      StoreDetailsUrl: 'collision-center-ft-lauderdale-south',
      StoreCollisionUrl: '/dealers/collision-center-ft-lauderdale-south/collision-center',
      ReviewUrl: '/dealers/collision-center-ft-lauderdale-south',
      PromoUrl: 'collision-center-ft-lauderdale-south',
      ShowViewStoreDetails: true,
      Rating: 0,
      FleetSalesURL: 'javascript:void(0);',
      onlyCollisionDepartment: false,
      HasInventory: true,
      IsSalesAppointmentEnabled: true
    },
    {
      Name: 'AutoNation Toyota Weston',
      AddressLine1: '4050 Weston Road',
      AddressLine2: '',
      City: 'Davie',
      StateCode: 'FL',
      PostalCode: '33331',
      Phone: '(954) 644-4867',
      Latitude: '26.071443',
      Longitude: '-80.360694',
      Distance: 23.052376408783758,
      AverageRating: 0,
      ReviewCount: 0,
      Department: [
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Sales',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Friday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '11:00 AM'
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        },
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Service',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Friday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '',
              Reoccurence: 'Weekly',
              StartTime: ''
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        },
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Collision',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Friday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '12:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '8:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '',
              Reoccurence: 'Weekly',
              StartTime: ''
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        }
      ],
      Makes: 'Scion, Toyota',
      StoreId: '2930',
      StoreDetailedHours: [
        {
          Day: 0,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 1,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 2,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 3,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 4,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 5,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 6,
          StartTime: '11:00 AM',
          EndTime: '7:00 PM',
          Reoccurence: 0
        }
      ],
      StoreDetailsUrl: 'toyota-weston-fl',
      StoreCollisionUrl: '/dealers/toyota-weston-fl/collision-center',
      ReviewUrl: '/dealers/toyota-weston-fl',
      PromoUrl: 'toyota-weston-fl',
      ShowViewStoreDetails: true,
      Rating: 0,
      FleetSalesURL: 'javascript:void(0);',
      onlyCollisionDepartment: false,
      HasInventory: true,
      IsSalesAppointmentEnabled: true
    },
    {
      Name: 'Jaguar Fort Lauderdale',
      AddressLine1: '6606 N. Andrews Avenue',
      AddressLine2: '',
      City: 'Fort Lauderdale',
      StateCode: 'FL',
      PostalCode: '33309',
      Phone: '(954) 406-1917',
      Latitude: '26.207910',
      Longitude: '-80.144150',
      Distance: 30.661508913591046,
      AverageRating: 0,
      ReviewCount: 0,
      Department: [
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Sales',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Friday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '5:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '12:00 PM'
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        },
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Service',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Friday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '5:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '',
              Reoccurence: 'Weekly',
              StartTime: ''
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        }
      ],
      Makes: 'Jaguar',
      StoreId: '2326',
      StoreDetailedHours: [
        {
          Day: 0,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 1,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 2,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 3,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 4,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 5,
          StartTime: '9:00 AM',
          EndTime: '7:00 PM',
          Reoccurence: 0
        },
        {
          Day: 6,
          StartTime: '12:00 PM',
          EndTime: '5:00 PM',
          Reoccurence: 0
        }
      ],
      StoreDetailsUrl: 'jaguar-fort-lauderdale-fl',
      StoreCollisionUrl: 'javascript:void(0);',
      ReviewUrl: '/dealers/jaguar-fort-lauderdale-fl',
      PromoUrl: 'jaguar-fort-lauderdale-fl',
      ShowViewStoreDetails: true,
      Rating: 0,
      FleetSalesURL: 'javascript:void(0);',
      onlyCollisionDepartment: false,
      HasInventory: true,
      IsSalesAppointmentEnabled: true
    },
    {
      Name: 'AutoNation Ford Margate',
      AddressLine1: '5401 W Copans Road',
      AddressLine2: '',
      City: 'Margate',
      StateCode: 'FL',
      PostalCode: '33063',
      Phone: '(954) 644-4976',
      Latitude: '26.255270',
      Longitude: '-80.199020',
      Distance: 33.69081206638785,
      AverageRating: 0,
      ReviewCount: 0,
      Department: [
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Sales',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Friday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '5:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '12:00 PM'
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        },
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Service',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Friday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '5:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '',
              Reoccurence: 'Weekly',
              StartTime: ''
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        }
      ],
      Makes: 'Ford',
      StoreId: '2130',
      StoreDetailedHours: [
        {
          Day: 0,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 1,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 2,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 3,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 4,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 5,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 6,
          StartTime: '12:00 PM',
          EndTime: '5:00 PM',
          Reoccurence: 0
        }
      ],
      StoreDetailsUrl: 'ford-margate-fl',
      StoreCollisionUrl: 'javascript:void(0);',
      ReviewUrl: '/dealers/ford-margate-fl',
      PromoUrl: 'ford-margate-fl',
      ShowViewStoreDetails: true,
      Rating: 0,
      FleetSalesURL: 'javascript:void(0);',
      onlyCollisionDepartment: false,
      HasInventory: true,
      IsSalesAppointmentEnabled: true
    },
    {
      Name: 'AutoNation Collision Center Margate',
      AddressLine1: '5355 NW 24th St.',
      AddressLine2: '',
      City: 'Margate',
      StateCode: 'FL',
      PostalCode: '33063',
      Phone: '(954) 949-0696',
      Latitude: '26.257460',
      Longitude: '-80.196490',
      Distance: 33.84489061514756,
      AverageRating: 0,
      ReviewCount: 0,
      Department: [
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Collision',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Friday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '12:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '8:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '',
              Reoccurence: 'Weekly',
              StartTime: ''
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        }
      ],
      Makes: '',
      StoreId: '2577',
      StoreDetailedHours: [
        {
          Day: 0,
          StartTime: '7:30 AM',
          EndTime: '6:00 PM',
          Reoccurence: 0
        },
        {
          Day: 1,
          StartTime: '7:30 AM',
          EndTime: '6:00 PM',
          Reoccurence: 0
        },
        {
          Day: 2,
          StartTime: '7:30 AM',
          EndTime: '6:00 PM',
          Reoccurence: 0
        },
        {
          Day: 3,
          StartTime: '7:30 AM',
          EndTime: '6:00 PM',
          Reoccurence: 0
        },
        {
          Day: 4,
          StartTime: '7:30 AM',
          EndTime: '6:00 PM',
          Reoccurence: 0
        },
        {
          Day: 5,
          StartTime: '8:00 AM',
          EndTime: '12:00 PM',
          Reoccurence: 0
        },
        {
          Day: 6,
          StartTime: '',
          EndTime: '',
          Reoccurence: 0
        }
      ],
      StoreDetailsUrl: 'collision-center-margate-fl',
      StoreCollisionUrl: '/dealers/collision-center-margate-fl/collision-center',
      ReviewUrl: '/dealers/collision-center-margate-fl',
      PromoUrl: 'collision-center-margate-fl',
      ShowViewStoreDetails: true,
      Rating: 0,
      FleetSalesURL: 'javascript:void(0);',
      onlyCollisionDepartment: false,
      HasInventory: true,
      IsSalesAppointmentEnabled: true
    },
    {
      Name: 'Mercedes-Benz of Pompano',
      AddressLine1: '350 West Copans Road',
      AddressLine2: '',
      City: 'Pompano Beach',
      StateCode: 'FL',
      PostalCode: '33064',
      Phone: '(954) 644-4832',
      Latitude: '26.257664',
      Longitude: '-80.130882',
      Distance: 34.181109947706,
      AverageRating: 0,
      ReviewCount: 0,
      Department: [
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Sales',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Friday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '5:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '12:00 PM'
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        },
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Service',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Friday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '5:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '',
              Reoccurence: 'Weekly',
              StartTime: ''
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        },
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Collision',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Friday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '12:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '8:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '',
              Reoccurence: 'Weekly',
              StartTime: ''
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        }
      ],
      Makes: 'Mercedes-Benz',
      StoreId: '2406',
      StoreDetailedHours: [
        {
          Day: 0,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 1,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 2,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 3,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 4,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 5,
          StartTime: '9:00 AM',
          EndTime: '7:00 PM',
          Reoccurence: 0
        },
        {
          Day: 6,
          StartTime: '12:00 PM',
          EndTime: '5:00 PM',
          Reoccurence: 0
        }
      ],
      StoreDetailsUrl: 'mercedes-benz-pompano-beach-fl',
      StoreCollisionUrl: '/dealers/mercedes-benz-pompano-beach-fl/collision-center',
      ReviewUrl: '/dealers/mercedes-benz-pompano-beach-fl',
      PromoUrl: 'mercedes-benz-pompano-beach-fl',
      ShowViewStoreDetails: true,
      Rating: 0,
      FleetSalesURL: 'javascript:void(0);',
      onlyCollisionDepartment: false,
      HasInventory: true,
      IsSalesAppointmentEnabled: true
    },
    {
      Name: 'Land Rover Fort Lauderdale',
      AddressLine1: '400 W. Copans Road',
      AddressLine2: '',
      City: 'Pompano Beach',
      StateCode: 'FL',
      PostalCode: '33064',
      Phone: '(954) 949-0654',
      Latitude: '26.260020',
      Longitude: '-80.128060',
      Distance: 34.36704480112047,
      AverageRating: 0,
      ReviewCount: 0,
      Department: [
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Sales',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Friday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '5:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '12:00 PM'
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        },
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Service',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Friday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '5:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '',
              Reoccurence: 'Weekly',
              StartTime: ''
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        },
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Collision',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Friday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '12:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '8:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '',
              Reoccurence: 'Weekly',
              StartTime: ''
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        }
      ],
      Makes: 'LAND ROVER',
      StoreId: '2054',
      StoreDetailedHours: [
        {
          Day: 0,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 1,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 2,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 3,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 4,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 5,
          StartTime: '9:00 AM',
          EndTime: '7:00 PM',
          Reoccurence: 0
        },
        {
          Day: 6,
          StartTime: '12:00 PM',
          EndTime: '5:00 PM',
          Reoccurence: 0
        }
      ],
      StoreDetailsUrl: 'land-rover-ft-lauderdale-fl',
      StoreCollisionUrl: '/dealers/land-rover-ft-lauderdale-fl/collision-center',
      ReviewUrl: '/dealers/land-rover-ft-lauderdale-fl',
      PromoUrl: 'land-rover-ft-lauderdale-fl',
      ShowViewStoreDetails: true,
      Rating: 0,
      FleetSalesURL: 'javascript:void(0);',
      onlyCollisionDepartment: false,
      HasInventory: true,
      IsSalesAppointmentEnabled: true
    },
    {
      Name: 'Mercedes-Benz of Coconut Creek',
      AddressLine1: '4250 N. State Road 7',
      AddressLine2: '',
      City: 'Coconut Creek',
      StateCode: 'FL',
      PostalCode: '33073',
      Phone: '(954) 406-1167',
      Latitude: '26.281174',
      Longitude: '-80.200073',
      Distance: 35.48112233803193,
      AverageRating: 0,
      ReviewCount: 0,
      Department: [
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Sales',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Friday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '5:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '12:00 PM'
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        },
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Service',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Friday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '5:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '',
              Reoccurence: 'Weekly',
              StartTime: ''
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        },
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Collision',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Friday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '12:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '8:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '',
              Reoccurence: 'Weekly',
              StartTime: ''
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        }
      ],
      Makes: 'Mercedes-Benz',
      StoreId: '2123',
      StoreDetailedHours: [
        {
          Day: 0,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 1,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 2,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 3,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 4,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 5,
          StartTime: '9:00 AM',
          EndTime: '7:00 PM',
          Reoccurence: 0
        },
        {
          Day: 6,
          StartTime: '12:00 PM',
          EndTime: '5:00 PM',
          Reoccurence: 0
        }
      ],
      StoreDetailsUrl: 'mercedes-benz-coconut-creek-fl',
      StoreCollisionUrl: '/dealers/mercedes-benz-coconut-creek-fl/collision-center',
      ReviewUrl: '/dealers/mercedes-benz-coconut-creek-fl',
      PromoUrl: 'mercedes-benz-coconut-creek-fl',
      ShowViewStoreDetails: true,
      Rating: 0,
      FleetSalesURL: 'javascript:void(0);',
      onlyCollisionDepartment: false,
      HasInventory: true,
      IsSalesAppointmentEnabled: true
    },
    {
      Name: 'Mercedes-Benz of Delray',
      AddressLine1: '1001 Linton Boulevard',
      AddressLine2: '',
      City: 'Delray Beach',
      StateCode: 'FL',
      PostalCode: '33444',
      Phone: '(561) 291-6095',
      Latitude: '26.440098',
      Longitude: '-80.084610',
      Distance: 47.08537892911944,
      AverageRating: 0,
      ReviewCount: 0,
      Department: [
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Sales',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Friday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '5:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '12:00 PM'
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        },
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Service',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Friday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '5:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '',
              Reoccurence: 'Weekly',
              StartTime: ''
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        },
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Collision',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Friday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '12:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '8:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '',
              Reoccurence: 'Weekly',
              StartTime: ''
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        }
      ],
      Makes: 'Mercedes-Benz',
      StoreId: '2087',
      StoreDetailedHours: [
        {
          Day: 0,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 1,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 2,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 3,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 4,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 5,
          StartTime: '9:00 AM',
          EndTime: '7:00 PM',
          Reoccurence: 0
        },
        {
          Day: 6,
          StartTime: '12:00 PM',
          EndTime: '5:00 PM',
          Reoccurence: 0
        }
      ],
      StoreDetailsUrl: 'mercedes-benz-delray',
      StoreCollisionUrl: '/dealers/mercedes-benz-delray/collision-center',
      ReviewUrl: '/dealers/mercedes-benz-delray',
      PromoUrl: 'mercedes-benz-delray',
      ShowViewStoreDetails: true,
      Rating: 0,
      FleetSalesURL: 'javascript:void(0);',
      onlyCollisionDepartment: false,
      HasInventory: true,
      IsSalesAppointmentEnabled: true
    },
    {
      Name: 'BMW of Delray Beach',
      AddressLine1: '1311 Linton Boulevard',
      AddressLine2: '',
      City: 'Delray Beach',
      StateCode: 'FL',
      PostalCode: '33444',
      Phone: '(561) 491-2329',
      Latitude: '26.440900',
      Longitude: '-80.087090',
      Distance: 47.11553933821564,
      AverageRating: 0,
      ReviewCount: 0,
      Department: [
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Sales',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Friday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '5:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '12:00 PM'
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        },
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Service',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Friday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '5:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '',
              Reoccurence: 'Weekly',
              StartTime: ''
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        }
      ],
      Makes: 'BMW',
      StoreId: '2947',
      StoreDetailedHours: [
        {
          Day: 0,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 1,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 2,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 3,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 4,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 5,
          StartTime: '9:00 AM',
          EndTime: '7:00 PM',
          Reoccurence: 0
        },
        {
          Day: 6,
          StartTime: '12:00 PM',
          EndTime: '5:00 PM',
          Reoccurence: 0
        }
      ],
      StoreDetailsUrl: 'bmw-delray-beach',
      StoreCollisionUrl: '/dealers/bmw-delray-beach/collision-center',
      ReviewUrl: '/dealers/bmw-delray-beach',
      PromoUrl: 'bmw-delray-beach',
      ShowViewStoreDetails: true,
      Rating: 0,
      FleetSalesURL: 'javascript:void(0);',
      onlyCollisionDepartment: false,
      HasInventory: true,
      IsSalesAppointmentEnabled: true
    },
    {
      Name: 'AutoNation Collision Center Delray',
      AddressLine1: '1311 Linton Blvd.',
      AddressLine2: '',
      City: 'Delray Beach',
      StateCode: 'FL',
      PostalCode: '33444',
      Phone: '(561) 491-2347',
      Latitude: '26.440907',
      Longitude: '-80.087090',
      Distance: 47.116017297024804,
      AverageRating: 0,
      ReviewCount: 0,
      Department: [
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Collision',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Friday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '12:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '8:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '',
              Reoccurence: 'Weekly',
              StartTime: ''
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        }
      ],
      Makes: '',
      StoreId: '2439',
      StoreDetailedHours: [
        {
          Day: 0,
          StartTime: '7:30 AM',
          EndTime: '6:00 PM',
          Reoccurence: 0
        },
        {
          Day: 1,
          StartTime: '7:30 AM',
          EndTime: '6:00 PM',
          Reoccurence: 0
        },
        {
          Day: 2,
          StartTime: '7:30 AM',
          EndTime: '6:00 PM',
          Reoccurence: 0
        },
        {
          Day: 3,
          StartTime: '7:30 AM',
          EndTime: '6:00 PM',
          Reoccurence: 0
        },
        {
          Day: 4,
          StartTime: '7:30 AM',
          EndTime: '6:00 PM',
          Reoccurence: 0
        },
        {
          Day: 5,
          StartTime: '8:00 AM',
          EndTime: '12:00 PM',
          Reoccurence: 0
        },
        {
          Day: 6,
          StartTime: '',
          EndTime: '',
          Reoccurence: 0
        }
      ],
      StoreDetailsUrl: 'collision-center-delray-beach-fl',
      StoreCollisionUrl: '/dealers/collision-center-delray-beach-fl/collision-center',
      ReviewUrl: '/dealers/collision-center-delray-beach-fl',
      PromoUrl: 'collision-center-delray-beach-fl',
      ShowViewStoreDetails: true,
      Rating: 0,
      FleetSalesURL: 'javascript:void(0);',
      onlyCollisionDepartment: false,
      HasInventory: true,
      IsSalesAppointmentEnabled: true
    },
    {
      Name: 'AutoNation Collision Center Greenacres',
      AddressLine1: '5757 Lake Worth Road',
      AddressLine2: '',
      City: 'Greenacres',
      StateCode: 'FL',
      PostalCode: '33466',
      Phone: '(561) 491-2314',
      Latitude: '26.619949',
      Longitude: '-80.131973',
      Distance: 59.0892062430775,
      AverageRating: 0,
      ReviewCount: 0,
      Department: [
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Collision',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Friday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '12:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '8:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '',
              Reoccurence: 'Weekly',
              StartTime: ''
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        }
      ],
      Makes: '',
      StoreId: '2636',
      StoreDetailedHours: [
        {
          Day: 0,
          StartTime: '7:30 AM',
          EndTime: '6:00 PM',
          Reoccurence: 0
        },
        {
          Day: 1,
          StartTime: '7:30 AM',
          EndTime: '6:00 PM',
          Reoccurence: 0
        },
        {
          Day: 2,
          StartTime: '7:30 AM',
          EndTime: '6:00 PM',
          Reoccurence: 0
        },
        {
          Day: 3,
          StartTime: '7:30 AM',
          EndTime: '6:00 PM',
          Reoccurence: 0
        },
        {
          Day: 4,
          StartTime: '7:30 AM',
          EndTime: '6:00 PM',
          Reoccurence: 0
        },
        {
          Day: 5,
          StartTime: '8:00 AM',
          EndTime: '12:00 PM',
          Reoccurence: 0
        },
        {
          Day: 6,
          StartTime: '',
          EndTime: '',
          Reoccurence: 0
        }
      ],
      StoreDetailsUrl: 'collision-center-greenacres-fl',
      StoreCollisionUrl: '/dealers/collision-center-greenacres-fl/collision-center',
      ReviewUrl: '/dealers/collision-center-greenacres-fl',
      PromoUrl: 'collision-center-greenacres-fl',
      ShowViewStoreDetails: true,
      Rating: 0,
      FleetSalesURL: 'javascript:void(0);',
      onlyCollisionDepartment: false,
      HasInventory: true,
      IsSalesAppointmentEnabled: true
    },
    {
      Name: 'AutoNation Chevrolet Greenacres',
      AddressLine1: '5757 Lake Worth Road',
      AddressLine2: '',
      City: 'Greenacres',
      StateCode: 'FL',
      PostalCode: '33463',
      Phone: '(561) 291-6054',
      Latitude: '26.619950',
      Longitude: '-80.131970',
      Distance: 59.089289833100594,
      AverageRating: 0,
      ReviewCount: 0,
      Department: [
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Sales',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Friday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '5:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '12:00 PM'
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        },
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Service',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Friday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '5:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '',
              Reoccurence: 'Weekly',
              StartTime: ''
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        },
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Collision',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Friday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '1:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '8:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '',
              Reoccurence: 'Weekly',
              StartTime: ''
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        }
      ],
      Makes: 'Chevrolet',
      StoreId: '2140',
      StoreDetailedHours: [
        {
          Day: 0,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 1,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 2,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 3,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 4,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 5,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 6,
          StartTime: '12:00 PM',
          EndTime: '5:00 PM',
          Reoccurence: 0
        }
      ],
      StoreDetailsUrl: 'chevrolet-greenacres-fl',
      StoreCollisionUrl: '/dealers/chevrolet-greenacres-fl/collision-center',
      ReviewUrl: '/dealers/chevrolet-greenacres-fl',
      PromoUrl: 'chevrolet-greenacres-fl',
      ShowViewStoreDetails: true,
      Rating: 0,
      FleetSalesURL: 'javascript:void(0);',
      onlyCollisionDepartment: false,
      HasInventory: true,
      IsSalesAppointmentEnabled: true
    },
    {
      Name: 'Lexus of Palm Beach',
      AddressLine1: '5700 Okeechobee Boulevard',
      AddressLine2: '',
      City: 'West Palm Beach',
      StateCode: 'FL',
      PostalCode: '33417',
      Phone: '(561) 291-6004',
      Latitude: '26.705341',
      Longitude: '-80.130623',
      Distance: 64.9835788588154,
      AverageRating: 0,
      ReviewCount: 0,
      Department: [
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Sales',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Friday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '5:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '12:00 PM'
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        },
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Service',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Friday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '5:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '',
              Reoccurence: 'Weekly',
              StartTime: ''
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        },
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Collision',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Friday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '12:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '8:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '',
              Reoccurence: 'Weekly',
              StartTime: ''
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        }
      ],
      Makes: 'Lexus',
      StoreId: '2945',
      StoreDetailedHours: [
        {
          Day: 0,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 1,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 2,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 3,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 4,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 5,
          StartTime: '9:00 AM',
          EndTime: '7:00 PM',
          Reoccurence: 0
        },
        {
          Day: 6,
          StartTime: '12:00 PM',
          EndTime: '5:00 PM',
          Reoccurence: 0
        }
      ],
      StoreDetailsUrl: 'lexus-palm-beach-fl',
      StoreCollisionUrl: '/dealers/lexus-palm-beach-fl/collision-center',
      ReviewUrl: '/dealers/lexus-palm-beach-fl',
      PromoUrl: 'lexus-palm-beach-fl',
      ShowViewStoreDetails: true,
      Rating: 0,
      FleetSalesURL: 'javascript:void(0);',
      onlyCollisionDepartment: false,
      HasInventory: true,
      IsSalesAppointmentEnabled: true
    },
    {
      Name: 'AutoNation Collision Center West Palm Beach',
      AddressLine1: '1030 N. Congress Ave',
      AddressLine2: '',
      City: 'West Palm Beach',
      StateCode: 'FL',
      PostalCode: '33409',
      Phone: '(561) 287-6234',
      Latitude: '26.703842',
      Longitude: '-80.085892',
      Distance: 65.14141250692312,
      AverageRating: 0,
      ReviewCount: 0,
      Department: [
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Collision',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Friday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '12:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '8:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '',
              Reoccurence: 'Weekly',
              StartTime: ''
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        }
      ],
      Makes: '',
      StoreId: '2978',
      StoreDetailedHours: [
        {
          Day: 0,
          StartTime: '7:30 AM',
          EndTime: '6:00 PM',
          Reoccurence: 0
        },
        {
          Day: 1,
          StartTime: '7:30 AM',
          EndTime: '6:00 PM',
          Reoccurence: 0
        },
        {
          Day: 2,
          StartTime: '7:30 AM',
          EndTime: '6:00 PM',
          Reoccurence: 0
        },
        {
          Day: 3,
          StartTime: '7:30 AM',
          EndTime: '6:00 PM',
          Reoccurence: 0
        },
        {
          Day: 4,
          StartTime: '7:30 AM',
          EndTime: '6:00 PM',
          Reoccurence: 0
        },
        {
          Day: 5,
          StartTime: '8:00 AM',
          EndTime: '12:00 PM',
          Reoccurence: 0
        },
        {
          Day: 6,
          StartTime: '',
          EndTime: '',
          Reoccurence: 0
        }
      ],
      StoreDetailsUrl: 'collision-center-west-palm-beach',
      StoreCollisionUrl: '/dealers/collision-center-west-palm-beach/collision-center',
      ReviewUrl: '/dealers/collision-center-west-palm-beach',
      PromoUrl: 'collision-center-west-palm-beach',
      ShowViewStoreDetails: true,
      Rating: 0,
      FleetSalesURL: 'javascript:void(0);',
      onlyCollisionDepartment: false,
      HasInventory: true,
      IsSalesAppointmentEnabled: true
    },
    {
      Name: 'AutoNation Cadillac West Palm Beach',
      AddressLine1: '2101 45th Street',
      AddressLine2: '',
      City: 'West Palm Beach',
      StateCode: 'FL',
      PostalCode: '33407',
      Phone: '(561) 291-6025',
      Latitude: '26.759760',
      Longitude: '-80.085600',
      Distance: 68.9857531456589,
      AverageRating: 0,
      ReviewCount: 0,
      Department: [
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Sales',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Friday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '5:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '12:00 PM'
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        },
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Service',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Friday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '5:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '',
              Reoccurence: 'Weekly',
              StartTime: ''
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        },
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Collision',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Friday',
              EndTime: '6:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '7:30 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '12:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '8:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '',
              Reoccurence: 'Weekly',
              StartTime: ''
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        }
      ],
      Makes: 'Cadillac',
      StoreId: '2348',
      StoreDetailedHours: [
        {
          Day: 0,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 1,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 2,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 3,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 4,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 5,
          StartTime: '9:00 AM',
          EndTime: '7:00 PM',
          Reoccurence: 0
        },
        {
          Day: 6,
          StartTime: '12:00 PM',
          EndTime: '5:00 PM',
          Reoccurence: 0
        }
      ],
      StoreDetailsUrl: 'cadillac-west-palm-beach',
      StoreCollisionUrl: '/dealers/cadillac-west-palm-beach/collision-center',
      ReviewUrl: '/dealers/cadillac-west-palm-beach',
      PromoUrl: 'cadillac-west-palm-beach',
      ShowViewStoreDetails: true,
      Rating: 0,
      FleetSalesURL: 'javascript:void(0);',
      onlyCollisionDepartment: false,
      HasInventory: true,
      IsSalesAppointmentEnabled: true
    },
    {
      Name: 'AutoNation Nissan Miami -No Sales',
      AddressLine1: '3345 SW 8th Street',
      AddressLine2: '',
      City: 'Miami',
      StateCode: 'FL',
      PostalCode: '33135',
      Phone: '(305) 707-0145',
      Latitude: '25.764849',
      Longitude: '-80.250775',
      Distance: 2.7398269831543316,
      AverageRating: 0,
      ReviewCount: 0,
      Department: [
        {
          Brands: null,
          ContactPhone: null,
          DepartmentType: 'Sales',
          DetailedHours: [
            {
              Day: 'Monday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Tuesday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Wednesday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Thursday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Friday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Saturday',
              EndTime: '9:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '9:00 AM'
            },
            {
              Day: 'Sunday',
              EndTime: '7:00 PM',
              Reoccurence: 'Weekly',
              StartTime: '11:00 AM'
            }
          ],
          Hours: null,
          Name: null,
          collisionUrl: '',
          storeId: ''
        }
      ],
      Makes: 'Nissan',
      StoreId: '2890',
      StoreDetailedHours: [
        {
          Day: 0,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 1,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 2,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 3,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 4,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 5,
          StartTime: '9:00 AM',
          EndTime: '9:00 PM',
          Reoccurence: 0
        },
        {
          Day: 6,
          StartTime: '11:00 AM',
          EndTime: '7:00 PM',
          Reoccurence: 0
        }
      ],
      StoreDetailsUrl: 'nissan-miami-fl',
      StoreCollisionUrl: '/dealers/nissan-miami-fl/collision-center',
      ReviewUrl: '/dealers/nissan-miami-fl',
      PromoUrl: 'nissan-miami-fl',
      ShowViewStoreDetails: true,
      Rating: 0,
      FleetSalesURL: 'javascript:void(0);',
      onlyCollisionDepartment: false,
      HasInventory: true,
      IsSalesAppointmentEnabled: true
    },
  ];

export const StoreSearchResponseMock: IStoreSearchResponse = {
    Makes: [
      'Nissan',
      'Chevrolet',
      'Mercedes-Benz',
      'Ford',
      'Honda',
      'Chrysler',
      'Dodge',
      'Jeep',
      'Ram',
      'Mercedes',
      'Scion',
      'Toyota',
      'Jaguar',
      'LAND ROVER',
      'BMW',
      'Lexus',
      'Cadillac'
    ],
    Store: StoreDataMock,
    StoreTotalCount: 20,
    NationwideSearch: true
};

export const ZipCodeDataMock: IZipCodeData = {
  PostalCode: '77057',
  StateCode: 'TX',
  City: 'Houston',
  Latitude: 110.345,
  Longitude: 123.456,
  ValidPostalCode: true
};

export const ZipCodeResponseMock: IZipCodeResponse = {
  Success: true,
  ErrorCodes: [],
  ZipCodeData: ZipCodeDataMock
};

export const ZipCodeResponseFailMock: IZipCodeResponse = {
  Success: false,
  ErrorCodes: [{ Code: 'ERRCODE 06/17/2019 23:21:45', Message: 'Zipcode You Entered is not Valid' }],
  ZipCodeData: {
    PostalCode: null,
    StateCode: null,
    City: null,
    Latitude: 0.0,
    Longitude: 0.0,
    ValidPostalCode: false
  }
};

export const AutocompleteLocations = {
  resourceSets: [
    {
      estimatedTotal: 3,
      resources: [
        {
          __type: 'Location:http:\/\/schemas.microsoft.com\/search\/local\/ws\/rest\/v1',
          bbox: [
            30.962379455566406,
            -87.921371459960938,
            30.980169296264648,
            -87.887161254882813
          ],
          name: 'Coconut Grove, FL',
          point: {
            type: 'Point',
            coordinates: [
              30.971298217773437,
              -87.909408569335938
            ]
          },
          address: {
            adminDistrict: 'AL',
            adminDistrict2: 'Baldwin Co.',
            countryRegion: 'United States',
            formattedAddress: 'The T, AL',
            landmark: 'The T'
          },
          confidence: 'High',
          entityType: 'River',
          geocodePoints: [
            {
              type: 'Point',
              coordinates: [
                30.971298217773437,
                -87.909408569335938
              ],
              calculationMethod: 'Rooftop',
              usageTypes: [
                'Display'
              ]
            }
          ],
          matchCodes: [
            'Good'
          ]
        },
        {
          __type: 'Location:http:\/\/schemas.microsoft.com\/search\/local\/ws\/rest\/v1',
          bbox: [
            30.962379455566406,
            -87.921371459960938,
            30.980169296264648,
            -87.887161254882813
          ],
          name: 'Fort Lauderdale, FL',
          point: {
            type: 'Point',
            coordinates: [
              30.971298217773437,
              -87.909408569335938
            ]
          },
          address: {
            adminDistrict: 'AL',
            adminDistrict2: 'Baldwin Co.',
            countryRegion: 'United States',
            formattedAddress: 'The T, AL',
            landmark: 'The T'
          },
          confidence: 'High',
          entityType: 'River',
          geocodePoints: [
            {
              type: 'Point',
              coordinates: [
                30.971298217773437,
                -87.909408569335938
              ],
              calculationMethod: 'Rooftop',
              usageTypes: [
                'Display'
              ]
            }
          ],
          matchCodes: [
            'Good'
          ]
        },
        {
          __type: 'Location:http:\/\/schemas.microsoft.com\/search\/local\/ws\/rest\/v1',
          bbox: [
            30.962379455566406,
            -87.921371459960938,
            30.980169296264648,
            -87.887161254882813
          ],
          name: 'Houston, TX',
          point: {
            type: 'Point',
            coordinates: [
              30.971298217773437,
              -87.909408569335938
            ]
          },
          address: {
            adminDistrict: 'AL',
            adminDistrict2: 'Baldwin Co.',
            countryRegion: 'United States',
            formattedAddress: 'The T, AL',
            landmark: 'The T'
          },
          confidence: 'High',
          entityType: 'River',
          geocodePoints: [
            {
              type: 'Point',
              coordinates: [
                30.971298217773437,
                -87.909408569335938
              ],
              calculationMethod: 'Rooftop',
              usageTypes: [
                'Display'
              ]
            }
          ],
          matchCodes: [
            'Good'
          ]
        },
        {
          __type: 'Location:http:\/\/schemas.microsoft.com\/search\/local\/ws\/rest\/v1',
          bbox: [
            45.150751449282836,
            -89.756156880989991,
            45.208692215267945,
            -89.646614115103759
          ],
          name: 'Manhattan, New York',
          point: {
            type: 'Point',
            coordinates: [
              45.179721832275391,
              -89.701385498046875
            ]
          },
          address: {
            countryRegion: 'United States',
            formattedAddress: 'T.B. Scott Free Library',
            landmark: 'T.B. Scott Free Library'
          },
          confidence: 'High',
          entityType: 'HistoricalSite',
          geocodePoints: [
            {
              type: 'Point',
              coordinates: [
                45.179721832275391,
                -89.701385498046875
              ],
              calculationMethod: 'Rooftop',
              usageTypes: [
                'Display'
              ]
            }
          ],
          matchCodes: [
            'Good'
          ]
        },
        {
          __type: 'Location:http:\/\/schemas.microsoft.com\/search\/local\/ws\/rest\/v1',
          bbox: [
            30.962379455566406,
            -87.921371459960938,
            30.980169296264648,
            -87.887161254882813
          ],
          name: 'Miami, FL',
          point: {
            type: 'Point',
            coordinates: [
              30.971298217773437,
              -87.909408569335938
            ]
          },
          address: {
            adminDistrict: 'AL',
            adminDistrict2: 'Baldwin Co.',
            countryRegion: 'United States',
            formattedAddress: 'The T, AL',
            landmark: 'The T'
          },
          confidence: 'High',
          entityType: 'River',
          geocodePoints: [
            {
              type: 'Point',
              coordinates: [
                30.971298217773437,
                -87.909408569335938
              ],
              calculationMethod: 'Rooftop',
              usageTypes: [
                'Display'
              ]
            }
          ],
          matchCodes: [
            'Good'
          ]
        },
        {
          __type: 'Location:http:\/\/schemas.microsoft.com\/search\/local\/ws\/rest\/v1',
          bbox: [
            42.358438155232058,
            -71.09316669436069,
            42.366163590373411,
            -71.079227104467435
          ],
          name: 'Miami, Ohio',
          point: {
            type: 'Point',
            coordinates: [
              42.362300872802734,
              -71.086196899414063
            ]
          },
          address: {
            formattedAddress: 'Kendall\/MIT',
            landmark: 'Kendall\/MIT'
          },
          confidence: 'High',
          entityType: 'RailwayStation',
          geocodePoints: [
            {
              type: 'Point',
              coordinates: [
                42.362300872802734,
                -71.086196899414063
              ],
              calculationMethod: 'Rooftop',
              usageTypes: [
                'Display'
              ]
            }
          ],
          matchCodes: [
            'Good'
          ]
        }
      ]
    }
  ]
};

export const AutosuggestBingMock = {
  authenticationResultCode: 'ValidCredentials',
  brandLogoUri: 'http:\/\/dev.virtualearth.net\/Branding\/logo_powered_by.png',
  copyright: 'Copyright © 2020 Microsoft and its suppliers. ' +
    'All rights reserved. This API cannot be accessed and the content and any results may not be used, ' +
    'reproduced or transmitted in any manner without express written permission from Microsoft Corporation.',
  resourceSets: [
    {
      estimatedTotal: 1,
      resources: [
        {
          __type: 'Autosuggest:http:\/\/schemas.microsoft.com\/search\/local\/ws\/rest\/v1',
          value: [
            {
              __type: 'Place',
              address:
              {
                countryRegion: 'United States',
                adminDistrict: 'Illinois',
                adminDistrict2: 'Cook County',
                countryRegionIso2: 'US',
                formattedAddress: 'Illinois'
              },
              name: 'Lincoln Park'
            },
            {
              __type: 'LocalBusiness',
              address: {
                countryRegion: 'United States',
                locality: 'Chicago',
                adminDistrict: 'IL',
                countryRegionIso2: 'US',
                postalCode: '60611',
                addressLine: '455 N Park Dr',
                formattedAddress: '455 N Park Dr, Chicago, IL 60611'
              },
              name: 'Loews Chicago Hotel'
            },
            {
              __type: 'LocalBusiness',
              address: {
                countryRegion: 'United States',
                locality: 'Chicago',
                adminDistrict: 'IL',
                countryRegionIso2: 'US',
                postalCode: '60605',
                addressLine: '805 S State St',
                formattedAddress: '805 S State St, Chicago, IL 60605'
              },
              name: 'Lou Malnati\'s Pizzeria'
            },
            {
              __type: 'LocalBusiness',
              address: {
                countryRegion: 'United States',
                locality: 'Chicago',
                adminDistrict: 'IL',
                countryRegionIso2: 'US',
                postalCode: '60606',
                addressLine: '1 S Franklin St',
                formattedAddress: '1 S Franklin St, Chicago, IL 60606'
              },
              name: 'La Quinta Inn & Suites by Wyndham Chicago Downtown'
            },
            {
              __type: 'Place',
              address: {
                countryRegion: 'United States',
                locality: 'Chicago',
                adminDistrict: 'Illinois',
                adminDistrict2: 'Cook County',
                countryRegionIso2: 'US',
                formattedAddress: 'Chicago, Illinois'
              },
              name: 'Loyola University Chicago'
            },
            {
              __type: 'Place',
              address: {
                countryRegion: 'United States',
                locality: 'Lombard',
                adminDistrict: 'Illinois',
                adminDistrict2: 'DuPage County',
                countryRegionIso2: 'US',
                formattedAddress: 'Lombard, Illinois'
              }
            },
            {
              __type: 'LocalBusiness',
              address: {
                countryRegion: 'United States',
                locality: 'Chicago',
                adminDistrict: 'IL',
                countryRegionIso2: 'US',
                postalCode: '60607',
                addressLine: '1235 W Randolph St',
                formattedAddress: '1235 W Randolph St, Chicago, IL 60607'
              },
              name: 'Lou Malnati\'s Pizzeria'
            }]
        }]
    }],
  statusCode: 200,
  statusDescription: 'OK', traceId: '78261964813a45e981a07a10be07b150|CH00001606|0.0.0.1'
};

export const ReverseGeoBingMock = {
  authenticationResultCode: 'ValidCredentials',
  brandLogoUri: 'http:\/\/dev.virtualearth.net\/Branding\/logo_powered_by.png',
  copyright: `Copyright © 2011 Microsoft and its suppliers. All rights reserved.
  This API cannot be accessed and the content and any results may not be used,
  reproduced or transmitted in any manner without express written permission from Microsoft Corporation.`,
  resourceSets: [
    {
      estimatedTotal: 1,
      resources: [
        {
          __type: 'Location:http:\/\/schemas.microsoft.com\/search\/local\/ws\/rest\/v1',
          bbox: [
            47.636705672917948,
            -122.137016420622,
            47.6444311080593,
            -122.1217297861384
          ],
          name: 'Fort Lauderdale, FL',
          point: {
            type: 'Point',
            coordinates: [
              47.640568390488625,
              -122.1293731033802
            ]
          },
          address: {
            addressLine: '1 Microsoft Way',
            adminDistrict: 'FL',
            adminDistrict2: 'King Co.',
            countryRegion: 'United States',
            formattedAddress: 'Fort Lauderdale, FL',
            locality: 'Fort Lauderdale',
            postalCode: '33301'
          },
          confidence: 'High',
          entityType: 'Address',
          geocodePoints: [
            {
              type: 'Point',
              coordinates: [
                47.640568390488625,
                -122.1293731033802
              ],
              calculationMethod: 'Interpolation',
              usageTypes: [
                'Display',
                'Route'
              ]
            }
          ],
          matchCodes: [
            'Good'
          ]
        }
      ]
    }
  ],
  statusCode: 200,
  statusDescription: 'OK',
  traceId: '99b1256e09044490bce82bbbba1dab7a'
};
