import { Component, Inject } from '@angular/core';
import { IConfig } from '../../interfaces/config.interface';
import { FindStoreConfig, Cta } from '../../interfaces/findstore.interface';

@Component({
    selector: 'dt-backTopCtaComponent',
    templateUrl: './backTopCta.component.html',
    styleUrls: ['./backTopCta.component.scss']
})
export class BackTopCtaComponent {

    FindStoreConfigData: FindStoreConfig;
    textCta: Cta;
    currentTop = 0;

    constructor(
        @Inject('config') public SitecoreConfig: IConfig,
    ) {
        this.textCta = SitecoreConfig.Sitecore.FindStoreConfig.ResultsPage.BackTopCta;
    }

    /* istanbul ignore next */
    backToTop() {
        this.currentTop = this.getPageOffset();
        if (this.currentTop > 0) {
            window.scrollBy(0, -100);
            setTimeout( () => {
                this.backToTop();
            }, 0);
        }
    }

    /* istanbul ignore next */
    getPageOffset() {
        return window.pageYOffset;
    }
}
