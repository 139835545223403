import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DevModeService } from './dev-mode.service';
import { IStoreSearchRequest, IStoreSearchResponse } from '../interfaces/search.interface';
import { FindStoreConfig, ISearchCriteria } from '../interfaces/findstore.interface';
import { StoreDetailsConfig } from '../interfaces/storeDetails.interface';
import { IConfig } from '../interfaces/config.interface';

@Injectable({ providedIn: 'root' })

export class FindStorePubSub {

    constructor(
        private readonly http: HttpClient,
        public devModeService: DevModeService) { 
        }

    submitForm(searchCriteria: ISearchCriteria, findStoreConfig: FindStoreConfig, isHeadless?: boolean): Observable<IStoreSearchResponse> {
        const url = findStoreConfig.GetMakesURL;
        sessionStorage.setItem('resultfas-clicked', 'false');
        if (searchCriteria.Criteria && searchCriteria.Criteria.City !== '' && searchCriteria.Criteria.State !== '') {
            const storeSearchRequest: IStoreSearchRequest = {
                ZipCode: this.setZipCode(searchCriteria,isHeadless),
                Latitude: this.setLatitude(searchCriteria),
                Longitude : this.setLongitude(searchCriteria),
                City: searchCriteria.Criteria.City,
                State: searchCriteria.Criteria.State,
                Radius: findStoreConfig.SearchRadio,
                Makes: searchCriteria.Criteria.makes,
                DepartmentTypes: this.getDepartments(searchCriteria),
                ItemsPerPage: findStoreConfig.TilesPerPage,
                CurrentPage: searchCriteria.Criteria.CurrentPage
            };
            return this.http.post<IStoreSearchResponse>(url, storeSearchRequest);
        } else {
        const noResultsResponse: IStoreSearchResponse = {
            Makes: [],
            Store: [],
            StoreTotalCount: 0,
            NationwideSearch: false
        };
        return of(noResultsResponse);
        }
    }

    setLongitude(searchCriteria: ISearchCriteria): number {
        return searchCriteria.Criteria && searchCriteria.Criteria.Location && searchCriteria.Criteria.Location.PostalCode
            ? searchCriteria.Criteria.Location.Longitude : 0;
    }

    setLatitude(searchCriteria: ISearchCriteria): number {
        return searchCriteria.Criteria && searchCriteria.Criteria.Location && searchCriteria.Criteria.Location.PostalCode
            ? searchCriteria.Criteria.Location.Latitude : 0;
    }

    setZipCode(searchCriteria: ISearchCriteria, isHeadless: boolean): any {
        return searchCriteria.Criteria && searchCriteria.Criteria.Location && searchCriteria.Criteria.Location.PostalCode
            ? (isHeadless ? searchCriteria.Criteria.Location.PostalCode : Number(searchCriteria.Criteria.Location.PostalCode)): isHeadless ? '' : 0;
    }

    getReviewsStores(storeId: number, storeDetailsConfig: StoreDetailsConfig): Observable<string> {
        if (storeId) {
            const url = storeDetailsConfig.ReviewsDetailsApi + storeId;
            return this.http.get(url, {responseType: 'text'});
        } else {
            return of('noResultsResponse');
        }
    }

    getDepartments(searchCriteria: ISearchCriteria) {
        const departmentSearch = searchCriteria.Criteria.Departments;
        if (departmentSearch.includes('all') || departmentSearch.length === 0) {
            return ['R', 'S', 'C'];
        }
        return departmentSearch;
    }
}
function Inject(arg0: string): (target: typeof FindStorePubSub, propertyKey: undefined, parameterIndex: 0) => void {
    throw new Error('Function not implemented.');
}

