import { Pipe, PipeTransform } from '@angular/core';
import { StoreDetails } from '../interfaces/storeDetails.interface';

@Pipe({ name: 'formatGoogleMapUrl' })
export class FormatGoogleMapUrl implements PipeTransform {

  transform(storeDetail: StoreDetails, mapURL: string): any {
    let addressQuery = `${storeDetail.AddressLine1} ${storeDetail.AddressLine2}
    ${storeDetail.City} ${storeDetail.StateCode} ${storeDetail.PostalCode}`;
    addressQuery = encodeURI(addressQuery);
    return `${mapURL}?daddr=${addressQuery}`;
  }
}
