import { Injectable } from '@angular/core';
import { UDO } from '../../global';
import { ITealiumInformation } from '../interfaces/tealium.interface';
import { TealiumService } from './tealium.service';
import { SearchCriteria} from '../interfaces/findstore.interface';

@Injectable({
    providedIn: 'root'
})

export class FormInfoService {
    constructor(public tealium: TealiumService) {

    }
    utagcontants: ITealiumInformation = {};
    utagcategory: any = {};
    utagevents: any = {};
    utagprocess: any = {};
    utagdimensions: any = {};
    utagstoreDetails: any = {};
    utagForm: any = {};
    utagFormProcess: any = {};
    utagLead: any = {};
    // eslint-disable-next-line max-len
    fillTealiumData(categoryl1?: string, categoryl2?: string, categoryl3?: string, categoryl4?: string, processactualname?: string, eventLabel?: string, event?: string, eventCategory?: string, eventAction?: string) {
        this.fillCategory(categoryl1, categoryl2, categoryl3, categoryl4);
        this.fillEvents(eventLabel, event, eventCategory, eventAction);
        this.fillProcess(processactualname);
        this.fillDimension();
    }

    fillTealiumLoadData(categoryl1?: string, categoryl2?: string, categoryl3?: string, categoryl4?: string, processactualname?: string) {
      this.fillCategory(categoryl1, categoryl2, categoryl3, categoryl4);
      this.fillProcess(processactualname);
      this.fillDimension();
  }

    fillTealiumFormData(FormActualName?: string, FormName?: string) {
        this.fillFormAction(FormActualName, FormName);
    }
    fillCategory(categoryl1?: string, categoryl2?: string, categoryl3?: string, categoryl4?: string) {
        this.utagcategory.category_l1 = categoryl1;
        this.utagcategory.category_l2 = categoryl2;
        this.utagcategory.category_l3 = categoryl3;
        this.utagcategory.category_l4 = categoryl4;
    }
    fillEvents(eventLabel?: string, event?: string, eventCategory?: string, eventAction?: string) {
        this.utagevents.event_action = eventAction ? eventAction : UDO.ActionType;
        this.utagevents.event_category = eventCategory ? eventCategory : UDO.EngagementType;
        this.utagevents.event_value = UDO.Type;
        this.utagevents.event_label = eventLabel;
        this.utagevents.event = event;
    }
    fillProcess(processactualname?: string) {
        this.utagprocess.pagegroup = processactualname ? processactualname : UDO.Pagegroup;
    }
    fillDimension() {
        this.utagdimensions.display_dimension_width = window.innerWidth;
        this.utagdimensions.display_dimension_height = window.innerHeight;
    }
    fillFormAction(FormActualName?: string, FormName?: string) {
        this.utagForm.form_actual_name = FormActualName;
        this.utagForm.form_name = FormName;
    }

    reportSelectedMakes( makes: string[]) {
        const reportEventMake: any = {};
        const makesSelected: any = { make: makes };
        // eslint-disable-next-line max-len
        this.fillTealiumData(UDO.HomepageCatL1, UDO.HomepageCatL2, UDO.HomepageCatL3, UDO.NotSet, UDO.NotSet, UDO.SelectMakeEventLabel, UDO.SelectMakeEvent);
        Object.assign(reportEventMake, this.utagcategory, this.utagevents, this.utagprocess, this.utagcontants, makesSelected);
        (window as any).utag_data = reportEventMake;
        this.tealium.link((window as any).utag_data);
    }

    // Use/Update Current Location
    reportCurrentLocationClick(fromHomepage: boolean) {
      const catL2 = fromHomepage ? UDO.HomepageCatL2 : UDO.ResultspageCatL2;
      const reportCurrentLocation: any = {};
      // eslint-disable-next-line max-len
      this.fillTealiumData(UDO.HomepageCatL1, catL2, UDO.NotSet, UDO.NotSet, UDO.Pagegroup, UDO.CurrentLocationEventLabel,
        UDO.CurrentLocationEvent, UDO.CurrentLocationEventCat, UDO.CurrentLocationEventAction);
      Object.assign(reportCurrentLocation, this.utagcategory, this.utagevents, this.utagprocess, this.utagcontants);
      (window as any).utag_data = reportCurrentLocation;
      this.tealium.link((window as any).utag_data);
  }

    reportFASDepartmentChanged(UDOEventLabel: string, UDOEvent: string) {
        const reportDepartmentChanged: any = {};
        // eslint-disable-next-line max-len
        this.fillTealiumData(UDO.HomepageCatL1, UDO.ResultspageCatL2,  UDO.NotSet, UDO.NotSet, UDO.Pagegroup, UDOEventLabel, UDOEvent, UDO.EngagementType, UDO.ResultspageDeptToggleEventventAction );
        Object.assign(reportDepartmentChanged, this.utagcategory, this.utagevents, this.utagprocess, this.utagcontants);
        (window as any).utag_data = reportDepartmentChanged;
        this.tealium.link((window as any).utag_data);
    }

    // Load Event Page (Landing Page only)
    reportFASFormLoad() {
       console.log('Load Tealium FAS HomePage');
       const reportFASFormLoad: any = {};
       // eslint-disable-next-line max-len
       this.fillTealiumLoadData(UDO.HomepageCatL1, UDO.HomepageCatL2, UDO.NotSet, UDO.NotSet, UDO.Pagegroup);
       this.fillTealiumFormData( UDO.FasFormActualName, UDO.FasFormName);
       Object.assign(reportFASFormLoad, this.utagcategory, this.utagprocess, this.utagcontants);
       (window as any).utag_data = reportFASFormLoad;
       this.tealium.view((window as any).utag_data);
    }

    // Load Event Page (Results Page only)
    // eslint-disable-next-line max-len
    reportFASResultsLoad(numberContentResult: number, resultsPerPage: number, siteSearchTerm: SearchCriteria,  departments: any, nationWideSearch: boolean)  {
      console.log('Load Tealium Results Page ');
      const reportFASResultsLoad: any = {};
      // eslint-disable-next-line max-len
      this.fillTealiumLoadData(UDO.ResultspageCatL1, UDO.ResultspageCatL2, UDO.NotSet, UDO.NotSet, UDO.Pagegroup);
      this.fillTealiumFormData( UDO.FasFormActualName, UDO.FasFormName);
      Object.assign(reportFASResultsLoad, this.utagcategory, this.utagprocess, this.utagcontants);
      if (nationWideSearch) {
          reportFASResultsLoad.no_result = UDO.NoResult;
      }
      reportFASResultsLoad.number_content_result = numberContentResult;
      reportFASResultsLoad.results_page = UDO.ResultsPage;
      reportFASResultsLoad.results_per_page = resultsPerPage;
      reportFASResultsLoad.store_search_city = siteSearchTerm.City;
      // eslint-disable-next-line max-len
      reportFASResultsLoad.store_search_make = siteSearchTerm.makes && siteSearchTerm.makes.length > 0 ? siteSearchTerm.makes : UDO.NotSet;
      // eslint-disable-next-line max-len
      reportFASResultsLoad.store_search_type = departments && departments.length > 0 ? departments : UDO.NotSet;
      (window as any).utag_data = reportFASResultsLoad;
      this.tealium.view((window as any).utag_data);
  }

    // Find Store Button Click (Results and Landing)
    // eslint-disable-next-line max-len
    reportFASFormSubmitStart(fromHomepage: boolean, makes: any, departments: any, city: string, storeCount: number, nationWideSearch: boolean) {
        console.log('find Store Button Click: IsHomePage + ' + fromHomepage);
        const catL1 = fromHomepage ? UDO.HomepageCatL1 : UDO.ResultspageCatL1;
        const catL2 = fromHomepage ? UDO.HomepageCatL2 : UDO.ResultspageCatL2;
        const formActualName = fromHomepage ? UDO.FasFormActualName : UDO.ResultsFASFormActualName;
        const reportFASFormSubmitStart: any = {};
        // eslint-disable-next-line max-len
        this.fillTealiumData(catL1, catL2, UDO.NotSet, UDO.NotSet, UDO.Pagegroup, UDO.FasFormEventSubmitStartLabel, UDO.FasFormEventSubmitStart, UDO.FasFormEventCat, UDO.FasFormEventAction);
        this.fillTealiumFormData( formActualName, UDO.FasFormName);
        Object.assign(reportFASFormSubmitStart, this.utagcategory, this.utagevents, this.utagprocess, this.utagcontants);
        if (!fromHomepage) {
          reportFASFormSubmitStart.number_content_result = storeCount;
          if (nationWideSearch) {
            reportFASFormSubmitStart.no_result = UDO.NoResult;
          }
        }

        reportFASFormSubmitStart.store_search_city = city;
        // eslint-disable-next-line max-len
        reportFASFormSubmitStart.store_search_make = makes && makes.length > 0 ? makes : UDO.NotSet;
        // eslint-disable-next-line max-len
        reportFASFormSubmitStart.store_search_type = departments && departments.length > 0 ? departments : UDO.NotSet;
        (window as any).utag_data = reportFASFormSubmitStart;
        this.tealium.link((window as any).utag_data);
    }

    // ---------- STORE DETAILS PAGE -----------
    reportStoreDetailsPageDisplayed( phone: string, storeId: string, lang: string ) {
        const reportStoreDetailsPageDisplayed: any = {
            contact_us_phone: phone,
            hyperion_id: storeId,
            page_language: lang,
            enh_action: UDO.EnhAction
        };
        // eslint-disable-next-line max-len
        this.fillTealiumData(UDO.HomepageCatL1, UDO.SdpCatL2, UDO.NotSet, UDO.NotSet, UDO.SdpPagegroup, UDO.SdpEventLabel, UDO.SdpEvent, UDO.SdpEventCat, UDO.SdpEventAction);
        Object.assign(reportStoreDetailsPageDisplayed, this.utagcategory, this.utagevents, this.utagprocess, this.utagcontants);
        (window as any).utag_data = reportStoreDetailsPageDisplayed;
        this.tealium.view((window as any).utag_data);
    }

    reportMakeThisMyStoreClick() {
        const reportMAkeThisMyStoreClick: any = {};
        // eslint-disable-next-line max-len
        this.fillTealiumData(UDO.HomepageCatL1, UDO.SdpCatL2,  UDO.NotSet, UDO.NotSet, UDO.SdpPagegroup, UDO.MyStoreCtaEventLabel, UDO.MyStoreCtaEvent, UDO.SdpEventCat, UDO.SdpEventActionClick);
        Object.assign(reportMAkeThisMyStoreClick, this.utagcategory, this.utagevents, this.utagprocess, this.utagcontants);
        (window as any).utag_data = reportMAkeThisMyStoreClick;
        this.tealium.link((window as any).utag_data);
    }

    reportToCallClick(phone: string, fromResults: boolean) {
        const catL2 = fromResults ? UDO.ResultspageCatL2 : UDO.SdpCatL2;
        const catL3 = fromResults ? UDO.NotSet : UDO.NotSet;
        const pageGroup = fromResults ? UDO.Pagegroup : UDO.SdpPagegroup;
        const eventAction = fromResults ? UDO.ResultspageCallCtaEventAction : UDO.CallCtaEventAction;
        const reportClickToCallClick: any = {};
        reportClickToCallClick.phone_click = '1';
        // eslint-disable-next-line max-len
        this.fillTealiumData(UDO.HomepageCatL1, catL2,  catL3, UDO.NotSet, pageGroup, phone, UDO.CallCtaEvent, UDO.CallCtaEventCat, eventAction);
        Object.assign(reportClickToCallClick, this.utagcategory, this.utagevents, this.utagprocess, this.utagcontants);
        (window as any).utag_data = reportClickToCallClick;
        this.tealium.link((window as any).utag_data);
    }

    reportStoreAddressClick(fromResults: boolean) {
        const catL2 = fromResults ? UDO.ResultspageCatL2 : UDO.SdpCatL2;
        const catL3 = fromResults ? UDO.NotSet : UDO.NotSet;
        const pageGroup = fromResults ? UDO.Pagegroup : UDO.SdpPagegroup;
        const eventAction = fromResults ? UDO.AdressCtaEventAction : UDO.SdpEventActionClick;
        const reportStoreAddressClick: any = {};
        // eslint-disable-next-line max-len
        this.fillTealiumData(UDO.HomepageCatL1, catL2,  catL3, UDO.NotSet, pageGroup, UDO.AdressCtaEventLabel, UDO.AdressCtaEvent, UDO.AdressCtaEventCat, eventAction);
        Object.assign(reportStoreAddressClick, this.utagcategory, this.utagevents, this.utagprocess, this.utagcontants);
        (window as any).utag_data = reportStoreAddressClick;
        this.tealium.link((window as any).utag_data);
    }

    reportVehiclesOnSaleClick() {
        const reportVehiclesOnSaleClick: any = {};
        // eslint-disable-next-line max-len
        this.fillTealiumData(UDO.HomepageCatL1, UDO.ResultspageCatL2,  UDO.NotSet, UDO.NotSet, UDO.Pagegroup, UDO.VehiclesOnSaleCtaEventLabel, UDO.VehiclesOnSaleCtaEvent, UDO.EngagementType, UDO.VehiclesOnSaleCtaEventAction);
        Object.assign(reportVehiclesOnSaleClick, this.utagcategory, this.utagevents, this.utagprocess, this.utagcontants);
        (window as any).utag_data = reportVehiclesOnSaleClick;
        this.tealium.link((window as any).utag_data);
    }

    reportScheduleServiceClick(fromResults: boolean) {
        const catL2 = fromResults ? UDO.ResultspageCatL2 : UDO.SdpCatL2;
        const pageGroup = fromResults ? UDO.Pagegroup : UDO.SdpPagegroup;
        const eventCat = fromResults ? UDO.EngagementType : UDO.SdpEventCat;
        const eventAction = fromResults ? UDO.ResultspageScheduleCTAEventAction : UDO.SdpEventActionClick;
        const reportScheduleServiceClick: any = {};
        // eslint-disable-next-line max-len
        this.fillTealiumData(UDO.HomepageCatL1, catL2,   UDO.NotSet, UDO.NotSet, pageGroup, UDO.ScheduleServiceCtaEventLabel, UDO.ScheduleServiceCtaEvent, eventCat, eventAction);
        Object.assign(reportScheduleServiceClick, this.utagcategory, this.utagevents, this.utagprocess, this.utagcontants);
        (window as any).utag_data = reportScheduleServiceClick;
        this.tealium.link((window as any).utag_data);
    }

    reportScheduleSalesClick(fromResults: boolean) {
        const catL2 = fromResults ? UDO.ResultspageCatL2 : UDO.SdpCatL2;
        const pageGroup = fromResults ? UDO.Pagegroup : UDO.SdpPagegroup;
        const eventCat = fromResults ? UDO.EngagementType : UDO.SdpEventCat;
        const eventAction = fromResults ? UDO.ResultspageScheduleCTAEventAction : UDO.SdpEventActionClick;
        const reportScheduleSalesClick: any = {};
        // eslint-disable-next-line max-len
        this.fillTealiumData(UDO.HomepageCatL1, catL2,  UDO.NotSet, UDO.NotSet, pageGroup, UDO.ScheduleSalesCtaEventLabel, UDO.ScheduleSalesCtaEvent, eventCat, eventAction);
        Object.assign(reportScheduleSalesClick, this.utagcategory, this.utagevents, this.utagprocess, this.utagcontants);
        (window as any).utag_data = reportScheduleSalesClick;
        this.tealium.link((window as any).utag_data);
    }

    reportScheduleCollisonClick(fromResults: boolean) {
        const catL2 = fromResults ? UDO.ResultspageCatL2 : UDO.SdpCatL2;
        const pageGroup = fromResults ? UDO.Pagegroup : UDO.SdpPagegroup;
        const eventCat = fromResults ? UDO.EngagementType : UDO.SdpEventCat;
        const eventAction = fromResults ? UDO.ResultspageScheduleCTAEventAction : UDO.SdpEventActionClick;
        const reportScheduleCollisonClick: any = {};
        // eslint-disable-next-line max-len
        this.fillTealiumData(UDO.HomepageCatL1, catL2,   UDO.NotSet, UDO.NotSet, pageGroup, UDO.ScheduleCollisionCtaEventLabel, UDO.ScheduleCollisionCtaEvent, eventCat, eventAction);
        Object.assign(reportScheduleCollisonClick, this.utagcategory, this.utagevents, this.utagprocess, this.utagcontants);
        (window as any).utag_data = reportScheduleCollisonClick;
        this.tealium.link((window as any).utag_data);
    }

    reportDrawerExpandClick(drawerTitle: string) {
        const reportDrawerExpandClick: any = {};
        // eslint-disable-next-line max-len
        this.fillTealiumData(UDO.HomepageCatL1, UDO.SdpCatL2,  UDO.NotSet, UDO.NotSet, UDO.SdpPagegroup, drawerTitle, UDO.DrawerClickEvent, UDO.SdpEventCat, UDO.SdpEventActionClick);
        Object.assign(reportDrawerExpandClick, this.utagcategory, this.utagevents, this.utagprocess, this.utagcontants);
        (window as any).utag_data = reportDrawerExpandClick;
        this.tealium.link((window as any).utag_data);
    }
    // -------- END STORE DETAILS PAGE ---------

    reportStoreDetailsClick() {
        const reportStoreDetailsClick: any = {};
        // eslint-disable-next-line max-len
        this.fillTealiumData(UDO.HomepageCatL1, UDO.ResultspageCatL2,  UDO.NotSet, UDO.NotSet, UDO.Pagegroup, UDO.ResultspageStoreDetailsEventLabel, UDO.ResultspageStoreDetailsEvent, UDO.EngagementType, UDO.ResultspageClickEventAction);
        Object.assign(reportStoreDetailsClick, this.utagcategory, this.utagevents, this.utagprocess, this.utagcontants);
        (window as any).utag_data = reportStoreDetailsClick;
        this.tealium.link((window as any).utag_data);
    }

    reportListMapViewClick(label: string) {
        const reportListMapViewClick: any = {};
        // eslint-disable-next-line max-len
        this.fillTealiumData(UDO.HomepageCatL1, UDO.HomepageCatL2,  UDO.ResultspageCatL3, UDO.NotSet, UDO.Pagegroup, label, UDO.ResultspageListMapViewEvent, UDO.EngagementType, UDO.ResultspageClickEventAction);
        Object.assign(reportListMapViewClick, this.utagcategory, this.utagevents, this.utagprocess, this.utagcontants);
        (window as any).utag_data = reportListMapViewClick;
        this.tealium.link((window as any).utag_data);
    }
}



