import { Injectable } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BreakpointService {

    breakpoint = new BehaviorSubject('');
    breakpoints = {
        xs: '(max-width: 575px)',
        sm: '(min-width: 576px) and (max-width: 767px)',
        md: '(min-width: 768px) and (max-width: 991px)',
        lg: '(min-width: 992px) and (max-width: 1200px)',
        xl: '(min-width: 1200px)',
        hs: '(min-width: 767px) and (max-height: 481px)',
    };

    constructor(public breakpointObserver: BreakpointObserver) {
        this.breakpointObserver
            .observe([
                this.breakpoints.xs,
                this.breakpoints.sm,
                this.breakpoints.md,
                this.breakpoints.lg,
                this.breakpoints.xl,
                this.breakpoints.hs,
            ])
            .subscribe((state: BreakpointState) => {
                this.setBreakpoint(state);
                console.log('Breakpoint', this.breakpoint.value);
            });
    }

    setBreakpoint(state: BreakpointState) {
        if (state.breakpoints[this.breakpoints.xs]) {
            this.breakpoint.next('xs');
        } else if (state.breakpoints[this.breakpoints.sm]) {
            this.breakpoint.next('sm');
        } else if (state.breakpoints[this.breakpoints.md]) {
            this.breakpoint.next('md');
        } else if (state.breakpoints[this.breakpoints.lg]) {
            this.breakpoint.next('lg');
        } else if (state.breakpoints[this.breakpoints.xl]) {
            this.breakpoint.next('xl');
        } else if (state.breakpoints[this.breakpoints.hs]) {
            this.breakpoint.next('hs');
        } else {
            this.breakpoint.next('');
        }
    }
}
