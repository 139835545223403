import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomepageComponent } from './components/homepage/homepage.component';
import { ResultsPageComponent } from './components/resultspage/resultspage.component';
import { StoreDetailsPageComponent } from './components/storedetailspage/storedetailspage.component';

const routes: Routes = [
  {
    path: 'storedetails/:nameStore/:storeId',
    component: StoreDetailsPageComponent
  },
  {
    path: 'results',
     redirectTo:"dealers/results"
  },
  {
    path: 'dealers/results',
    component: ResultsPageComponent
  },
  {
    path: 'dealers-hl/results',
    component: ResultsPageComponent
  },
  {
    path: '',
    component: HomepageComponent,
    pathMatch: 'full',
    data : {isIframe : window.location.href.toString().toLowerCase().indexOf('iframe=true') !== -1 }
  },
  {
    path: '**',
    component: HomepageComponent,
    pathMatch: 'full'
  }
];


// { path: "product/:id", component: ProductDetailComponent }


@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
