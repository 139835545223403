import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilter'
})
export class SearchFilterPipe implements PipeTransform {
  transform(items: any[], searchTerm: string, labelKey?: string): any {
    const label = labelKey || 'label';
    if (!items || !searchTerm) {
      return items;
    }

    return items
    .filter(
      item =>
        item[label]
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) === true
    )
    .map(item => {
      item[label] = item.value ? item.value : item[label];
      const re = new RegExp(`(${searchTerm})`, 'i');
      item[label] = item[label].replace(re, `<span class='highlight-autocomplete'>$1</span>` );
      item[label] = `<span>${item[label]}</span`;
      return item;
    });
  }
}
