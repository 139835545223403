import { Component, Inject } from '@angular/core';
import { StateService } from './services/state.service';
import { IConfig } from './interfaces/config.interface';
import { TealiumService } from './services/tealium.service';
import { configMock } from '../tests/config.mock';
import { DevModeService } from './services/dev-mode.service';
import { Subscription } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';

export let browserRefresh = false;

@Component({
    selector: 'dt-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent {
    title = 'dt-an-find-store-page-app';
    devMode = this.devModeService.isDevMode();
    subscription: Subscription;

    constructor(
            @Inject('config') public config: IConfig,
            private readonly stateService: StateService,
            private readonly devModeService: DevModeService,
            private readonly tealiumService: TealiumService,
            private readonly router: Router
        ) {
            this.initilize();
            this.subscription = this.router.events.subscribe((event) => {
             /* istanbul ignore else */
                if (event instanceof NavigationStart) {
                  browserRefresh = !router.navigated;
                }
            });
    }

    initilize() {
        this.config.Sitecore = this.devMode ? configMock.Sitecore : this.stateService.get('Sitecore');
        console.log('@@@ Config Loaded @@@', this.config.Sitecore);
        if (this.config.Sitecore && this.config.Sitecore.TealiumSettings) {
            const tealiumAccount = this.config.Sitecore.TealiumSettings.TealiumAccount;
            const tealiumProfile = this.config.Sitecore.TealiumSettings.TealiumProfile;
            const tealiumEnv = this.config.Sitecore.TealiumSettings.TealiumENV;
            this.tealiumService.setConfig({
                account: tealiumAccount,
                profile: tealiumProfile,
                environment: tealiumEnv
            });
        }
    }
}
