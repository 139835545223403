<div class="section results-page">
    <div class="row container-results">
        <div class=" col-md-6 col-lg-7 col-xl-6 container-map-desktop"
            [ngClass]="{'no-display': screenSize === 'sm' || screenSize === 'xs'}">
            <div [ngStyle]="{'visibility':storeTotalCount > 0 ? 'visible' : 'hidden'}" id="map-desktop" class="map-desktop" [ngClass]="{'sticky-map': sticky}">
            </div>
            <div *ngIf="storeTotalCount === 0 || !isAzureMapsEnabled" id="map-desktop-error" class="map-desktop-error">
                <div class="nomap-container">
                    <div class="nomap-icon"><img src="{{resultsPageData.NoMapIcon.Src}}" alt="{{resultsPageData.NoMapIcon.Alt}}" /></div>
                    <div class="nomap-title">{{resultsPageData.NoMapTitle}}</div>
                    <div class="nomap-text">{{resultsPageData.NoMapText}}</div>
                </div>
            </div>
        </div>
        <div class="col-md-6 col-lg-5 col-xl-6 container-sub-results"
            [ngClass]="{'container-sub-results-s': screenSize === 'sm' || screenSize === 'xs'}">
            <div class="container-subheader-map col-sm-12"
                [ngClass]="{'flex-display': screenSize !== 'sm' && screenSize !== 'xs'}">
                <div class="container-subheader" [ngClass]="{'flex-order-2': screenSize !== 'sm' && screenSize !== 'xs'}">
                    <dt-subheadercomponent #subheader [showListViewLink]="showListViewToggle" (isListView)="onListViewToggle($event)"
                        [storeCount]="storeTotalCount" [countPerPage]="getCount()" [storeLength]="stores.length">
                    </dt-subheadercomponent>
                </div>
            </div>

            <div *ngIf="screenSize !== 'lg' && screenSize !== 'xl'" class="container-tiles col-sm-12 col-md-12 col-lg-12">
                <div *ngIf="!listView && storeTotalCount > 0 && isAzureMapsEnabled"
                    [ngClass]="{'no-display': screenSize !== 'sm' && screenSize !== 'xs'}"
                    class="container-map">
                    <div id="map-mobile" class="map-mobile" [ngClass]="{'sticky-map-mobile': sticky}">

                    </div>
                </div>
                <div class="row w-100 m-0" *ngIf="showNoResult()">
                    <div class="col-sm-12 col-xs-12 fsp-nr-cont-m">
                        <div class="row w-100 m-0 align-items-center align-self-center" (click)="closeNoResults()">
                            <!--no results mobile-->
                            <div class="fsp-nrc">
                                <img src="{{resultsPageData.CloseIcon.Src}}" alt="{{resultsPageData.CloseIcon.Alt}}" />
                            </div>
                            <div class="col-md-2 p-0 fsp-nrim">
                                <img src="{{resultsPageData.NoResultsIcon.Src}}" alt="{{resultsPageData.NoResultsIcon.Alt}}" />
                            </div>
                            <div class="col-md-10 fsp-nrm">
                                <div class="fsp-nr-title">
                                    {{resultsPageData.NoResultsTitle}}
                                </div>
                                <div class="fsp-nr-text">
                                    {{resultsPageData.NoResultsText}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="fsp-nr-cont" *ngIf="(screenSize === 'lg' || screenSize === 'xl') && showNoResult()" (click)="closeNoResults()">
                <div class="fsp-nrc">
                    <img src="{{resultsPageData.CloseIcon.Src}}" alt="{{resultsPageData.CloseIcon.Alt}}" />
                </div>
                <div class="row w-100 m-0">
                   <div class="col-lg-2 col-xl-1 p-0 fsp-nr-icon">
                    <img src="{{resultsPageData.NoResultsIcon.Src}}" alt="{{resultsPageData.NoResultsIcon.Alt}}" />
                   </div>
                   <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10 col-xl-11 fsp-nr-content">
                        <div class="row fsp-nr-title">
                            {{resultsPageData.NoResultsTitle}}
                        </div>
                        <div class="row fsp-nr-text">
                            {{resultsPageData.NoResultsText}}
                        </div>
                   </div>
               </div>
            </div>

            <div class="container-tiles col-sm-12 col-md-12 col-lg-12" [ngClass]="storeTotalCount > 0 ?'map-container-tiles':'nomap-container-tiles'" [ngStyle]="{'visibility':storeTotalCount > 0 ? 'visible' : 'hidden'}">
                <mat-accordion *ngIf="screenSize !== 'lg' && screenSize !== 'xl'">
                    <mat-expansion-panel *ngFor="let store of stores; index as i" id="result-tile-{{i}}" class="result-panel"
                        [expanded]="store.PanelOpen" (opened)="onTileExpanded(store.PanelOpen, i)" (closed)="store.PanelOpen = false">
                        <mat-expansion-panel-header collapsedHeight="*" expandedHeight="*">
                            <mat-panel-title>
                                <div (click)="titleOrTileClicked(store, 'map-mobile', i, false)" [ngClass]="{'no-display': screenSize === 'sm' || screenSize === 'xs'}">
                                    <h2 [id]="'title'+ i" class="title">{{store.Name}}</h2>
                                </div>
                                <h3 (click)="titleOrTileClicked(store, 'map-mobile', i, false)" [ngClass]="{'no-display': screenSize !== 'sm' && screenSize !== 'xs'}" class="title">{{store.Name}}
                                </h3>
                                <p class="miles">{{store.Distance | number:'1.0-2'}} Miles away</p>
                                <p *ngIf="store.PanelOpen && false">Icons to show: {{store.Makes}}</p>
                                <div *ngIf="!isOneMake(store.Makes) && getMakesCt(store.Makes) !== 0" class="brands-container">
                                        <div *ngFor="let make of getMakesArr(store.Makes); index as j">
                                            <span *ngIf="j < 4">
                                                <img [alt]="make + ' logo'" src="{{getSrc(make)}}" onerror='this.style.display = "none"'/>
                                            </span>
                                        </div>
                                </div>
                                <div *ngIf="isOneMake(store.Makes) && getMakesCt(store.Makes) !== 0" class="brands-container">
                                        <div>
                                            <img [alt]="store.Makes + ' logo'" src={{getSrc(store.Makes)}}  onerror='this.style.display = "none"' />
                                        </div>
                                </div>
                                <div class="address-container">
                                    <div>
                                        <span class="address-icon dt-icon dt-icon-location-pin"></span>
                                    </div>
                                    <div role="link" (click)="openMap(store, $event)" tabindex="0" class="address-link">
                                        {{store.AddressLine1}}
                                        <br *ngIf="store.AddressLine2">
                                        {{store.AddressLine2}}
                                        <br>
                                        {{store.City}}, {{store.StateCode}} {{store.PostalCode}}
                                    </div>
                                </div>
                                <div class="phone-container" [ngClass]="{'phone-container-sm': screenSize === 'sm' || screenSize === 'xs'}">
                                    <div class="piw"><span class="phone-icon dt-icon dt-icon-phone-blue"></span></div>
                                    <div><a tabindex="0"  (click)="openPhoneApp(store.Phone, $event)">{{store.Phone}}</a></div>
                                </div>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <a class="tertiary-cta"  
                            (click)="utagStoreClick($event)"
                            href="{{store.ReviewUrl}}"
                            title="{{FindStoreConfigData.StoreDetailsCta.Text}}"
                            [attr.aria-labelledby]="'store-details'+ i + ' title'+i">
                            {{FindStoreConfigData.StoreDetailsCta.Text}}
                        </a>

                        <a  class="tertiary-cta vehicles-sale-cta" [ngClass]="{'no-display': store.onlyCollisionDepartment}"
                            (click)="openVehiclesOnSale(store, $event)"
                            role="link"
                            href="javascript:void(0);"
                            title="{{FindStoreConfigData.VehiclesOnSaleCta.Text}}"
                            [attr.aria-labelledby]="'vehicles-sale-cta'+ i + ' title'+i"
                            *ngIf="shouldShowVehicleOnSale(store)">
                            {{FindStoreConfigData.VehiclesOnSaleCta.Text}}
                        </a>

                        <dt-cta-tab-group [store]="store" [tileId]="i" [mapContainer]="'map-mobile'" (isAppointmentTabClicked)="onAppointmentTabClicked($event)"></dt-cta-tab-group>

                    </mat-expansion-panel>
                </mat-accordion>

                <ng-container *ngIf="screenSize === 'lg' || screenSize === 'xl'">
                    <mat-card *ngFor="let store of stores; index as i" id="result-tile-{{i}}" class="result-panel" (click)="titleOrTileClicked(store, 'map-desktop', i, false)" [ngClass]="{'rt-border': highlightedTile === i}">
                        <div>
                            <div>
                                <a  class="map-link" (click)="utagStoreClick($event)" href="{{store.ReviewUrl}}">
                                    <h2 class="title" [id]="'title'+i">{{store.Name}}</h2>
                                </a>
                                <p class="miles">{{store.Distance | number:'1.0-2'}} Miles away</p>
                            </div>
                            <div class="row">
                                <div class="col-6 col-lg-12 col-xl-6">
                                    <div *ngIf="!isOneMake(store.Makes) && getMakesCt(store.Makes) !== 0" class="brands-container">
                                            <div *ngFor="let make of getMakesArr(store.Makes); index as j">
                                                <span *ngIf="j < 4">
                                                    <img [alt]="make + ' logo'" src="{{getSrc(make)}}" onerror='this.style.display = "none"'/>
                                                </span>
                                            </div>
                                    </div>
                                    <div *ngIf="isOneMake(store.Makes) && getMakesCt(store.Makes) !== 0" class="brands-container">
                                            <div>
                                                <img [alt]="store.Makes + ' logo'" src={{getSrc(store.Makes)}}  onerror='this.style.display = "none"' />
                                            </div>
                                    </div>
                                    <div class="address-container">
                                        <div>
                                            <span class="address-icon dt-icon dt-icon-location-pin"></span>
                                        </div>
                                        <div role="link" tabindex="0" (click)="openMap(store, $event)" class="address-link">
                                            {{store.AddressLine1}}
                                            <br *ngIf="store.AddressLine2">
                                            {{store.AddressLine2}}
                                            <br>
                                            {{store.City}}, {{store.StateCode}} {{store.PostalCode}}
                                        </div>
                                    </div>
                                    <div class="phone-container">
                                        <div class="piw"><span class="phone-icon dt-icon dt-icon-phone-blue"></span></div>
                                        <div><a tabindex="0" class="phone-link" (click)="openPhoneApp(store.Phone, $event)">{{store.Phone}}</a></div>
                                    </div>
                                    <a class="tertiary-cta sdl-tab"
                                        (click)="utagStoreClick($event)"
                                        href="{{store.ReviewUrl}}"
                                        title="{{FindStoreConfigData.StoreDetailsCta.Text}}"
                                        [attr.aria-labelledby]="'store-details'+ i + ' title'+i">
                                        {{FindStoreConfigData.StoreDetailsCta.Text}}
                                    </a>
                                    <a   class="tertiary-cta vehicles-sale-cta" [ngClass]="{'no-display': store.onlyCollisionDepartment}"
                                        (click)="openVehiclesOnSale(store, $event)"
                                        title="{{FindStoreConfigData.VehiclesOnSaleCta.Text}}"
                                        role="link"
                                        href="javascript:void(0);"
                                        [attr.aria-labelledby]="'vehicles-sale-cta'+ i + ' title'+i"
                                        *ngIf="shouldShowVehicleOnSale(store)">
                                        {{FindStoreConfigData.VehiclesOnSaleCta.Text}}
                                    </a>
                                </div>
                                <div class="col-6 col-lg-12 col-xl-6 tabs-desktop">
                                    <dt-cta-tab-group [store]="store" [fromResults]="true" [tileId]="i" [mapContainer]="'map-desktop'" (isAppointmentTabClicked)="onAppointmentTabClicked($event)"></dt-cta-tab-group>
                                    <div class="align-center sdl-container">
                                        <a class="tertiary-cta storedetails-link"
                                            (click)="utagStoreClick($event)"
                                            href="{{store.ReviewUrl}}"
                                            title="{{FindStoreConfigData.StoreDetailsCta.Text}}"
                                            [attr.aria-labelledby]="'store-details'+ i + ' title'+i">
                                            {{FindStoreConfigData.StoreDetailsCta.Text}}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-card>
                </ng-container>

                <div *ngIf="storeTotalCount > 0" class="container-loadmore" [ngClass]="{'no-display': !showLoadMore}">
                    <div class="d-block text-center">
                        <button (click)="loadMoreResults()" class="secondary-cta">{{FindStoreConfigData.ResultsPage.LoadMoreCta.Text}}</button>
                        <span class="sr-only">{{FindStoreConfigData.ResultsPage.LoadMoreCta.AdaText}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <dt-seoTextComponent [dataComponent]="seoComponentData" *ngIf="!isIframe"></dt-seoTextComponent>
    <dt-backTopCtaComponent></dt-backTopCtaComponent>
</div>
