import { LookupMappingInformation } from '../interfaces/customer.interface';
import { IDescriptionValuePair } from '../interfaces/config.interface';
import { FormControl, FormGroup } from '@angular/forms';

export function getLookupMappings(lookupMappingInfo: LookupMappingInformation[], lookupName: string): IDescriptionValuePair[] {
    if (lookupMappingInfo) {
        const lookUpResult = lookupMappingInfo.filter(x => x.LookupName === lookupName);
        const values = lookUpResult.length > 0 ? lookUpResult[0].Mappings : null;
        return values;
    }
    return null;
}

export function getCookie(cookieName: string): any {
    const cName = cookieName + '=';
    const ca = document.cookie.split(';');
    /* istanbul ignore next */
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1, c.length);
        }

        /* istanbul ignore else */
        if (c.indexOf(cName) === 0) {
            return c.substring(cName.length, c.length);
        }
    }
    return null;
}

export function getDataFromCookie(cookieName: string, keyName: string, split1 = 0, split2 = 0, split3 = 0, split4 = 0): any | undefined {
    const name = cookieName;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            const isExists = c.substring(name.length, c.length).split('&')[split1].split('=')[split2].indexOf(keyName);

            if (isExists === 0) {
                const value = c.substring(name.length, c.length).split('&')[split3].split('=')[split4];
                console.log('matched-' + keyName, value);
                if (value) {
                    // eslint-disable-next-line radix
                    return value;
                }
            }
        }
    }
}

export function validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
        const control = formGroup.get(field);
        if (control instanceof FormControl) {
            control.markAsTouched({ onlySelf: true });
            if (control.invalid) {
                control.markAsDirty();
            }
        } else if (control instanceof FormGroup) {
            this.validateAllFormFields(control);
        }
    });
}

export function HideHeader() {
    if (window.innerWidth < 767) {
        const headerMain = document.getElementsByClassName('instore-header') as HTMLCollectionOf<HTMLElement>;
        headerMain[0].style.display = 'none';
    }
}

export function ShowHeader() {
    if (window.innerWidth < 767) {
        const headerMain = document.getElementsByClassName('instore-header') as HTMLCollectionOf<HTMLElement>;
        headerMain[0].style.display = 'block';
    }
}

export function UpdateURL(listMakes, listDepartment, zipcodedata, city, state, Router) {
    const mks = listMakes;
    let url: string;
    if (mks.indexOf('Sales_Services') !== -1) {
        mks.splice(mks.indexOf('Sales_Services'), 1);
    }
    if (mks.indexOf('Collision') !== -1) {
        mks.splice(mks.indexOf('Collision'), 1);
    }

    const makes = encodeURI(listMakes.join());
    // R- Sales, S- Service, C- Collision
    const departments = listDepartment.length === 0 ? ['R', 'S', 'C'] : encodeURI(UpdateDepartmentWithCode(listDepartment).join());
    const scity = encodeURI(city);
    const sstate = encodeURI(state);
    const szip = encodeURI(zipcodedata.PostalCode);
    if (zipcodedata.PostalCode !== undefined) {
        url = `d=${departments}&mk=${makes}&c=${scity}&s=${sstate}&z=${szip}`;
    } else {
        url = `d=${departments}&mk=${makes}&c=${scity}&s=${sstate}`;
    }

    Router.navigate(['results'], { queryParams: { search: url } });
}

export function UpdateDepartmentWithCode(listDepartment: any) {
    const lstDeptWithValues = [];
    listDepartment.forEach(d => {
        if (d === 'Collision') {
            lstDeptWithValues.push('C');
        }
        if (d === 'Sales') {
            lstDeptWithValues.push('R');
        }
        if (d === 'Service') {
            lstDeptWithValues.push('S');
        }
        if (d === 'Sales_Services') {
            lstDeptWithValues.push('R');
            lstDeptWithValues.push('S');
        }
        if (d === 'alls') {
            lstDeptWithValues.push('R');
            lstDeptWithValues.push('S');
            lstDeptWithValues.push('C');
        }
    });
    return lstDeptWithValues;
}

export function DecodeURL(param) {
    const url = param.split('&');
    let departments = [];
    let makesList = [];
    let city: string;
    let state: string;
    if (url[0]) {
        departments = (decodeURI(url[0].split('=')[1]) === '') ? [] : decodeURI(url[0].split('=')[1]).split(',');
        if (url[1]) {
            makesList = (decodeURI(url[1].split('=')[1]) === '') ? [] : decodeURI(url[1].split('=')[1]).split(',');
        }
        if (url[2]) {
            city = (decodeURI(url[2].split('=')[1]) === '') ? '' : decodeURI(url[2].split('=')[1]);
        }
        if (url[3]) {
            state = (decodeURI(url[3].split('=')[1]) === '') ? '' : decodeURI(url[3].split('=')[1]);
        }
    }
    return {
        Location: { City: city, StateCode: state, Latitude: 0, Longitude: 0, PostalCode: '', ValidPostalCode: false },
        makes: makesList,
        Departments: departments.length === 0 ? ['R', 'S', 'C'] : departments,
        City: city,
        State: state
    };
}

export function updateHeader() {
    /* istanbul ignore else */
    if (sessionStorage.getItem('faszip')) {
        const fasZip = sessionStorage.getItem('faszip').split('|');
        /* istanbul ignore else */
        if (fasZip.length === 3) {
            updateHeaderZipCode(fasZip[0]);
        }
    }
}

/* istanbul ignore next */
function updateHeaderZipCode(val: string): any {
    /* istanbul ignore else */
    if (document.querySelector('.zipCodeDisplay')) {
        document.querySelector('.zipCodeDisplay').innerHTML = val;
    } else {
        document.dispatchEvent(new CustomEvent('__UPDATE__ZIPCODE__HEADER__V2__EVENT__', { 
            detail: { zipcode: val } 
        }));
    }

}
