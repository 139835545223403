import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LayoutModule } from '@angular/cdk/layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule} from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_BASE_HREF } from '@angular/common';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IConfig } from './interfaces/config.interface';
import { HomepageComponent } from './components/homepage/homepage.component';
import { BannerTopComponent } from './components/bannerTop/bannerTop.component';
import { FindStoreFormComponent } from './components/findForm/findForm.component';
import { ResultsPageComponent } from './components/resultspage/resultspage.component';
import { LoadingComponent } from './components/loading/loading.component';
import { GeoLocatorComponent } from './components/geoLocator/geoLocator.component';
import { SubheaderComponent } from './components/subheader/subheader.component';
import { SearchFilterPipe } from './pipes/search-filter.pipe';
import { SeoTextComponent } from './components/seoText/seoText.component';
import { BackTopCtaComponent } from './components/backTopCta/backTopCta.component';
import { ModalComponent } from './components/modal/modal.component';
import { SalesFormComponent } from './components/salesForm/salesForm.component';
import { CollisionFormComponent } from './components/collisionForm/collisionForm.component';
import { StoreDetailsPageComponent } from './components/storedetailspage/storedetailspage.component';
import { CtaTabGroupComponent } from './components/ctaTabGroup/ctaTabGroup.component';
import { ReplaceSpacesByDash } from './pipes/replaceSpacesByDash.pipe';
import { FormatGoogleMapUrl } from './pipes/formatGoogleMapUrl.pipe';
import { FormatCarSalesUrl } from './pipes/formatCarSalesUrl.pipe';
import { MatCardModule } from '@angular/material/card';
import { DtAnZipcodewidgetLibModule } from '@autonation/dt-an-zipcodewidget-lib';
import { AnModalModule } from '@autonation/dt-an-components-lib/an-modal';
import { NotificationModule } from '@autonation/dt-an-components-lib/notification-modal';

const config: IConfig = {
    SitecoreDomain: ''
};
@NgModule({
    declarations: [
        AppComponent,
        HomepageComponent,
        BannerTopComponent,
        FindStoreFormComponent,
        ResultsPageComponent,
        LoadingComponent,
        GeoLocatorComponent,
        SubheaderComponent,
        SearchFilterPipe,
        SeoTextComponent,
        BackTopCtaComponent,
        ModalComponent,
        SalesFormComponent,
        CollisionFormComponent,
        StoreDetailsPageComponent,
        CtaTabGroupComponent,
        ReplaceSpacesByDash,
        FormatGoogleMapUrl,
        FormatCarSalesUrl
    ],
    imports: [
        BrowserTransferStateModule,
        BrowserModule,
        AppRoutingModule,
        LayoutModule,
        FormsModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatDialogModule,
        MatInputModule,
        MatAutocompleteModule,
        MatExpansionModule,
        MatTabsModule,
        MatCardModule,
        HttpClientModule,
        HttpClientJsonpModule,
        DtAnZipcodewidgetLibModule,
        AnModalModule,
        NotificationModule
    ],
    providers: [
        { provide: 'config', useValue: config },
        { provide: APP_BASE_HREF, useValue: '/' }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
