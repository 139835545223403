import { Observable, Subject, } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })

export class LoadingLayout {

    // Observable string sources
    private readonly loading = new Subject<any>();

    showLoader() {
        this.loading.next(true);
    }

    hideLoader() {
        this.loading.next(false);
    }

    getStateLoader(): Observable<any> {
        return this.loading.asObservable();
    }
}
