import { Component, OnInit, OnDestroy, Inject, Input } from '@angular/core';
import { GeolocatorService } from '../../services/geolocator.service';
import { Subscription } from 'rxjs';
import { LoadingLayout } from '../../services/loadingLayout.service';
import { IConfig } from '../../interfaces/config.interface';
import { FormInfoService } from '../../services/form-info.service';

@Component({
    selector: 'dt-geoLocatorComponent',
    templateUrl: './geoLocator.component.html',
    styleUrls: ['./geoLocator.component.scss']
})
export class GeoLocatorComponent implements OnInit, OnDestroy {
    defaultLabel: string;
    updateLabel: string;
    geoLocatorLabel: string;
    currentPosition: GeolocationPosition;
    cityStateLocation: string;
    private locationChangeSubscription: Subscription;
    @Input() fromHomepage: boolean;

    constructor(
        @Inject('config') public SitecoreConfig: IConfig,
        public geolocatorService: GeolocatorService,
        private LoadingLayoutService: LoadingLayout,
        private formInfoService: FormInfoService
        ) {
        this.defaultLabel = SitecoreConfig.Sitecore.FindStoreConfig.FindStoreForm.CtaCurrentLocation.Text;
        this.updateLabel = SitecoreConfig.Sitecore.FindStoreConfig.FindStoreForm.CtaUpdateCurrentLocation.Text;
    }

    ngOnInit() {
        this.locationChangeSubscription = this.geolocatorService.locationChangeEmitted$
        .subscribe(() => {
            this.geoLocatorLabel = this.defaultLabel;
            this.currentPosition = null;
        });
    }

    getCurrentPosition() {
         /* istanbul ignore next */
        this.LoadingLayoutService.showLoader();
        this.geolocatorService.getCurrentPosition(this.onGeoLocatorSuccess.bind(this), this.onGeoLocatorError.bind(this));
        this.LoadingLayoutService.hideLoader();
    }
    onGeoLocatorSuccess(position: GeolocationPosition) {
        this.geoLocatorLabel = this.updateLabel;
        this.currentPosition = position;
        this.geolocatorService.convertPosition(position)
            .subscribe(locationData => {
                this.geolocatorService.emitLocation(locationData);
                this.LoadingLayoutService.hideLoader();
            },
                () => {
                    const siteCoreLocationUser = this.SitecoreConfig.Sitecore.ZipCodeData;
                    this.geoLocatorLabel = this.defaultLabel;
                    this.geolocatorService.emitLocation(siteCoreLocationUser);
                    this.LoadingLayoutService.hideLoader();
                });
    }

    onGeoLocatorError(positionError: GeolocationPositionError) {
        const siteCoreLocationUser = this.SitecoreConfig.Sitecore.ZipCodeData;
        this.geoLocatorLabel = this.defaultLabel;
        this.geolocatorService.emitLocation(siteCoreLocationUser);
        this.LoadingLayoutService.hideLoader();
    }

    openGeoLocator(e: Event) {
          /* istanbul ignore next */
          e.preventDefault();
          this.getCurrentPosition();
          this.formInfoService.reportCurrentLocationClick(this.fromHomepage);
    }

    ngOnDestroy() {
        this.locationChangeSubscription.unsubscribe();
    }
}
