import { Component, OnInit, Inject } from '@angular/core';
import { IConfig } from '../../interfaces/config.interface';
import { FindStoreConfig } from '../../interfaces/findstore.interface';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'dt-modalComponent',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

    FindStoreConfigData: FindStoreConfig;

    constructor(
        @Inject('config') public SitecoreConfig: IConfig,
        // eslint-disable-next-line
        @Inject(MAT_DIALOG_DATA) public data
    ) { }

    ngOnInit() {
        this.FindStoreConfigData = this.SitecoreConfig.Sitecore.FindStoreConfig;
    }
}
