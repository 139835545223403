<style>
  .fas-landing-form {
    top: -470px;
    width: 482px;
    height: 425px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    position: absolute;
  }

  .content-form {
    padding-left: 0px!important;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .fas-landing-form {
      margin-left: -220px;
    }

    .fas-form{
      width: 665px;
      height: 218px;
    }
  }

  .content-landing-form {
    padding-top: 130px;
    padding-right: 150px;
  }

  @media screen and (max-width: 600px) {
    .content-landing-form {
      padding-right: 0px !important;
    }

    .srp-bootstrap .col-sm-8 {
      max-width: 100% !important;
    }

    .srp-bootstrap .offset-sm-2 {
      margin-left: 0% !important;
    }
  }

  .location-landing-input {
    width: 350px;
    margin-bottom: 30px;
  }

  .make-model-landing-select {
    width: 350px;
    margin-bottom: 30px;
  }

  .nostore-feedback {
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #dc3545;
  }

  .fas-landform-title {
    top: 40px;
    width: 350px;
    text-align: center;
    letter-spacing: 0px;
    color: #093454;
    opacity: 1;
    position: absolute;
    font-size: 28px;
    font-weight: bold;
  }

  .fas-landform-subtitle {
    font-size: 14px;
    font-weight: 200;
    margin-top: 10px;
    display: none;
  }

  .fas-landing-form .large-cta {
    width: 350px;
  }

  .fas-form-title {
    display: none;
  }

  .input-nostore-location {
    border: solid 2px #e61464 !important;
  }

  .input-store-location {
    border: 1px solid #d5d5d5;
  }

  .mid-section-fasform {
    top: 507px;
    left: 539px;
    width: 350px;
    height: 20px;
    text-align: left;
    font: normal normal bold 14px/16px Arial, Helvetica, sans-serif;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-bottom: 16px;
    margin-top: -9px;
  }

  @media (max-width: 600px) {
    .fas-landing-form {
      top: -97px;
      left: 0px;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border-radius: 10px;
      opacity: 1;
      position: relative;
      box-shadow: 0px 3px 12px #00000026;
    }

    .fas-landform-title {
      left: 30px;
      width: 285px;
      height: 44px;
      text-align: center;
      letter-spacing: 0px;
      color: #093454;
      opacity: 1;
    }

    .location-landing-input {
      width: 285px;
      margin-bottom: 30px;
      margin-left: 0px;
    }

    .make-model-landing-select {
      width: 285px;
      margin-bottom: 30px;
      margin-left: 0px;
    }

    .seotext-component {
      margin-top: 305px;
    }

    .fas-landing-form .large-cta {
      width: 285px;
      margin-left: 0px;
    }

    .fas-form-title {
      display: none;
    }

    .fas-landing-loclabel {
      width: 101px;
    }

    .mid-section-fasform {
      width: unset;
    }
  }

  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    .fas-landing-form {
      left: 18%;
      margin-top: 2%;
    }

    .location-landing-input {
      margin-left: -17%;
    }

    .fas-landform-title {
      left: -3%;
    }

    .make-model-landing-select {
      margin-left: -17%;
    }

    .content-landing-form .mid-section-fasform{
      margin-left: -17%;
    } */

    .fas-landing-form .large-cta {
      margin-left: -17%;
    }
  }

  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    .fas-landing-form {
      left: 27%;
    }

    .fas-landform-title {
      left: -3%;
    }

    .location-landing-input {
      margin-left: -17%;
    }

    .make-model-landing-select {
      margin-left: -17%;
    }

    .content-landing-form .mid-section-fasform {
         margin-left: -17%;
    }

    
    .fas-landing-form .large-cta {
      width: 350px;
      margin-left: -17%;
    }
  }

  @media only screen and (min-device-width : 1024px) and (orientation : portrait) {
    .fas-landing-form {
      left: 24%;
      margin-top: 0%;
    }
  }

  @media screen and (min-width: 370px) and (max-width: 812px) {
    .fas-form .large-cta {
      width: 260px !important;
      margin-left: 0px !important;
    }
  }

  .mid-section-fasform .fas-landing-checkboxs {
    width: 13px;
    height: 13px;
    background: #0A3453 0% 0% no-repeat padding-box;
    border-radius: 3px;
    opacity: 1;
    top: 0px !important;
  }

  .label-interest {
    width: 120px;
    height: 16px;
    text-align: left;
    font: normal normal bold 14px/16px Arial, Helvetica, sans-serif;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }

  .label-Sales {
    width: 49px;
    height: 16px;
    text-align: left;
    font: normal normal bold 14px/16px Arial, Helvetica, sans-serif;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-left: 2px;
  }

  .label-Service {
    width: 62px;
    height: 16px;
    text-align: left;
    font: normal normal bold 14px/16px Arial, Helvetica, sans-serif;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-left: 2px;
  }

  .label-Collision {
    width: 59px;
    height: 16px;
    text-align: left;
    font: normal normal bold 14px/16px Arial, Helvetica, sans-serif;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-left: 2px;
  }

  .mid-section-fasform label {
    margin-right: 0px;
  }

  .fas-checkboxs-labels {
    width: 18px;
    height: 20px;
  }

  .mid-section-fasform .checkbox-fas-checked {
    color: #FFFFFF;
    background-color: #0A3453;
  }

  .checkbox-section-fas {
    margin-top: -23px;
    margin-left: 118px;
  }

  @media (max-width: 600px) {
    .checkbox-section-fas {
      margin-top: 10px;
      margin-left: 0px;
    }

    .label-interest {
      height: 25px;
    }

    .mid-section-fasform {
      height: 50px;
    }

    .label-Sales {
      width: 60px;
    }

    .label-Service {
      width: 75px;
    }

    .fas-landing-form .geekmark {
      position: absolute;
      width: 14px;
      height: 14px;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border-radius: 3px;
      border: 2px solid;
      border-color: #0A3453;
      opacity: 1;
      top: 116px !important;
      margin-left: -12px;
    }
  }

  .fas-form .mid-section-fasform {
    height: 50px;
  }

  .fas-form .checkbox-section-fas {
    margin-top: 0px;
    margin-left: 0px;
  }

  .fas-form .label-interest {
    height: 25px;
  }

  .fas-form .label-Sales {
    width: 60px;
  }

  .fas-form .label-Service {
    width: 75px;
  }

  input[type=checkbox] {
    visibility: hidden;
  }
  .fas-landing-form .geekmark {
    position: absolute;
    width: 14px;
    height: 14px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 3px;
    border: 2px solid;
    border-color: #0A3453;
    opacity: 1;
    margin-left: -12px;
  }

  .fas-form .geekmark {
    position: absolute;
    width: 14px;
    height: 14px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 2px;
    border: 2px solid;
    border-color: #0A3453;
    opacity: 1;
    margin-left: -12px;
  }

  .mid-section-fasform:hover input ~ .geekmark {
    border-color: #0A3453;
  }

  .mid-section-fasform input:checked ~ .geekmark {
    background-color: #0A3453;
    border-color: #0A3453
  }

  .geekmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .mid-section-fasform input:checked ~ .geekmark:after {
    display: block;
  }

  .mid-section-fasform .geekmark:after {
    left: 3px;
    bottom: 2px;
    width: 5px;
    height: 9px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  @media (max-width: 600px) {
    .fas-landing-form .geekmark {
      position: absolute;
      width: 14px;
      height: 14px;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border-radius: 3px;
      border: 2px solid;
      border-color: #0A3453;
      opacity: 1;
      top: 116px !important;
      margin-left: -12px;
    }
  }

  @media (max-width: 320px) {
    .label-Sales, .label-Service {
      width: auto !important;
      margin-right: 2px !important;
    }
  }
  input:focus, mat-select:focus, input:hover, mat-select:hover {
    box-shadow: none;
    /*border: 1px solid #000 !important;*/
    outline: 2px solid black;
  }
</style>

<div class="d-flex justify-content-center h-100">
  <div [ngClass]="IslandingPage ?'fas-landing-form':'fas-form'">
    <div [ngClass]="getClass()">
      <div [ngClass]="IslandingPage ?'fas-landform-title':'fas-form-title'">
        <h2>{{FindStoreConfigData.FindStoreForm.HeaderInputs.FasTitleText}}</h2>
        <div [ngClass]="IslandingPage ?'fas-landform-subtitle':'fas-form-subtitle'"></div>
      </div>
      <div class="row no-gutters">
        <div class="col-1 col-md-1 col-lg-2"
             [ngClass]="{'no-display': secondaryView  && (screenSize === 'lg' || screenSize === 'xl')}"></div>
        <div class="col-10 col-sm-12 col-md-12 col-lg-12 col-xl-8">

          <form [formGroup]="fasForm" (ngSubmit)="onSubmit($event, fasForm.value)">
            <div [ngClass]="{'flex-display': secondaryView && (screenSize === 'lg' || screenSize === 'xl')}">
              <div [ngClass]="{'col-md-12 col-lg-12 col-xl-10 p-0': secondaryView}">
                <div [ngClass]="IslandingPage ?'form-group location-landing-input':'form-group location-input'">
                  <div class="row justify-content-between no-gutters">
                    <div class="col-5">
                      <label [ngClass]="IslandingPage ?'fas-landing-loclabel':'fas-loclabel'" for="location">{{FindStoreConfigData.FindStoreForm.LocationInput.Label}}</label>
                    </div>
                    <div class="col-7 text-right">
                      <dt-geoLocatorComponent tabindex="0" #geoLocator [fromHomepage]="fromHomepage"></dt-geoLocatorComponent>
                    </div>
                    <mat-form-field>
                      <input [ngClass]="totalStoreCount === 0 ?'input-nostore-location':'input-store-location'" matInput #location [matAutocomplete]="locationAutocomplete"
                             [formControl]="locationControl"
                             class="form-control"
                             pattern="^[^\s].+[0-9, a-zA-Z]+$"
                             type="text"
                             id="location"
                             (focus)="locationFocus()"
                             [placeholder]="FindStoreConfigData.FindStoreForm.LocationInput.Placeholder" aria-required="true" required
                             aria-label="Start typing, then use the up and down arrows to select a location from the list"/>
                      <mat-error class="invalid-feedback" *ngIf="!locationControl.valid">{{FindStoreConfigData.FindStoreForm.LocationInput.ErrorMessage}}</mat-error>
                    </mat-form-field>
                    <mat-autocomplete class="location-panel" #locationAutocomplete="matAutocomplete">
                      <mat-option *ngFor="let option of (locationOptions | searchFilter: locationControl.value : 'name')" [value]="option.value" [innerHTML]="option.name">
                      </mat-option>
                    </mat-autocomplete>
                  </div>
                </div>
                <fieldset>
                <div class="mid-section-fasform">
                  <div class="interest-section-fas">
                    <legend class="label-interest">
                      {{FindStoreConfigData.FindStoreForm.BaseSearchSection.LabelInterest}}
                    </legend>
                  </div>
                  <div class="checkbox-section-fas">
                    <label class="fas-checkboxs-labels" tabindex="0">
                      <input type="checkbox" id="sale" tabindex="-1" [ngClass]="salesChecked ?'checkbox-fas-checked':'fas-form'" class="fas-landing-checkboxs" value="Sales" [attr.checked]="salesChecked ? 'checked' : null" (change)="CheckboxStatus($event)" />
                      <span class="geekmark" tabindex="-1"></span>
                    </label>
                    <label class="label-Sales" tabindex="-1" for="sale">{{FindStoreConfigData.FindStoreForm.BaseSearchSection.LabelSales}}</label>
                    <label class="fas-checkboxs-labels" tabindex="0">
                      <input type="checkbox" tabindex="-1" id="service" class="fas-landing-checkboxs" value="Service" [attr.checked]="serviceChecked ? 'checked' : null" (change)="CheckboxStatus($event)" />
                      <span class="geekmark" tabindex="-1"></span>
                    </label>
                    <label class="label-Service" tabindex="-1" for="service">{{FindStoreConfigData.FindStoreForm.BaseSearchSection.LabelService}}</label>
                    <label class="fas-checkboxs-labels" tabindex="0">
                      <input type="checkbox" tabindex="-1" id="collision" class="fas-landing-checkboxs" value="Collision" [attr.checked]="collisionChecked ? 'checked' : null" (change)="CheckboxStatus($event)" />
                      <span class="geekmark" tabindex="-1"></span>
                    </label>
                    <label class="label-Collision" for="collision">{{FindStoreConfigData.FindStoreForm.BaseSearchSection.LabelCollision}}</label>
                  </div>
                </div>
              </fieldset>
                <div [ngClass]="IslandingPage ?'form-group make-model-landing-select':'form-group make-model-select'">
                  <mat-form-field class="makes-dropdown">
                    <mat-select [formControl]="makesControl"
                                multiple
                                panelClass="make-panel"
                                (openedChange)="openedMakeDropdownChange($event)"
                                [ngClass]="totalStoreCount === 0 ?'input-nostore-location':'input-store-location'"
                                placeholder="{{FindStoreConfigData.FindStoreForm.MakesInput.Placeholder}}">
                      <mat-option class="main-label hidden" [value]="allMakesOption.Value">{{allMakesOption.Description}}</mat-option>
                      <ng-container *ngFor="let department of departments">
                        <mat-option class="main-label" [value]="department.Value" (onSelectionChange)="toggleAllSelection($event, department.Value)">{{department.Description}}</mat-option>
                      </ng-container>
                      <mat-optgroup *ngFor="let group of makesGroup" [disabled]="group.disabled">
                        <mat-option *ngFor="let make of group.makes" [value]="make.Value" (onSelectionChange)="toggleAllSelection($event, make.Value)">
                          {{make.Value}}
                        </mat-option>
                      </mat-optgroup>
                    </mat-select>
                    <mat-error class="invalid-feedback" *ngIf="makesControl.hasError('required')">{{FindStoreConfigData.FindStoreForm.MakesInput.ErrorMessage}}</mat-error>
                  </mat-form-field>
                  <div class="nostore-feedback" *ngIf="totalStoreCount === 0">{{FindStoreConfigData.FindStoreForm.HeaderInputs.NoStoreError}}</div>
                </div>
              </div>
              <div tabindex="0" class="form-group text-center"
                   [ngClass]="{'fas-cta': secondaryView}">
                <button [disabled]="fasForm.invalid" [ngClass]="{'primary-cta large-cta': !secondaryView, 'secondary-cta pink fas': secondaryView }" type="submit">{{FindStoreConfigData.FindStoreForm.CtaFindStore.Text}}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
