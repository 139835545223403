import { Component, OnInit, Inject, Input, Output, EventEmitter, isDevMode } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { IConfig } from '../../interfaces/config.interface';
import { ITabClicked } from '../../interfaces/findstore.interface';
import { IStoreSearchResult } from '../../interfaces/search.interface';
import { FormInfoService } from '../../services/form-info.service';
import { UDO } from '../../../global';
import { AnModalService } from '@autonation/dt-an-components-lib/an-modal';

@Component({
  selector: 'dt-cta-tab-group',
  templateUrl: './ctaTabGroup.component.html',
  styleUrls: ['./ctaTabGroup.component.scss']
})
export class CtaTabGroupComponent implements OnInit {
  @Input() store: IStoreSearchResult;
  @Input() fromResults: boolean;
  @Input() tileId: number;
  @Input() mapContainer: string;
  @Output() isAppointmentTabClicked = new EventEmitter<ITabClicked>();
  selectedTabIndex: number;
  tabIndexService = 0;
  tabIndexSales = 1;
  tabIndexCollision = 2;
  isAppointmentModalDisplayed = false;
  isSalesAppointmentEnabled = true;
  isHeadless = false;

  constructor(
    @Inject('config') public SitecoreConfig: IConfig,
    private readonly formInfoService: FormInfoService,
    private readonly modalService: AnModalService
  ) {
    this.isHeadless = this.SitecoreConfig.Sitecore.isHeadless;
  }

  ngOnInit() {
      this.configureCTA();
  }

  configureCTA() {
    const isSalesAppointmentEnabled = this.store.IsSalesAppointmentEnabled;
    this.store.Department.forEach(s => {
        if (s.DepartmentType === 'Sales') {
          if (isSalesAppointmentEnabled) {
            s.class = 'store-cta primary-cta';
            s.id = 'vdp-Cta-Vin-href';
            s.link = 'javascript:void(0)';
          } else {
            s.class = 'store-cta primary-cta appointment-modal-link-disabled';
            s.id = 'vdp-Cta-Vin-href';
            s.link = 'javascript:void(0)';
          }
        }

        if (s.DepartmentType === 'Service') {
            s.class = 'store-cta primary-cta';
            s.id = 'service-appt';
            s.link = `${this.isHeadless ? '/' : ''}make-appointment?hid=${this.store.StoreId}`;
        }

        if (s.DepartmentType === 'Collision') {
            s.class = 'store-cta primary-cta';
            s.id = 'collision-appt';
            s.link = this.store.StoreCollisionUrl;
        }
    });
  }

  setHours(detailedHours: any[any]): string {
    const dateNow = new Date(Date.now());
    let today = dateNow.getDay();
    today = today - 1 >= 0 ? today - 1 : 6;
    const storeHours = detailedHours[today];
    let hoursDetail = `${storeHours.StartTime} - ${storeHours.EndTime}`;
    let hoursLabel = `<b>${this.SitecoreConfig.Sitecore.FindStoreConfig.ResultsPage.StoreOpenLabel.Text}:</b>`;
    const storeHourStart = this.getStoreDateTime(storeHours.StartTime);
    const storeHourEnd = this.getStoreDateTime(storeHours.EndTime);
    if (dateNow.getTime() < storeHourStart.getTime() ||
        dateNow.getTime() >= storeHourEnd.getTime()) {
        hoursDetail = '';
        hoursLabel = `<b>${this.SitecoreConfig.Sitecore.FindStoreConfig.ResultsPage.StoreCloseLabel.Text}</b>`;
    }
    return `${hoursLabel} ${hoursDetail}`;
}

getStoreDateTime(storeHourTime: string): Date {
    const dateNow = new Date(Date.now());
    const [time, modifier] = storeHourTime.split(' ');
    const [hoursStr, minutesStr] = time.split(':');
    const minutes = parseInt(minutesStr, 10);
    let hours = parseInt(hoursStr, 10);
    if (hoursStr === '12') {
        hours = 0;
    }
    if (modifier === 'PM') {
        hours += 12;
    }
    const storeDateTime = new Date(dateNow);
    storeDateTime.setHours(hours, minutes);
    return storeDateTime;
}

setCTA(departmentType: string, getADA: boolean = false): string {
    let ctaLabel = 'Schedule Appointment';
    switch (departmentType) {
    case'Sales':
        ctaLabel = getADA ?
                   this.SitecoreConfig.Sitecore.FindStoreConfig.ResultsPage.SalesCta.AdaText :
                   this.SitecoreConfig.Sitecore.FindStoreConfig.ResultsPage.SalesCta.Text;
        break;
    case'Service':
        ctaLabel = getADA ?
                   this.SitecoreConfig.Sitecore.FindStoreConfig.ResultsPage.ServiceCta.AdaText :
                   this.SitecoreConfig.Sitecore.FindStoreConfig.ResultsPage.ServiceCta.Text;
        break;
    case'Collision':
        ctaLabel = getADA ?
                   this.SitecoreConfig.Sitecore.FindStoreConfig.ResultsPage.CollisionCta.AdaText :
                   this.SitecoreConfig.Sitecore.FindStoreConfig.ResultsPage.CollisionCta.Text;
        break;
    }
    return ctaLabel;
}

openCTA(departmentType: string) {
    switch (departmentType) {
    case'Sales':
        this.formInfoService.reportScheduleSalesClick(this.fromResults);
        break;
    case'Service':
        this.formInfoService.reportScheduleServiceClick(this.fromResults);
        break;
    case'Collision':
        this.formInfoService.reportScheduleCollisonClick(this.fromResults);
        break;
    }
}

/* istanbul ignore next */
openLink(lnk: string, deptType: any, e: Event, hid: string) {
    // this.isAppointmentModalDisplayed = (deptType === 'Sales');
    if(deptType === 'Sales') {
      this.isAppointmentModalDisplayed = true;
      this.openAppointmenModal(hid);
    } else {
      this.isAppointmentModalDisplayed = false;
    }

    /* istanbul ignore else */
    if (deptType === 'Service' || deptType === 'Collision') {
      e.stopPropagation();
      window.location.href = lnk;
    }
  }


processTealiumDepartmentChanged(selectedDepartmentIndex: any) {
  switch (selectedDepartmentIndex) {
    case this.tabIndexService:
      this.formInfoService.reportFASDepartmentChanged(UDO.ResultspageDeptToggleEventLabelService, UDO.ResultspageDeptToggleEventService);
      break;
    case this.tabIndexSales:
      this.formInfoService.reportFASDepartmentChanged(UDO.ResultspageDeptToggleEventLabelSales, UDO.ResultspageDeptToggleEventSales);
      break;
    case this.tabIndexCollision:
      // eslint-disable-next-line max-len
      this.formInfoService.reportFASDepartmentChanged(UDO.ResultspageDeptToggleEventLabelCollision, UDO.ResultspageDeptToggleEventCollision);
      break;
  }
}

  onTabChange(event: MatTabChangeEvent) {
    this.isAppointmentTabClicked.emit({ isTabClicked: true, tileId: this.tileId, mapContainer: this.mapContainer, storeDetails: this.store });
    // Event bubling causes tabchange to be executed after clicking on appointent CTA
    /* istanbul ignore next */
    if (!this.isAppointmentModalDisplayed) {
      this.processTealiumDepartmentChanged(this.store.SelectedDepartmentIndex);
    }
    this.isAppointmentModalDisplayed = false;
  }

  openAppointmenModal(hid: string): void {
    let apptUrl = `/appointment?hid=${hid}&iframe=true&ismodal=true&src=find_store`;
    const src = isDevMode() ? "https://www6.qaautonation.com" + apptUrl : apptUrl;

    this.modalService.openAppointmentModalV2(src, {maxWidth: '1320px'}).subscribe(data => {
      if(data?.confirm) {
        location.reload();
      }
    });
  }
}
