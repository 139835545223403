<div class="section fas-homepage">
    <div class="row">
        <div class="col-12">
            <dt-bannerTopComponent></dt-bannerTopComponent>
        </div>
        <div class="col-12">
            <dt-findStoreFormComponent [fromHomepage]="true"></dt-findStoreFormComponent>
        </div>
        <div class="col-12" *ngIf="!isIframe">
            <dt-seoTextComponent [dataComponent]="seoComponentData"></dt-seoTextComponent>
        </div>
    </div>
</div>

