import { Component, OnInit, Inject, ViewChild, HostListener } from '@angular/core';
import { IConfig } from '../../interfaces/config.interface';
import { FindStoreConfig, ISearchCriteria, ITabClicked, ResultsPage } from '../../interfaces/findstore.interface';
import { LoadingLayout } from '../../services/loadingLayout.service';
import { ActivatedRoute } from '@angular/router';
import { SearchState } from '../../services/searchState.service';
import { SearchCriteriaType } from '../../interfaces/findstore.interface';
import { IZipCodeData } from '../../interfaces/IZipCode.interface';
import { IStoreSearchResult, IStoreSearchResponse, IStoreMaps } from '../../interfaces/search.interface';
import { DecodeURL, updateHeader } from '../../utils/utils.functions';
import { FindStorePubSub } from '../../services/findstorepubsub.service';
import { SubheaderComponent } from '../subheader/subheader.component';
import { Observable } from 'rxjs';
import { BreakpointService } from '../../services/breakpoint.service';
import { FormInfoService } from '../../services/form-info.service';
import { FormatCarSalesUrl } from '../../pipes/formatCarSalesUrl.pipe';
import { StoreDetails } from '../../interfaces/storeDetails.interface';
import { GeolocatorService } from '../../services/geolocator.service';
import { browserRefresh } from './../../app.component';
import { getCookie } from '../../utils/utils.functions';

@Component({
    selector: 'dt-resultspage',
    templateUrl: './resultspage.component.html',
    styleUrls: ['./resultspage.component.scss'],
    providers: [FormatCarSalesUrl]
})
export class ResultsPageComponent implements OnInit {

    isHeadless = false;
    FindStoreConfigData: FindStoreConfig;
    searchParam: any;
    zipCodePreferred: IZipCodeData;
    search: ISearchCriteria;
    stores: IStoreSearchResult[] = [];
    highlightedStore: IStoreSearchResult;
    storeTotalCount: number;
    allMakesAndStoresValue: string;
    allMakesAndStoresCaption: string;
    selectedDepartmentIndex = 0;
    listView = false;
    seoComponentData: {};
    screenSize = '';
    isAzureMapsEnabled = false;
    highlightedTile: number;
    sticky = false;
    footerVisible = false;
    resultsPageData: ResultsPage;
    scroll: number;
    @ViewChild('subheader') subheader: SubheaderComponent;
    appointmentTabClicked: boolean;
    showLoadMore = true;
    brandLogoUrl = '/~/media/images/autonation/brands/';
    showListViewToggle = true;
    currentZipCodeFromCityState: string;
    isIframe = false;
    dataCollection: any;
    isFirstLoad = true;
    getTypedZipCodeStatus: any;
    getTypedZipCodeValue: any;
    closeNoResultElement = false;
    isNationwideSearch = false;
    resultfas = 'resultfas-clicked';
    mapdesktop = '.map-desktop';
    srpBootstrap = 'srp-bootstrap';


    constructor(
        @Inject('config') public SitecoreConfig: IConfig,
        private readonly LoadingLayoutService: LoadingLayout,
        private readonly FindStorePubSubService: FindStorePubSub,
        private readonly route: ActivatedRoute,
        private readonly SearchStateService: SearchState,
        private readonly breakpointService: BreakpointService,
        public formInfoService: FormInfoService,
        private readonly formatCarSalesUrl: FormatCarSalesUrl,
        private readonly geoLocatorService: GeolocatorService
    ) {
        this.FindStoreConfigData = this.SitecoreConfig.Sitecore.FindStoreConfig;
        this.zipCodePreferred = this.SitecoreConfig.Sitecore.ZipCodeData;
        this.searchParam = this.route.snapshot.queryParams.search;
        this.allMakesAndStoresCaption = this.FindStoreConfigData.FindStoreForm.DepartmentTypes.AllCaption;
        this.allMakesAndStoresValue = this.FindStoreConfigData.FindStoreForm.DepartmentTypes.AllValue;
        this.seoComponentData = this.SitecoreConfig.Sitecore.FindStoreConfig.SeoPageDescription;
        this.isAzureMapsEnabled = this.SitecoreConfig.Sitecore.isAzureMapsEnabled;
        this.resultsPageData = this.SitecoreConfig.Sitecore.FindStoreConfig.ResultsPage;
        this.showListViewToggle = this.resultsPageData.IsListView;
        this.isHeadless = this.SitecoreConfig.Sitecore.isHeadless;
    }

    ngOnInit() {
        /* istanbul ignore next */
        this.setConfigs();
        this.setSearchCriteria();

        // Publish Updates to other componenents
        // on page reload
        /* istanbul ignore next */
        if (browserRefresh) {
            this.SearchStateService.broadcastSearchCriteria(this.search);
        }
        /* istanbul ignore else */
        if (this.isFirstLoad) {
            this.SearchStateService.searchCriteriaBroadcasted$.subscribe(data => {
                this.isNationwideSearch = false;
                this.closeNoResultElement = true;
                /* istanbul ignore if */
                if (this.subheader && this.subheader.modalDialog) {
                    this.subheader.modalDialog.close();
                }
                this.populateDataCollector(data);
                /* istanbul ignore next */
                if (sessionStorage.getItem('typedZipCode') !== null) {
                    this.getTypedZipCode();
                }



                /* istanbul ignore next */
                if ((this.isFirstLoad || sessionStorage.getItem(this.resultfas) === 'true')) {
                    if (!isNaN(this.dataCollection.Criteria.City)) {
                        const zipCodeData: any = {
                            PostalCode: this.dataCollection.Criteria.City,
                            StateCode: '',
                            City: '',
                            Latitude: 0,
                            Longitude: 0,
                            ValidPostalCode: false
                        };
                        this.LoadingLayoutService.showLoader();                       
                        this.geoLocatorService.updateUserZipCode(zipCodeData).subscribe(
                            (res: any) => {
                                if (res && res.Success && res.ZipCodeData) {
                                    const resSessionvalue = `${res.ZipCodeData.PostalCode}|
                                ${res.ZipCodeData.Latitude}|
                                ${res.ZipCodeData.Longitude}`;
                                    sessionStorage.setItem('faszip', resSessionvalue);
                                }
                            }
                        );
                    }
                    this.updateLocationInDataColllection();
                    if (browserRefresh) {
                        updateHeader();
                    }
                    setTimeout(() => {
                        this.submit();
                    }, 3000);
                }
            });
        }
        this.selectTile(0);
        this.removeTabIndex();
    }

    populateDataCollector(data: ISearchCriteria) {
        this.dataCollection = data;
        if ((data.Criteria.Location === null) ||  (typeof data.Criteria.Location === 'string' && data.Criteria.Location === '')) {
            this.dataCollection.Criteria.Location = {
                PostalCode: '',
                StateCode: '',
                City: '',
                Latitude: 0,
                Longitude: 0,
                ValidPostalCode: true
            };
        }
    }

    updateLocationInDataColllection() {
        /* istanbul ignore next */
        // if location is empty take from zipCodePreferred or cookie
        if (this.dataCollection.Criteria.Location === null || this.dataCollection.Criteria.Location.City === undefined || this.dataCollection.Criteria.Location.City === '') {
            if (this.zipCodePreferred && this.zipCodePreferred.City !== undefined) {
                this.setPreferredLocation();
            } else {
                const ipZip = getCookie('IpZipCode');
                if (ipZip !== '' && ipZip !== null) {
                    this.getZipCodeData(ipZip);
                }
            }
        }
    }

    getDeparmentDescList(departments: string[]): string[] {
        const listDepartment = [];
        /* istanbul ignore else */
        if (departments && departments.length > 0 && departments.indexOf('R') > -1) {
            listDepartment.push('Sales');
        }
        /* istanbul ignore else */
        if (departments && departments.length > 0 && departments.indexOf('S') > -1) {
            listDepartment.push('Service');
        }
        /* istanbul ignore else */
        if (departments && departments.length > 0 && departments.indexOf('C') > -1) {
            listDepartment.push('Collision');
        }
        return listDepartment;
    }

    /* istanbul ignore next */
    processTealiumnSubmit(storeData: IStoreSearchResponse) {
        /* istanbul ignore else */
        if (!this.isFirstLoad) {
            // eslint-disable-next-line max-len
            this.formInfoService.reportFASFormSubmitStart(false, this.dataCollection.Criteria.makes, this.getDeparmentDescList(this.dataCollection.Criteria.Departments), this.dataCollection.Criteria.City, storeData.StoreTotalCount, storeData.NationwideSearch);
        }
    }

    /* istanbul ignore next */
    submit() {
        this.updateDataCollectorWithFasZip();
        this.FindStorePubSubService.submitForm(this.dataCollection, this.FindStoreConfigData,this.isHeadless).subscribe(
            (storeData: IStoreSearchResponse) => {
                if (this.isFirstLoad) {
                    // Tealium Load Event
                    // eslint-disable-next-line max-len
                    this.formInfoService.reportFASResultsLoad(storeData.StoreTotalCount, this.FindStoreConfigData.TilesPerPage, this.search.Criteria, this.getDeparmentDescList(this.search.Criteria.Departments), storeData.NationwideSearch);
                }
                this.processTealiumnSubmit(storeData);
                this.isFirstLoad = false;
                sessionStorage.setItem(this.resultfas, 'false');
                this.search.Criteria.Departments = this.dataCollection.Criteria.Departments;
                this.search.Criteria.CurrentPage = this.dataCollection.Criteria.CurrentPage;
                this.setStoreData(storeData).subscribe(() => {
                    this.LoadingLayoutService.hideLoader();
                    this.showLoadMoreCta();
                });
            },
            error => {
                console.log(error);
                this.LoadingLayoutService.hideLoader();
            }
        );
    }

    /* istanbul ignore next */
    updateDataCollectorWithFasZip() {
        /* istanbul ignore else */
        if (sessionStorage.getItem('faszip')) {
            const fasZip = sessionStorage.getItem('faszip').split('|');
            /* istanbul ignore else */
            if (fasZip.length === 3) {
                this.dataCollection.Criteria.Location.PostalCode = fasZip[0];
                this.dataCollection.Criteria.Location.Latitude = fasZip[1];
                this.dataCollection.Criteria.Location.Longitude = fasZip[2];
            }
        }
    }

    setSearchCriteria() {
        const CurrentPage = this.search?.Criteria?.CurrentPage;
        if (this.searchParam) {
            this.search = {
                Criteria: DecodeURL(this.searchParam),
                Type: SearchCriteriaType.initilizeSearch
            };
        } else {
            this.search = {
                Criteria: {
                    Location: this.zipCodePreferred,
                    makes: [],
                    Departments: [this.allMakesAndStoresValue]
                },
                Type: SearchCriteriaType.initilizeSearch
            };
        }

        /* istanbul ignore if */
        if (!this.search?.Criteria?.Location?.Latitude) {
            this.search.Criteria.Location = this.zipCodePreferred;
        }

        /* istanbul ignore if */
        if(this.isFirstLoad) {
            this.search.Criteria.CurrentPage = this.FindStoreConfigData.CurrentPage;
        } else {
            this.search.Criteria.CurrentPage = CurrentPage;
        }
    }

    setConfigs() {
        /* istanbul ignore if */
        if (this.route.data) {
            this.route.data.subscribe(v => this.isIframe = v.isIframe
            );
        }

        this.breakpointService.breakpoint.subscribe(breakpoint => {
            this.screenSize = breakpoint;
            if (this.screenSize !== 'xs' && this.screenSize !== 'sm') {
                this.listView = false;
            }
        });
    }

    /* istanbul ignore next */
    getTypedZipCode() {
        const getTypedZipcode = sessionStorage.getItem('typedZipCode').split('|');
        /* istanbul ignore else */
        if (getTypedZipcode.length === 2) {
            this.getTypedZipCodeStatus = getTypedZipcode[0];
            this.getTypedZipCodeValue = getTypedZipcode[1];
        }
    }

    setPreferredLocation() {
        this.dataCollection.Criteria.Location.City = this.zipCodePreferred.City;
        this.dataCollection.Criteria.Location.State = this.zipCodePreferred.StateCode;
        this.dataCollection.Criteria.City = this.zipCodePreferred.City;
        this.dataCollection.Criteria.State = this.zipCodePreferred.StateCode;
    }

    getZipCodeData(cityZipcode: string) {
        this.geoLocatorService.getZipCodeData(cityZipcode)
            .subscribe(zipCodeData => {
                /* istanbul ignore else */
                if (zipCodeData) {
                    this.dataCollection.Criteria.Location.City = zipCodeData.City;
                    this.dataCollection.Criteria.Location.State = zipCodeData.StateCode;
                    this.dataCollection.Criteria.City = zipCodeData.City;
                    this.dataCollection.Criteria.State = zipCodeData.StateCode;
                }
            });
    }

    showLoadMoreCta() {
        /* istanbul ignore else */
        if (this.search.Criteria.CurrentPage === 1 && this.storeTotalCount > this.FindStoreConfigData.TilesPerPage) {
            this.showLoadMore = true;
            return;
        }
        /* istanbul ignore else */
        if (this.stores.length === this.storeTotalCount) {
            this.showLoadMore = false;
        }
    }

    /* istanbul ignore next */
    getMapContainer() {
        const dcontainer = 'map-desktop';
        const mcontainer = 'map-mobile';
        if (this.screenSize === 'lg' || this.screenSize === 'xl' || this.screenSize === 'md') {
            return dcontainer;
        }

        if (!this.listView || document.getElementById(mcontainer)) {
            return mcontainer;
        } else {
            return '';
        }
    }

    validateZipcode(inputText: string) {
        /* istanbul ignore next */
        const zipcodeRegEx = new RegExp(/(^\d{5}$)/g);
        return zipcodeRegEx.test(inputText);
    }

    onListViewToggle(isListView: boolean) {
        this.listView = isListView;
        if (!this.listView && this.stores.length > 0 && this.isAzureMapsEnabled) {
            this.loadMap('map-mobile', this.stores);
        }
    }

    setStoreData(storeData: IStoreSearchResponse): Observable<void> {
        return new Observable(observer => {
            this.updateStoreData(storeData);
            /* istanbul ignore else */
            this.storeTotalCount = storeData.StoreTotalCount ? storeData.StoreTotalCount : this.stores.length;
            this.isNationwideSearch = storeData.NationwideSearch;
            this.setDepartmentIndex();
            this.stores.map(store => {
                store.Department.sort((a, b) => {
                    a.collisionUrl = store.StoreCollisionUrl;
                    a.storeId = store.StoreId;
                    b.storeId = store.StoreId;
                    if (a.DepartmentType === 'Service') {
                        return -1;
                    }
                    return 1;
                });
                store.SelectedDepartmentIndex = this.selectedDepartmentIndex;
                store.onlyCollisionDepartment = this.onlyCollison(store);
            });

            /* istanbul ignore else */
            if (this.stores[0] && this.stores.length > 0) {
                this.stores[0].PanelOpen = true;
            }
            observer.next();
            observer.complete();
            this.footerVisible = false;
        });
    }

    updateStoreData(storeData: IStoreSearchResponse) {
        if (this.search && this.search.Criteria && this.search.Criteria.CurrentPage && this.search.Criteria.CurrentPage === 1) {
            this.stores = storeData.Store;
        } else {
            this.stores = this.stores.concat(storeData.Store);
            // resetmap height on loadmore
            this.resetMapHeight();
        }
        // Update the map with latest store points.
        /* istanbul ignore else */
        if (this.isAzureMapsEnabled && (this.storeTotalCount > 0 || this.stores.length > 0)) {
            this.loadMap(this.getMapContainer(), this.stores);
        }
    }

    setDepartmentIndex() {
        /* istanbul ignore else */
        if (this.storeTotalCount > 0 || this.stores && this.stores.length > 0) {
            const departmentSearch = this.search.Criteria && this.search.Criteria.Departments ? this.search.Criteria.Departments : null;
            if (departmentSearch && departmentSearch.find((department) => department.toLowerCase().trim() !== 'all')) {
                if (departmentSearch.length >= 2) {
                    this.selectDepartment(departmentSearch);
                } else {
                    this.selectSingleDepartment(departmentSearch);
                }
            } else {
                this.selectedDepartmentIndex = 0;
            }
        }
    }

    selectDepartment(departmentSearch: string[]) {
        // highlight priority - 1. Service - 2. Sales - 3. Collision
        const hasService = departmentSearch.find((department) => department.toLowerCase().trim() === 's');
        const hasSales = departmentSearch.find((department) => department.toLowerCase().trim() === 'r');
        if (hasService) {
            this.selectedDepartmentIndex = 0;
        } else if (hasSales) {
            this.selectedDepartmentIndex = 1;
        } else {
            this.selectedDepartmentIndex = 2;
        }
    }

    selectSingleDepartment(departmentSearch: string[]) {
        if (departmentSearch.find((department) => department.toLowerCase().trim() === 's')) {
            this.selectedDepartmentIndex = 0;
        }
        if (departmentSearch.find((department) => department.toLowerCase().trim() === 'r')) {
            this.selectedDepartmentIndex = 1;
        }
        if (departmentSearch.find((department) => department.toLowerCase().trim() === 'c')) {
            this.selectedDepartmentIndex = 2;
        }
    }

    /* istanbul ignore next */
    openMap(storeDetail: IStoreSearchResult, e: Event) {
        e.stopPropagation();
        this.formInfoService.reportStoreAddressClick(true);
        /* eslint-disable-next-line max-len */
        let addressQuery = `${storeDetail.Name}+${storeDetail.AddressLine1}+${storeDetail.AddressLine2}+${storeDetail.City}+${storeDetail.StateCode}+${storeDetail.PostalCode}`;
        addressQuery = encodeURI(addressQuery);
        window.open(`https://www.bing.com/maps?rtp=adr.~adr.${addressQuery}`);
    }

    openPhoneApp(num: number, e: Event) {
        e.stopPropagation();
        this.formInfoService.reportToCallClick(`${num}`, true);
        window.open(`tel: ${num}`, '_self');
    }

    loadMoreResults() {
        this.searchParam = this.route.snapshot.queryParams.search;
        this.setSearchCriteria();
        this.search.Criteria.CurrentPage++;
        sessionStorage.setItem(this.resultfas, 'true');
        this.SearchStateService.broadcastSearchCriteria(this.search);
    }

    resetMapHeight() {
        const mapDiv = document.querySelector(this.mapdesktop);
        /* istanbul ignore else */
        if (mapDiv !== null && typeof mapDiv !== 'undefined') {
            const ht = window.innerHeight;
            mapDiv.setAttribute('style', 'height: ' + ht.toFixed(2) + 'px !important');
        }
    }

    getCount() {
        if (this.search && this.search.Criteria && this.search.Criteria.CurrentPage) {
            return this.search.Criteria.CurrentPage * this.FindStoreConfigData.TilesPerPage;
        }
        return 0;
    }

    public removeTabIndex() {      
        let srcScript = 
        `
        let mapTabElements = document.getElementsByClassName('popup-content-container');
        let mapMobile = document.getElementById('map-mobile');
        let mapDesktop = document.getElementById('map-desktop');
        var interval = setInterval(function(){
            if (mapMobile !== null) {
                mapMobile.removeAttribute('role');
            }
            if (mapDesktop !== null) {
                mapDesktop.removeAttribute('role');
            }
            if(mapTabElements.length > 0){
                for (let i = 0; i < mapTabElements.length; i++) { 
                    mapTabElements[i].removeAttribute('tabindex');
                }
            }
        }, 2000);
        `
    
        let node = document.createElement('script');
        node.classList.add('mapTabScript');
        node.innerText = srcScript;
        node.type = 'text/javascript';
        node.async = true;        
        if (document.getElementsByClassName('mapTabScript').length <= 0) {
            document.getElementsByTagName('html')[0].appendChild(node);
        }
    }    

    /* utagStoreClick(url: string, e: Event) {
        e.stopPropagation();
        this.formInfoService.reportStoreDetailsClick();
        window.location.href = url;
    } */

    /* istanbul ignore next */
    utagStoreClick(e: Event) {
        e.stopPropagation();
        this.formInfoService.reportStoreDetailsClick();
    }

    selectTile(id: number) {
        this.highlightedTile = id;
    }

    showMap(storeDetail: IStoreSearchResult, cont: string, e: Event) {
        e.stopPropagation();
        /* istanbul ignore else */
        if (this.isAzureMapsEnabled) {
            this.loadMap(cont, this.stores);
        }
    }

    titleOrTileClicked(storeDetail: IStoreSearchResult, cont: string, id: number, isAppointmentTabClicked: boolean) {
        if (this.appointmentTabClicked) {
            // no need to load map when sales/service/collision tab clicked
            this.appointmentTabClicked = false;
            return;
        }
        /* istanbul ignore else */
        if (!isAppointmentTabClicked) {
            this.highlightTileAndloadMap(storeDetail, cont, id);
        }

        /* istanbul ignore next */
        setTimeout(() => {
            this.makeTileDetailsVisible(id);
        }, 500);
    }

    highlightTileAndloadMap(storeDetail: IStoreSearchResult, cont: string, id: number) {
        /* istanbul ignore else */
        if (!this.appointmentTabClicked) {
            this.highlightedTile = id;
            /* istanbul ignore else */
            if (this.isAzureMapsEnabled) {
                this.highlightedStore = storeDetail;
                this.loadMap(cont, this.stores);
            }
        }
    }

    /* istanbul ignore next */
    onTileExpanded(opened: boolean, id: number) {
        setTimeout(() => {
            this.makeTileDetailsVisible(id);
        }, 500);
    }

    makeTileDetailsVisible(id: number) {
        this.removeMargin();
        this.addMargin(id);
    }

    removeMargin() {
        const allTiles = document.querySelectorAll('mat-expansion-panel') as any;
        /* istanbul ignore next */
        if (allTiles.length > 0) {
            // eslint-disable-next-line
            for (let tile of allTiles) {
                tile.style.marginTop = 'unset';
            }
        }
    }

    /* istanbul ignore next */
    addMargin(id: number) {
        const elemId = 'result-tile-' + id;
        const tileElem = document.getElementById(elemId);
        if (tileElem) {
            const elemTop = tileElem && tileElem.getBoundingClientRect().top;
            let marginTop = (this.getMapHeight() - elemTop) + 10;
            if (id >= 0 && marginTop < 0) {
                marginTop = 5;
            }
            // for the bug #310238 and #300358
            if (this.screenSize === 'xs') {
                tileElem.style.marginTop = marginTop + 'px';
            }
            tileElem.style.position = 'relative';
        }
    }

    getMapHeight() {
        // get map-mobile elem height
        const mapElem = document.getElementById('map-mobile');
        const mapHeight = mapElem && mapElem.getBoundingClientRect().height;
        return mapHeight;
    }

    isOneMake(makes: string) {
        return this.getMakesCt(makes) === 1;
    }

    getMakesCt(makes: string) {
        if (makes === '') {
            return 0;
        }

        if (makes.indexOf(',') > 0) {
            return makes.split(',').length;
        }
        return 1;
    }

    getMakesArr(makes: string) {
        return makes.split(',').map(make => make.trim());
    }

    getSrc(make: string) {
        if (make === '') {
            return '';
        }
        return this.brandLogoUrl + make + '.png';
    }

    loadMap(cont: string, stores: IStoreSearchResult[]) {
        /* istanbul ignore else */
        if (stores && stores.length > 0) {
            // Highlight the first store by default.
            /* istanbul ignore if */
            if (this.highlightedTile > 0) {
                this.highlightedStore = stores[this.highlightedTile];
            }
            /* istanbul ignore else */
            if (this.highlightedTile === 0) {
                this.highlightedStore = stores[0];
            }
            const locations: IStoreMaps[] = [];
            stores.forEach(store => {
                if (store && store.Name !== this.highlightedStore.Name) {
                    locations.push({
                        StoreName: store.Name,
                        Latitude: +store.Latitude,
                        Longitude: +store.Longitude,
                        Distance: store.Distance
                    });
                }
            });
            const SelectedStore: IStoreMaps = {
                StoreName: this.highlightedStore.Name,
                Latitude: +this.highlightedStore.Latitude,
                Longitude: +this.highlightedStore.Longitude,
                Distance: this.highlightedStore.Distance
            };
            (window as any)
                .LoadAzureMap({
                    container: cont, Locations: locations, SelectedStore
                });
        }
    }

    /* istanbul ignore next */
    onAppointmentTabClicked(e: ITabClicked) {
        if (this.highlightedTile !== e.tileId) {
            this.highlightTileAndloadMap(e.storeDetails, e.mapContainer, e.tileId);
        } else {
            this.appointmentTabClicked = e.isTabClicked;
        }
    }

    /* istanbul ignore next */
    @HostListener('window:scroll', ['$event'])
    onWindowScroll($event) {
        const current = window.pageYOffset || document.documentElement.scrollTop;
        /* istanbul ignore else */
        if (this.screenSize === 'lg' || this.screenSize === 'xl' || this.screenSize === 'md') {
            this.scrollFunction(current);
        }
        /* istanbul ignore else */
        if (this.screenSize === 'xs') {
            this.removeMargin();
            this.scrollMobileFunction(current);
        }
    }

    scrollFunction(current: number) {
        const titleContainer = '.title-list-view';
        this.onScrollingUp(titleContainer, current);
        /* istanbul ignore else */
        if (document.querySelector(titleContainer) !== null && typeof document.querySelector(titleContainer) !== 'undefined') {
            const srpTitle = document.querySelector(titleContainer).getBoundingClientRect().top;
            if (srpTitle < current - 200) {
                this.sticky = true;
                // when scrolling if footer is visible, update map height
                this.isScrolledIntoView();
            } else {
                this.sticky = false;
            }
        }
    }

    onScrollingUp(titleContainer: string, current: number) {
        if (document.querySelector(titleContainer) !== null && typeof document.querySelector(titleContainer) !== 'undefined') {
            const scrollPos = 0;
            const srpTitle = document.querySelector(titleContainer).getBoundingClientRect().top;
            /* istanbul ignore else */
            if (current === 0 || scrollPos < srpTitle) {
                this.footerVisible = false;
            }
        }
    }

    /* istanbul ignore next */
    scrollMobileFunction(current: number) {
        /* istanbul ignore else */
        if (document.querySelector('.location') !== null && typeof document.querySelector('.location') !== 'undefined') {
            const srpTitle = document.querySelector('.location').getBoundingClientRect().top;
            if (srpTitle < current - 200) {
                this.sticky = true;
            } else {
                this.sticky = false;
            }
        }
    }

    /* istanbul ignore next */
    isScrolledIntoView() {
        this.footerVisible = true;
        this.addDynamicHeight();
    }

    /* istanbul ignore next */
    removeDynamicHeight() {
        const mapDiv = document.querySelector(this.mapdesktop);
        if (mapDiv !== null && typeof mapDiv !== 'undefined') {
            mapDiv.removeAttribute('style');
        }
    }

    /* istanbul ignore next */
    addDynamicHeight() {
        const mapDiv = document.querySelector(this.mapdesktop);
        const seoContainer = '.seotext-component';
        const seoDiv = document.querySelector(seoContainer);

        /* istanbul ignore if */
        if (seoDiv !== null && typeof seoDiv !== 'undefined' && mapDiv !== null && typeof mapDiv !== 'undefined') {
            const seoContainerHt = document.querySelector(seoContainer).getBoundingClientRect().top;
            const ht = seoContainerHt < 0 ? 0 : window.innerHeight - (window.innerHeight - seoContainerHt);
            mapDiv.setAttribute('style', 'height: ' + ht.toFixed(2) + 'px !important');
        }
    }

    onlyCollison(storeDetail: IStoreSearchResult) {
        return storeDetail.Department.length === 1 && storeDetail.Department[0].DepartmentType === 'Collision';
    }

    /* istanbul ignore next */
    openVehiclesOnSale(storeDetail: StoreDetails, e: Event) {
        e.stopPropagation();
        const makes: string[] = [];
        this.formInfoService.reportVehiclesOnSaleClick();
        const vUrl = this.formatCarSalesUrl.transform(storeDetail, this.FindStoreConfigData.SalesUrl, makes);
        window.location.href = vUrl;
    }

    closeNoResults() {
        this.closeNoResultElement = true;
    }

    showNoResult() {
        return this.isNationwideSearch && !this.closeNoResultElement;
    }

    /* istanbul ignore next */
    @HostListener('document:keyup', ['$event'])
    KeyUpEvent(event) {
        if (event.keyCode === 9 && event.currentTarget.activeElement.id.indexOf('result-tile-') !== -1) {
            // highlight tile when tabbing
            const prevHighlightedTile = document.querySelectorAll('.rt-border');
            prevHighlightedTile.forEach(e => e && e.classList.remove('rt-border'));

            const currentActiveTile = document.getElementById(event.currentTarget.activeElement.id);
            currentActiveTile.classList.add('rt-border');

            this.highlightedTile = parseInt(event.currentTarget.activeElement.id.replace('result-tile-', ''), 0);
        }

        if (event.keyCode === 9 && document.getElementsByClassName(this.srpBootstrap)[0] !== undefined) {
            document.getElementsByClassName(this.srpBootstrap)[0].classList.remove('no-focus-outline');
        }
    }

    shouldShowVehicleOnSale(store: any) {
        return store.HasInventory;
    }

    /* istanbul ignore next */
    @HostListener('document:click', ['$event', '$event.target'])
    onClick(event: MouseEvent, targetElement: HTMLElement): void {
        if (document.getElementsByClassName(this.srpBootstrap)[0] !== undefined) {
            document.getElementsByClassName(this.srpBootstrap)[0].classList.add('no-focus-outline');
        }
    }
}
