import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoadingLayout } from '../../services/loadingLayout.service';

@Component({
    selector: 'dt-loadingcomponent',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss']
})

export class LoadingComponent implements OnInit, OnDestroy {

    loadingState: boolean;
    subscription: Subscription;

    constructor(
        private readonly LoadingLayoutService: LoadingLayout
    ) {
        this.loadingState = false;
        this.subscription = this.LoadingLayoutService.getStateLoader().subscribe(state => {
            this.loadingState = state;
        });
    }

    ngOnInit() {
     }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
