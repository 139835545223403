import { Pipe, PipeTransform } from '@angular/core';
import { StoreDetails } from '../interfaces/storeDetails.interface';

@Pipe({ name: 'formatCarSalesUrl' })
export class FormatCarSalesUrl implements PipeTransform {

  transform(storeDetail: StoreDetails, salesURL: string, makes?: string[]): any {
    const storeName = storeDetail && storeDetail.PromoUrl !== '' ? storeDetail.PromoUrl.replace(/ /g, '-') : '';
    const params = 'cnd=new|used|cpo&sortBy=distance&sortDirection=0&dst=-1';
    return `${salesURL}${storeName}?hid=${storeDetail.StoreId}&${params}`;
  }
}
