import { Component, OnInit, Inject, OnDestroy, ViewChild, Input, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { IConfig } from '../../interfaces/config.interface';
import { FindStoreConfig, Make, MakeGroup, ISearchCriteria } from '../../interfaces/findstore.interface';
import { GeolocatorService } from '../../services/geolocator.service';
import { Subscription, Observable } from 'rxjs';
import { IZipCodeData, IZipCodeResponse } from '../../interfaces/IZipCode.interface';
import { LocationAutocompleteService } from '../../services/locationAutocomplete.service';
import { debounceTime } from 'rxjs/operators';
import { SearchState } from '../../services/searchState.service';
import { SearchCriteriaType } from '../../interfaces/findstore.interface';
import { FormInfoService } from '../../services/form-info.service';
import { UpdateURL, UpdateDepartmentWithCode, updateHeader } from '../../utils/utils.functions';
import { GeoLocatorComponent } from '../geoLocator/geoLocator.component';
import { BreakpointService } from '../../services/breakpoint.service';
import { DecodeURL } from '../../utils/utils.functions';

@Component({
    selector: 'dt-findStoreFormComponent',
    templateUrl: './findForm.component.html',
    styleUrls: ['./findForm.component.scss']
})

export class FindStoreFormComponent implements OnInit, AfterViewInit, OnDestroy {

    FindStoreConfigData: FindStoreConfig;
    fasForm: FormGroup = new FormGroup({});
    formTouched = false;
    search: ISearchCriteria;
    private searchSubscription: Subscription;
    searchType = SearchCriteriaType.initilizeSearch;
    searchParam: any;
    makesControl = new FormControl('', [Validators.required]);
    makesGroup: MakeGroup[];
    allMakesOption: Make;
    collisionsOption: Make;
    salesserviceOption: Make;
    initialDropdownState: boolean;
    departments: Make[];
    departmentTypes: Make[];
    isIframe = false;

    @ViewChild('geoLocator', { static: true }) geoLocator: GeoLocatorComponent;
    private geoLocatorSubscription: Subscription;
    locationControl = new FormControl('', [Validators.required]);
    zipCodeDataControl = new FormControl('');
    zipCodeData: IZipCodeData;
    locationOptions = [];
    getAutocomplete = true;
    IslandingPage = true;
    makeSelectedData = [];
    makeSetValue: any;
    salesChecked = true;
    serviceChecked = true;
    collisionChecked = true;

    @Input() secondaryView: boolean;
    @Input() fromHomepage: boolean;
    @Input() totalStoreCount: number;

    screenSize = '';

    constructor(
        @Inject('config') public SitecoreConfig: IConfig,
        private readonly FindStoreForm: FormBuilder,
        private readonly geoLocatorService: GeolocatorService,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly SearchStateService: SearchState,
        private readonly locationAutocompleteService: LocationAutocompleteService,
        private readonly formInfoService: FormInfoService,
        private readonly breakpointService: BreakpointService
    ) {
        this.fasForm = this.FindStoreForm.group({
            location: this.FindStoreForm.group({
                cityState: this.locationControl,
                zipCodeData: this.zipCodeDataControl
            }),
            makes: this.makesControl
        });

        this.departmentTypes = [
            ...this.SitecoreConfig.Sitecore.FindStoreConfig.FindStoreForm.DepartmentTypes.Departments,
            {
                Value: this.SitecoreConfig.Sitecore.FindStoreConfig.FindStoreForm.DepartmentTypes.AllValue,
                Description: this.SitecoreConfig.Sitecore.FindStoreConfig.FindStoreForm.DepartmentTypes.AllCaption
            }
        ];
        /* istanbul ignore next */
        if (this.route && this.route.snapshot && this.route.snapshot.queryParams) {
            this.searchParam = this.route.snapshot.queryParams.search;
        }
    }

    ngOnInit() {
        /* istanbul ignore next */
        this.setConfigs();
        this.setSelectedDepartments();
        const sessionData = sessionStorage.getItem('makeData');
        this.makeSelectedData[0] = sessionData != null ? sessionData.replace(/,(?=[^\s])/g, ', ') : null;
        this.FindStoreConfigData = this.SitecoreConfig.Sitecore.FindStoreConfig;
        this.makesGroup = [
            {
                name: this.FindStoreConfigData.FindStoreForm.DepartmentTypes.AllCaption,
                makes: this.SitecoreConfig.Sitecore.Makes.UsedMakes
            }
        ];
        this.setAllMakes();
        this.departments = this.FindStoreConfigData.FindStoreForm.DepartmentTypes.Departments;
        this.makesControl.setValue([this.allMakesOption.Value]);
        // TODO replace values and make them be as attrs
        this.collisionsOption = this.FindStoreConfigData.FindStoreForm.DepartmentTypes.Departments[0];
        this.salesserviceOption = this.FindStoreConfigData.FindStoreForm.DepartmentTypes.Departments[1];

        this.initialDropdownState = true;
        this.OnLocationValueChanged();
        this.setSearchSubscription();
        this.setGeolocatorSubscription();
    }

    setConfigs() {
        /* istanbul ignore next */
        if (this.route && this.route.data) {
            this.route.data.subscribe(v => this.isIframe = v.isIframe
            );
        }

        this.breakpointService.breakpoint.subscribe(breakpoint => {
            this.screenSize = breakpoint;
        });

        /* istanbul ignore if */
        if (window.location.href.indexOf('results') > -1) {
            this.IslandingPage = false;
        }
        sessionStorage.setItem('resultfas-clicked', 'false');
    }

    setSelectedDepartments() {
        /* eslint-disable */
        /* istanbul ignore if */
        if (this.searchParam) {
            this.search = {
                Criteria: DecodeURL(this.searchParam),
                Type: SearchCriteriaType.initilizeSearch
            };
            if (this.search.Criteria.Departments.length > 0) {
                this.salesChecked = false;
                this.serviceChecked = false;
                this.collisionChecked = false;
                const qsVal = this.search.Criteria.Departments.toString();
                if (qsVal.indexOf('R') > -1) {
                    this.salesChecked = true;
                }
                if (qsVal.indexOf('S') > -1) {
                    this.serviceChecked = true;
                }
                if (qsVal.indexOf('C') > -1) {
                    this.collisionChecked = true;
                }
            }
        }
        /* eslint-enable */
        /* istanbul ignore if */
    }

    setAllMakes() {
        /* istanbul ignore if */
        if (this.makeSelectedData[0] === '' || this.makeSelectedData[0] === null
            || this.makeSelectedData[0] === 'alls' || this.makeSelectedData[0] === 'all') {
            this.allMakesOption = {
                Value: this.FindStoreConfigData.FindStoreForm.DepartmentTypes.AllValue,
                Description: this.FindStoreConfigData.FindStoreForm.DepartmentTypes.AllCaption
            };
        } else {
            const makebaseData = this.makeSelectedData[0];
            this.allMakesOption = {
                Value: this.makeSelectedData[0],
                Description: makebaseData
            };
        }
        /* istanbul ignore next */
        if (this.search !== undefined) {
            if (this.search.Criteria.makes.length === 0) {
                this.allMakesOption = {
                    Value: this.FindStoreConfigData.FindStoreForm.DepartmentTypes.AllValue,
                    Description: this.FindStoreConfigData.FindStoreForm.DepartmentTypes.AllCaption
                };
            } else {
                /* istanbul ignore next */
                const qsMake = this.search.Criteria.makes.toString();
                if (qsMake != null) {
                    this.makeSetValue = qsMake.replace(/,(?=[^\s])/g, ', ');
                    /* istanbul ignore if */
                    sessionStorage.setItem('makeData', this.makeSetValue);
                }
                this.allMakesOption = {
                    Value: this.makeSetValue,
                    Description: this.makeSetValue
                };
            }
        }
    }

    setGeolocatorSubscription() {
        this.geoLocatorSubscription = this.geoLocatorService.positionEmitted$
            .subscribe(zipCodeData => {
                this.zipCodeDataControl.setValue(zipCodeData);
                const cityState = this.geoLocatorService.printCityState(zipCodeData);
                this.locationControl.setValue(cityState);
                /* istanbul ignore next */
               /* Commenting this logic for the defect - 310996 */
               /* if (this.search && this.search.Criteria !== undefined && this.search.Criteria.City !== undefined
                    && this.search.Criteria.State !== undefined && this.search.Criteria.City !== '' && this.search.Criteria.State !== '') {
                    this.locationControl.setValue(this.search.Criteria.City + ', ' + this.search.Criteria.State);
                }*/
            });
    }

    setSearchSubscription() {
        this.searchSubscription = this.SearchStateService.searchCriteriaBroadcasted$
            .subscribe(data => {
                if (data.Criteria.Location.City && data.Criteria.Location.StateCode) {
                    this.locationControl.setValue(this.geoLocatorService.printCityState(data.Criteria.Location));
                } else {
                    const siteCoreLocationUser = this.SitecoreConfig.Sitecore.ZipCodeData;
                    this.locationControl.setValue(this.geoLocatorService.printCityState(siteCoreLocationUser));
                    this.geoLocator.getCurrentPosition();
                    this.getAutocomplete = false;
                }
                if (!this.fromHomepage) {
                    this.initialDropdownState = false;
                    this.searchType = data.Type;
                }
                this.makesControl.setValue(this.getSelectedMakes(data));
            });
    }

    OnLocationValueChanged() {
        this.locationControl.valueChanges
            .pipe(
                debounceTime(100)
            ).subscribe((searchTerm: string) => {
                /* istanbul ignore if */
                if (this.validateZipcode(searchTerm) || this.validateCityState(searchTerm)) {
                    this.getAutocomplete = false;
                    this.setCityState(searchTerm);
                } else if (searchTerm && this.getAutocomplete) {
                    this.getAutocompleteLocations(searchTerm).subscribe((locOpts) => {
                        this.locationOptions = locOpts;
                        this.setCityState(searchTerm);
                    });
                } else {
                    this.locationOptions = null;
                    this.getAutocomplete = true;
                }
            });
    }

    ngAfterViewInit() {
      /* istanbul ignore else */
      if (this.IslandingPage) {
        this.formInfoService.reportFASFormLoad();
      }
    }

    openedMakeDropdownChange(opened: boolean) {
        /* istanbul ignore next */
        this.dropDownChange(opened);
        setTimeout(() => {
            this.singleMakeSelectedChange();
        }, 300);
    }

    dropDownChange(opened: boolean) {
        const sessionVal = sessionStorage.getItem('makeData');
        /* istanbul ignore next */
        if (sessionVal != null) {
            this.makeSetValue = sessionVal.replace(/,(?=[^\s])/g, ', ');
        }

        /* istanbul ignore else */
        if (this.makesControl.value && this.makesControl.value.length > 0) {
            this.makesControl.value.map(mk => mk && mk.trim());
        }

        /* istanbul ignore if */
        if ((this.initialDropdownState && this.makeSetValue == null) ||
            this.makesControl.value && this.makesControl.value[0] === 'all') {
            this.initialDropdownState = false;
            const departmentsValues = this.departments.map(dep => dep.Value);
            this.makesControl.setValue(departmentsValues);
        }
        /* istanbul ignore next */
        if (sessionVal !== null && this.makeSetValue !== null &&
            this.makeSetValue === this.removeDups(this.makesControl.value).toString()) {
            let arr = sessionStorage.getItem('makeData').split(',');
            arr = arr.map(mk => mk.trim());
            this.initialDropdownState = false;
            if (arr.length === 1 && arr[0] === '') {
                this.setMakesToAll();
            } else {
                this.makesControl.setValue(arr);
            }
        }

        /* istanbul ignore next */
        if (this.makeSetValue != null && this.makesControl.value && this.makesControl.value.length === 0) {
            this.setMakesToAll();
        }
    }

    toggleAllSelection(event: any, optionSelected: string) {
        /* istanbul ignore if */
        if (optionSelected !== 'alls' && event && event.isUserInput && event.source.selected
            && this.makesControl.value && this.makesControl.value.length === 1
            && this.makesControl.value[0] !== null && optionSelected.toLowerCase() === this.makesControl.value[0].toLowerCase()) {
            this.unCheckSingleMake(optionSelected);
            this.setMakesToAll();
            return;
        }
        /* istanbul ignore if */
        if (optionSelected === 'alls'
            && this.makesControl.value && this.makesControl.value.length === 1
            && this.makesControl.value[0] !== null && optionSelected.toLowerCase() !== this.makesControl.value[0].toLowerCase()) {
            this.unCheckSingleMake(this.makesControl.value[0]);
            this.setMakesToAll();
            return;
        }
        if (event && event.isUserInput) {
            /* istanbul ignore else */
            if (event.source.selected) {
                const currentMakes = this.removeDups(this.makesControl.value);
                // fix for the bug #309467
                /* istanbul ignore next */
                if (currentMakes.indexOf('alls') === -1 && currentMakes.indexOf(optionSelected) !== -1) {
                    const newListMakes = [...currentMakes];
                    newListMakes.splice(newListMakes.indexOf(optionSelected), 1);
                    this.makesControl.setValue(newListMakes);
                }
                /* istanbul ignore next */
                if (currentMakes.indexOf('alls') >= 0 && (optionSelected !== 'alls')) {
                    const newListMakes = [...currentMakes];
                    newListMakes.splice(newListMakes.indexOf('alls'), 1);
                    this.makesControl.setValue(newListMakes);
                }
                /* istanbul ignore if */
                if (optionSelected === 'alls') {
                    if (this.makesControl.value.indexOf('alls') >= 0) {
                        this.makesControl.setValue(['alls']);
                    } else {
                        this.makesControl.setValue(['alls']);
                    }
                }
            } else if (optionSelected !== 'alls') {
                const selectedItems = [...this.makesControl.value];
                selectedItems.splice(selectedItems.indexOf(optionSelected), 1);
                if (selectedItems.length <= 0) {
                    this.makesControl.setValue(['alls']);
                }
            }
        }
    }

    /* istanbul ignore next */
    CheckboxStatus(event) {
        const check = event.target.checked;
        if (check) {
            switch (event.currentTarget.defaultValue) {
                case 'Sales':
                    this.salesChecked = true;
                    break;
                case 'Service':
                    this.serviceChecked = true;
                    break;
                case 'Collision':
                    this.collisionChecked = true;
                    break;
                default:
                    break;
            }
        } else {
            switch (event.currentTarget.defaultValue) {
                case 'Sales':
                    event.target.checked = this.collisionChecked || this.serviceChecked ? false : true;
                    this.salesChecked = event.target.checked;
                    break;
                case 'Service':
                    event.target.checked = this.collisionChecked || this.salesChecked ? false : true;
                    this.serviceChecked = event.target.checked;
                    break;
                case 'Collision':
                    event.target.checked = this.serviceChecked || this.salesChecked ? false : true;
                    this.collisionChecked = event.target.checked;
                    break;
                default:
                    break;
            }
        }
    }

    getSelectedMakes(searchCriteria: ISearchCriteria): string[] {
        const makeSearch = this.removeDups(searchCriteria.Criteria.makes);
        const deptSearch = this.setDeptValue(searchCriteria.Criteria.Departments);
        let makesValue = [];
        // fix for bug# 306220
        // const saleCheck = deptSearch.indexOf('Sales_Services') >= 0;
        if (deptSearch.indexOf('alls') >= 0 || (this.fromHomepage && makeSearch.length === 0 && deptSearch.length === 0)) {
            return [this.allMakesOption.Value];
        }

        if (makeSearch.length > 0) {
            return makesValue = makesValue.concat(makeSearch);
        } else {
            return [this.allMakesOption.Value];
        }
    }

    /* istanbul ignore next */
    getSelectedAllMakes(searchCriteria: ISearchCriteria): string[] | undefined {
        const makeSearch = this.removeDups(searchCriteria.Criteria.makes);
        const deptSearch = this.setDeptValue(searchCriteria.Criteria.Departments);

        if (deptSearch.indexOf('alls') >= 0 || (this.fromHomepage && makeSearch.length === 0 && deptSearch.length === 0)) {
            return [this.allMakesOption.Value];
        }

        if (deptSearch.indexOf('Sales_Services') >= 0 || deptSearch.indexOf('Collision') >= 0) {
            return [this.allMakesOption.Value];
        }
    }

    setDeptValue(ds: string[]) {
        const lstDeptWithValues = [];
        ds.forEach(d => {
            if (d === 'C' && lstDeptWithValues.indexOf('Collision') === -1) {
                lstDeptWithValues.push('Collision');
            }
            if (d === 'R' || d === 'S' && lstDeptWithValues.indexOf('Sales_Services') === -1) {
                lstDeptWithValues.push('Sales_Services');
            }
        });
        return lstDeptWithValues;
    }

    getAutocompleteLocations(filterText: string): Observable<any> {
        return this.locationAutocompleteService.getLocations(filterText);
    }

    locationFocus() {
    }

    validateCityState(inputText: string) {
        return this.locationOptions ?
            this.locationOptions.filter(locOpt => locOpt.value === inputText).length > 0 :
            false;
    }

    validateZipcode(inputText: string) {
        const zipcodeRegEx = new RegExp(/(^\d{5}$)/g);
        return zipcodeRegEx.test(inputText);
    }

    setLocationChange(zipCodeData) {
        const cityState = this.geoLocatorService.printCityState(zipCodeData);
        const geoCityState = this.geoLocatorService.printCityState(this.zipCodeDataControl.value);
        if (geoCityState !== cityState) {
            this.geoLocatorService.emitLocationChange(cityState);
            this.locationControl.setValue(cityState);
            this.zipCodeDataControl.setValue(zipCodeData);
        } else {
            this.locationControl.setValue(cityState);
            this.zipCodeDataControl.setValue(zipCodeData);
        }
    }

    /* istanbul ignore next */
    formStartTealium() {
        if (!this.formTouched) {
            this.formTouched = true;
        }
    }

    setCityState(cityZipcode: string) {
        /* istanbul ignore next */
        this.locationControl.setErrors(this.locationControl.errors ? { ...this.locationControl.errors } : null);
        if (this.validateZipcode(cityZipcode)) {
            this.getZipCodeData(cityZipcode);
            sessionStorage.setItem('typedZipCode', 'zipcode|' + cityZipcode);
            console.log('Zipcode - ' + sessionStorage.getItem('typedZipCode'));
        } else if (this.validateCityState(cityZipcode)) {
            const zipCodeDataCityState = this.geoLocatorService.printCityState(this.zipCodeDataControl.value);
            if (zipCodeDataCityState !== cityZipcode) {
                this.getZipCodeData(cityZipcode);
                sessionStorage.setItem('typedZipCode', 'citystate|' + cityZipcode);
                console.log('City,State - ' + sessionStorage.getItem('typedZipCode'));
            }

        } else {
            this.locationControl.setErrors({ invalidLocation: true });
        }
    }

    getZipCodeData(cityZipcode: string) {
      this.geoLocatorService.getZipCodeData(cityZipcode)
        .subscribe(zipCodeData => {
          if (zipCodeData) {
            if (zipCodeData.PostalCode === undefined) {
              /* istanbul ignore else */
              /* istanbul ignore next */
              if (zipCodeData && zipCodeData.PostalCode === undefined) {
                /* istanbul ignore next */
                this.geoLocatorService.updateCurrentUserZipCode(zipCodeData).subscribe(
                  (getCurrentZipcode: IZipCodeResponse) => {
                    zipCodeData.PostalCode = getCurrentZipcode.ZipCodeData.PostalCode;
                    if (zipCodeData) {
                      this.geoLocatorService.updateUserZipCode(zipCodeData).subscribe(zipcode => {
                        if (zipcode) {
                          this.getAutocomplete = false;
                          this.setLocationChange(zipCodeData);
                          document.cookie = 'disableBrowserGeoLocation=true; path=/';
                        }
                      });
                    }
                  },
                  error => {
                    console.log(error);
                  }
                );
              }
              /* istanbul ignore else */
              /* istanbul ignore next */

            } else {
              this.getAutocomplete = false;
              this.setLocationChange(zipCodeData);
            }
          } else {
            this.locationControl.setErrors({ invalidLocation: true });
          }
        });
    }

    ngOnDestroy() {
        this.geoLocatorSubscription.unsubscribe();
        this.searchSubscription.unsubscribe();
    }

    onSubmit(e: Event, storeData) {
        /* istanbul ignore next */
        this.updateZipInHeader(storeData);

        /* istanbul ignore next */
        const listMakes = [...storeData.makes];
        const listDepartment = [];

        /* istanbul ignore if */
        if (!this.IslandingPage) {
            sessionStorage.setItem('resultfas-clicked', 'true');
        }

        /* istanbul ignore else */
        if (this.salesChecked) {
            listDepartment.push('Sales');
        }
        /* istanbul ignore else */
        if (this.serviceChecked) {
            listDepartment.push('Service');
        }
        /* istanbul ignore else */
        if (this.collisionChecked) {
            listDepartment.push('Collision');
        }

        sessionStorage.setItem('makeData', this.removeDuplicates(storeData).toString());
        storeData.makes.forEach(make => {
            this.departmentTypes.some(type => {
                /* istanbul ignore if */
                if (type.Value === make) {
                    listMakes.splice(listMakes.indexOf(make), 1);
                }
            });
        });

        const cityState = this.locationControl.value.split(',');
        let city = '';
        let state = '';
        /* istanbul ignore next */
        if (cityState.length === 2) {
            city = cityState[0].trim();
            state = cityState[1].trim();
        }

        e.preventDefault();
        /* istanbul ignore else */
        if (this.fasForm.valid) {
             /* istanbul ignore else */
            if (this.IslandingPage) {
              this.formInfoService.reportFASFormSubmitStart(this.fromHomepage, storeData.makes, listDepartment, city, 0, false);
            }
            UpdateURL(listMakes, listDepartment, storeData.location.zipCodeData, city, state, this.router);
            this.SearchStateService.broadcastSearchCriteria({
                Criteria: {
                    Location: storeData.location.zipCodeData,
                    City: city,
                    State: state,
                    makes: listMakes,
                    Departments: UpdateDepartmentWithCode(listDepartment),
                    CurrentPage: this.FindStoreConfigData.CurrentPage
                },
                Type: this.searchType
            });
        }
    }

    updateZipInHeader(storeData: any) {
         /* istanbul ignore else */
        if (typeof storeData.location.zipCodeData !== 'string'
            && storeData.location.zipCodeData.City && storeData.location.zipCodeData.StateCode) {
            this.setFasZip(storeData);
        } else if (this.hasfasZipInSession()) {
            // if fasZip exist in session update header with that info
            updateHeader();
        } else {
            // when location is set to 'Always Ask' storeData.location.zipCodeData is empty
            // set fasZip to PrevZipCode value
            // PrevZipCode does not have lat and long
            // so calling UpdateZipCode api to get lat and long
            const prevZipCode = this.geoLocatorService.getCookie('PrevZipCode');
            const zipCodeData: any = {
                PostalCode: prevZipCode,
                StateCode: '',
                City: '',
                Latitude: 0,
                Longitude: 0,
                ValidPostalCode: false
            };
            this.geoLocatorService.updateUserZipCode(zipCodeData).subscribe(
                (res: any) => {
                    if (res && res.ZipCodeData) {
                        sessionStorage.setItem('faszip', res.ZipCodeData.PostalCode +
                        '|' + res.ZipCodeData.Latitude +
                        '|' + res.ZipCodeData.Longitude);
                    }
                }
            );
        }
    }

     /* istanbul ignore next */
    hasfasZipInSession() {
        /* istanbul ignore else */
        if (sessionStorage.getItem('faszip')) {
            const fasZip = sessionStorage.getItem('faszip').split('|');
            /* istanbul ignore else */
            if (fasZip.length === 3) {
                /* istanbul ignore else */
                if (fasZip[0] !== 'undefined' && fasZip[1] !== 'undefined' && fasZip[2] !== 'undefined') {
                    return true;
                }
            }
        }
        return false;
    }

    /* istanbul ignore next */
    setFasZip(storeData: any) {
        if (storeData.location.zipCodeData.PostalCode === undefined) {
            // since we are searching by city, state we need to get zipcode from apI
            this.geoLocatorService.updateCurrentUserZipCode(storeData.location.zipCodeData).subscribe(
                (getCurrentZipcode: IZipCodeResponse) => {
                    sessionStorage.setItem('currentZipCode', getCurrentZipcode.ZipCodeData.PostalCode);
                    sessionStorage.setItem('faszip', getCurrentZipcode.ZipCodeData.PostalCode +
                        '|' + getCurrentZipcode.ZipCodeData.Latitude +
                        '|' + getCurrentZipcode.ZipCodeData.Longitude);

                    updateHeader();
                },
                /* istanbul ignore next */
                error => {
                    console.log(error);
                }
            );
        } else {
            sessionStorage.setItem('faszip', storeData.location.zipCodeData.PostalCode +
                '|' + storeData.location.zipCodeData.Latitude +
                '|' + storeData.location.zipCodeData.Longitude);
            updateHeader();
        }
    }

    /* eslint-disable */
    removeDuplicates(storeData: any) {
        const mks = [];
        this.removeDeptFromMakes(storeData.makes);
        storeData.makes && storeData.makes.map(m => {
            /* istanbul ignore else */
            if (m && !mks.includes(m)) {
                mks.push(m.trim());
            }
        });

        return mks;
    }

    removeDups(makes: any) {
        const mks = [];
        this.removeDeptFromMakes(makes);
        makes && makes.map(m => {
            /* istanbul ignore else */
            if (m && !mks.includes(m)) {
                mks.push(m.trim());
            }
        });

        return mks;
    }

    removeDeptFromMakes(makes: any) {
        /* istanbul ignore else */
        if (makes && makes.indexOf('Sales_Services') !== -1) {
            makes.splice(makes.indexOf('Sales_Services'), 1);
        }

        /* istanbul ignore else */
        if (makes && makes.indexOf('Collision') !== -1) {
            makes.splice(makes.indexOf('Collision'), 1);
        }
    }

    /* istanbul ignore next */
    getClass() {
        if (this.IslandingPage && this.isIframe) {
            return 'col-12 col-sm-8 offset-sm-2 col-xl-6 offset-xl-1 content-landing-form iframe-m';
        }

        if (this.IslandingPage) {
            return 'col-12 col-sm-8 offset-sm-2 col-xl-6 offset-xl-1 content-landing-form';
        }

        if (!this.IslandingPage && this.screenSize === 'xs') {
            return 'col-12 col-sm-8 offset-sm-2 col-xl-6 offset-xl-1 content-form p-0';
        }

        return 'col-12 col-sm-8 offset-sm-2 col-xl-6 offset-xl-1 content-form';
    }

    /* istanbul ignore next */
    singleMakeSelectedChange() {
        // when only one make is selected the checkbox is getting unchecked
        // here we are forcing the checkbox as checked

        const selectedMakeElem = document.getElementsByClassName('mat-select-value-text') as any;
        if (selectedMakeElem[0] && selectedMakeElem[0].innerText.split(',').length === 1) {
            const selectedMake = selectedMakeElem[0].innerText;
            const matOptions = document.getElementsByClassName('mat-option-text') as any;

            if (matOptions.length > 0) {
                for (let i = 0; i < matOptions.length; i++) {
                    if (i > 0 && matOptions[i] && matOptions[i].innerText === selectedMake) {
                        this.checkSelectedMake(matOptions, i);
                    }
                }
            }
        }
    }

    /* istanbul ignore next */
    checkSelectedMake(matOptions: any, i: number) {
        const matOption = matOptions[i];
        const matOptionElem = matOption.closest('mat-option');
        if (matOptionElem && matOptionElem !== null) {
            matOptionElem.setAttribute('aria-selected', true);
            matOptionElem.classList.add('mat-selected');
            matOptionElem.classList.add('mat-active');

            const pChkboxElem = document.getElementsByTagName("mat-pseudo-checkbox")[i] as any;
            if (pChkboxElem && pChkboxElem !== null) {
                pChkboxElem.classList.remove('mat-pseudo-checkbox-unchecked');
                pChkboxElem.classList.add('mat-pseudo-checkbox-checked');
            }
        }
    }

    /* istanbul ignore next */
    setMakesToAll() {
        setTimeout(() => {
            this.makesControl.setValue(['alls']);
        });
    }

    /* istanbul ignore next */
    unCheckSingleMake(optionSelected: string) {
        const matOptions = document.getElementsByClassName('mat-option-text') as any;
        if (matOptions.length > 0) {
            for (let i = 0; i < matOptions.length; i++) {
                if (i > 0 && matOptions[i] && matOptions[i].innerText.toLowerCase() === optionSelected.toLowerCase()) {
                    this.unCheckMake(matOptions, i);
                }
            }
        }

        const selectedMakeElem = document.getElementsByClassName('mat-select-value-text') as any;
        if (selectedMakeElem[0]) {
            const selectMakeValue = selectedMakeElem[0].innerText;
            if (selectMakeValue && selectMakeValue.toLowerCase() === optionSelected.toLowerCase()) {
                selectedMakeElem[0].innerText = 'All Brands / Makes';
            }
        }
    }

    /* istanbul ignore next */
    unCheckMake(matOptions: any, i: number) {
        const matOption = matOptions[i];
        const matOptionElem = matOption.closest('mat-option');
        if (matOptionElem && matOptionElem !== null) {
            matOptionElem.setAttribute('aria-selected', false);
            matOptionElem.classList.remove('mat-selected');
            matOptionElem.classList.remove('mat-active');

            const pChkboxElem = document.getElementsByTagName("mat-pseudo-checkbox")[i] as any;
            if (pChkboxElem && pChkboxElem !== null) {
                pChkboxElem.classList.remove('mat-pseudo-checkbox-checked');
                pChkboxElem.classList.add('mat-pseudo-checkbox-unchecked');
            }
        }
    }
}
