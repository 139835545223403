<div [ngClass]="IsResultPage ?'fas-result-page':'fas-landing-page'" class="row seotext-component" id="seo-wrapper">
    <div class="col-12 col-md-8 offset-md-2">
        <h2 class="seo-wrapper-title" *ngIf="!isMobile()">{{dataComponent.Title}}</h2>
        <div #pContainer [className]="showMoreText ? 'show-more text-seo' : 'text-seo'" id="textContainer">
            <h3 class="text-container-title" *ngIf="isMobile()">{{dataComponent.Title}}</h3>
            <p #paragraph [innerHTML]="dataComponent.Text"></p>
        </div>
        <p class="read-more cta-container">
            <a
                tabindex="0"
                #showCta
                class="tertiary-cta"
                (click)="toggleText()">
                {{showMoreText ? dataComponent.ReadLessCta.Text : dataComponent.ReadMoreCta.Text}}
                <span class="sr-only">{{showMoreText ? dataComponent.ReadLessCta.AdaText : dataComponent.ReadMoreCta.AdaText}}</span>
            </a>
        </p>
    </div>
</div>
